<app-spinner></app-spinner>

<!--Section Start-->
<div class="sigma_subheader style-6 bg-cover bg-center title-banner">
  <div class="container">
    <div class="sigma_subheader-inner">
      <h1>Contact Us</h1>
    </div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="btn-link" routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
    </ol>
  </div>
</div>
<!--Section End-->
<!--Section Start-->
<div class="section section-padding">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="sigma_info style-19">
          <div class="sigma_info-title">
            <span class="sigma_info-icon bg-primary-1 text-white">
              <i class="flaticon-address"></i>
            </span>
            <h5><strong>Our Addresses</strong></h5>
          </div>
          <div class="sigma_info-description">
            <p>US Main Office</p>
            <p><strong>4950 Fulton Dr, Unit F, Fairfield, CA 94534</strong></p>
            <br />
            <p>Canada Main Office</p>
            <p><strong>800 Boyer Blvd., Mississauga, ON L5V 2Y1</strong></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sigma_info style-19">
          <div class="sigma_info-title">
            <span class="sigma_info-icon bg-primary-1 text-white">
              <i class="flaticon-call"></i>
            </span>
            <h5><strong>Our Phone</strong></h5>
          </div>
          <div class="sigma_info-description">
            <div>
              <strong>US Customer Service 24/7</strong> <br />
              {{ telephone }} (US) <br />
              {{ telephone_canada }} (Canada) <br />
              {{ telephone_uk }} (UK) <br /><br />

              <strong>PH Customer Service 24/7</strong> <br />
              <div class="row">
                <div class="col-auto">Smart :</div>
                <div class="col" style="padding-left: 0">
                  {{ mobileSmart }} (CALLS ONLY) <br />
                  {{ mobileSmart2 }} (CALLS ONLY) <br />
                </div>
              </div>
              <br />

              <strong>PH Landline</strong> <br />
              (02) 8396-8887 <br /><br />

              <strong>US Outgoing Number</strong> <br />
              +1 (661) 454-7200 <br />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sigma_info style-19">
          <div class="sigma_info-title">
            <span class="sigma_info-icon bg-primary-1 text-white">
              <i class="flaticon-mail"></i>
            </span>
            <h5><strong>Our Email</strong></h5>
          </div>
          <div class="sigma_info-description">
            <p>cs@logocargo.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Section End-->
<!--Section Start-->
<div
  class="section sigma_contact extra-padding bg-cover bg-center dark-overlay"
  style="background-image: url(assets/img/bg-2.jpg)"
>
  <div class="container">
    <div class="section-title centered">
      <h1 class="title text-white">
        Contact <span class="primary-color">Us</span>
      </h1>
      <p class="text-white lead">
        Our Team is happy to answer all of your questions. Fill out the form and
        we'll be in touch as soon as possible.
      </p>
    </div>
  </div>
</div>
<div class="sigma_contact-map is-absolute">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <iframe
          height="400"
          allowfullscreen=""
          frameborder="0"
          src="https://www.google.com/maps/embed/v1/place?q=4950+Fulton+Dr+Unit+F,+Fairfield,+CA+94534,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        ></iframe>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isSucceeded" class="section pt-5">
  <div class="container success-content">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <h1 class="text-success">Sent Successfully!</h1>
        <h3>
          Your inquiry has been sent to our team. We will reach you as soon as
          possible. Please check your email you provided regularly. Thank you!
        </h3>
        <div class="pt-3">
          <button class="btn btn-axp-theme" routerLink="/home">
            Home Page
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isSucceeded" class="section">
  <div class="container">
    <div class="section-title centered">
      <span class="subtitle">Reach Out</span>
      <h3 class="title">Send a Message</h3>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>First Name <i class="required">*</i></label>
                <input type="text" name="fname" formControlName="firstName" />
                <div
                  *ngIf="
                    formControl.firstName.invalid &&
                    (formControl.firstName.dirty ||
                      formControl.firstName.touched ||
                      isSubmitted)
                  "
                >
                  <span
                    class="text-danger"
                    *ngIf="formControl.firstName.errors?.required"
                    >First Name is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>Last Name <i class="required">*</i></label>
                <input type="text" name="lname" formControlName="lastName" />
                <div
                  *ngIf="
                    formControl.lastName.invalid &&
                    (formControl.lastName.dirty ||
                      formControl.lastName.touched ||
                      isSubmitted)
                  "
                >
                  <span
                    class="text-danger"
                    *ngIf="formControl.lastName.errors?.required"
                    >Last Name is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Email <i class="required">*</i> </label>
                <input type="email" name="email" formControlName="email" />
                <div
                  *ngIf="
                    formControl.email.invalid &&
                    (formControl.email.dirty ||
                      formControl.email.touched ||
                      isSubmitted)
                  "
                >
                  <span
                    class="text-danger"
                    *ngIf="formControl.email.errors?.required"
                    >Email is required.</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="formControl.email.errors?.email"
                    >Email is invalid.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Message <i class="required">*</i> </label>
                <textarea
                  name="message"
                  rows="5"
                  formControlName="message"
                ></textarea>
                <div
                  *ngIf="
                    formControl.message.invalid &&
                    (formControl.message.dirty ||
                      formControl.message.touched ||
                      isSubmitted)
                  "
                >
                  <span
                    class="text-danger"
                    *ngIf="formControl.message.errors?.required"
                    >Message is required.</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="formControl.message.errors?.minlength"
                    >Message should be greater than 10 characters.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-grid gap-2">
                <button type="submit" class="btn btn-axp-theme">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Section End-->
