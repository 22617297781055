<!-- <div class="sigma_preloader sigma_preloader-gear">
  <div class="sigma_preloader-inner">
    <div class="preloader-gear">
      <div class="preloader-gear-inner">
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="overflow-hidden">
  <div class="sigma_subheader style-6 bg-cover bg-center title-banner">
    <div class="container">
      <div class="sigma_subheader-inner">
        <h1>About Us</h1>
      </div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="btn-link" routerLink="/">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">About Us</li>
      </ol>
    </div>
  </div>
  <!--Section End-->
  <!--Section Start-->
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img [src]="images['about_us_image']" alt="img" />
            </div>
            <div class="sigma_media m-0">
              <span>
                <b class="counter" data-from="0" data-to="20">20</b>
                Years of <br />
                Experience
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title">
              <span class="subtitle">About Us</span>
              <h3 class="title m-0">LOGO</h3>
            </div>
            <div class="sigma_about-content" style="text-align: justify">
              <p style="text-indent: 5%">
                LOGO stands tall as a multifaceted corporation with a rich
                tapestry of services. Our vision expanded, and we transformed
                into something much more than we could have ever imagined. The
                company has grown into a beacon of connection and support for
                the Filipino community across the globe.
              </p>
              <strong>2001 - LOGO Remit:</strong>
              <p style="text-indent: 5%">
                The company started as pioneers year 2001 in the money
                remittance industry, ensuring swift and secure transactions for
                our customers.
              </p>
              <strong>2005 - ABXP:</strong>
              <p style="text-indent: 5%">
                Four years later, in 2005, we expanded our horizons and set sail
                into the world of freight forwarding and logistics with the
                birth of ABXP in the Philippines.
              </p>
              <strong>2021 - LOGO:</strong>
              <p style="text-indent: 5%">
                In 2021, LOGO emerged as an IT Solutions and Call Center
                Company, not only expanding our offerings but also rebranding to
                reflect our commitment to modernity and progress.
              </p>
              <p style="text-indent: 5%">
                We decided to branch out independently and embraced LOGO as our
                Cargo Company's legal identity. A name that started to resonate
                across Canada in 2022 and extend its reach even further into
                America in 2023. We carved a path as leaders in the world of
                balikbayan box shipments, proudly spreading our wings with 26
                branches scattered across Northern California, Nevada, Chicago,
                Washington, Hawaii and a growing presence in Canada.
              </p>
              <p style="text-indent: 5%">
                Motivated by a firm passion for progress, we delved further into
                the world of e-commerce, giving birth to the LOGO Megastore. An
                online platform that beckoned shoppers to explore a treasure
                trove of Filipino and Asian products. On the horizon, we
                envisioned the imminent launch of LOGO Driver, a transportation
                company poised to revolutionize online delivery bookings.
              </p>
              <p style="text-indent: 5%">
                LOGO is more than just a business; it's a symbol of connection
                and Filipino pride. We bridge the gap for families separated by
                oceans in the U.S., Canada, and around the world. At LOGO, we
                continue to evolve, adapt, and innovate to serve our community
                better. We are your partner in progress, connecting hearts and
                dreams across continents.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Section Start-->
  <div class="section pt-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="sigma_about style-10 pl-lg-4">
            <div class="section-title">
              <span class="subtitle">LOGO</span>
              <h3 class="title">MISSION & VISION</h3>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="sigma_media">
                  <div class="sigma_media-content">
                    <h5>VISION</h5>
                    <p>
                      The company aims to be the world’s leading and most
                      customer-centric company in Balikbayan Box shipping and
                      money remittance.
                    </p>
                  </div>
                </div>
                <!-- </div>
              <div class="col-lg-6"> -->
                <div class="sigma_media">
                  <div class="sigma_media-content">
                    <h5>MISSION</h5>
                    <p>
                      The company’s mission is to help people from various parts
                      of the globe to connect by providing the market with the
                      best shipping and money remittance services, supported by
                      our world-class customer support and industry-leading
                      technologies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 d-none d-lg-block">
          <div class="sigma_about style-10 w-100 h-100">
            <div class="sigma_about-image-1">
              <img src="assets/img/entreprenuer.jpg" alt="img" />
            </div>
            <div class="sigma_about-image-2">
              <img src="assets/img/building.jpg" alt="img" />
            </div>
            <div class="sigma_about-image-3">
              <img src="assets/img/wingplane.jpg" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Section End-->
  <!--Section Start-->
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img src="assets/img/about-jpl.jpg" alt="img" class="mt-lg-5" />
            </div>
            <!-- <div class="sigma_media m-0">
              <span>
                <b class="counter" data-from="0" data-to="20">20</b>
                Years of <br> Experience
              </span>
            </div> -->
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title">
              <span class="subtitle">About</span>
              <h3 class="title">Our CEO</h3>
            </div>
            <div class="sigma_about-content">
              <h2 class="text-center mb-2">
                <em
                  >“I want to try bigger things, and I knew I was ready. So, I
                  set out for the United States – a place where I knew there
                  would be no limit for my desire to succeed.”</em
                >
              </h2>
              <h2 class="text-end mb-4">
                <em>- Joel P. Longares</em>
              </h2>
              <div style="text-align: justify">
                <p style="text-indent: 10%">
                  We started the Balikbayan Services more than 20 years ago to
                  focus on the unmet needs of our fellow Filipinos abroad, and
                  above all else, to never be satisfied with just a “good
                  enough” level of service. After all, we all know that the
                  hardworking Filipinos and our communities spread out across
                  the world DESERVE so much more than just getting the bare
                  minimum in exchange for their hard work. They do after all
                  contribute so much to our home country.
                </p>
                <p style="text-indent: 10%">
                  Right now, we are still doing the right thing by offering real
                  and meaningful solutions that make a difference in our
                  customer’s lives. By making every padala easier, and faster,
                  and all the while providing our clients and customers
                  incomparable transparency every step of the way.
                </p>
                <p style="text-indent: 10%">
                  At Longares Global Operations (LOGO Inc) we have but one
                  mission – to be the best company in the world at connecting
                  Filipino families and loved ones, no matter the distance. We
                  are driven by the ideals of innovation, efficiency, and speed
                  to make every transaction a meaningful one for each family we
                  serve. We aspire to become the widest network of Filipino
                  businesses that focus solely on making every Filipino migrant,
                  OFW, and their loved ones abroad feel closer to home, as well
                  as helping them build better lives for their families wherever
                  they may be.
                </p>
                <p style="text-indent: 10%">
                  That’s why we’ve decided to become LOGO Inc. At LOGO we are
                  fully embracing the next set of challenges that our customers
                  demand to be met through the use of our technology. We are
                  preparing to increase the quality of our service
                  responsiveness by diversifying our infrastructure. Lastly, we
                  are spreading out across new markets as we begin blazing new
                  trails in the Middle East, Europe, and other parts in Asia to
                  begin reaching more Filipino communities and providing them
                  with the meaningful service that they also deserve to get.
                </p>
                <p style="text-indent: 10%">
                  Right now, we are building the largest, fastest, most reliable
                  balikbayan services in the world. Something that we believe is
                  important to our customers, to make them feel closer to loved
                  ones back home. We want them to get faster transfers and
                  shipments, more reliable delivery times, and better exchange
                  rates to so they can give more to families back home, and have
                  them enjoy very responsive and easily accessible customer
                  service so they can stay informed and resolve any issues they
                  might have for each of their padala.
                </p>
                <p style="text-indent: 10%">
                  Because we truly believe that every Filipino deserves more
                  transparency, value, and more value-added choices to give you
                  and your families the peace of mind that you deserve for all
                  the hard work you are doing.
                </p>
                <p style="text-indent: 10%">
                  Thankfully, all these are slowly but surely being made
                  possible thanks to the amazing employees who love our
                  customers and continue to give uncompromising service to them
                  every step of the way. And thanks to them, and the trust of
                  our customers. We are well on our way to centralizing our
                  operations across the globe, so each and every Filipino will
                  come to know and enjoy the incomparable level of service and
                  reliability for every balikbayan box shipment, money
                  remittance, and all our new services that they can avail
                  easily through our new flagship, the LONGARES GLOBAL
                  OPERATIONS brand.
                </p>
                <p style="text-indent: 10%">
                  I look forward to being with you for the next decade ahead of
                  us, and we are all looking forward to creating the best
                  experience possible for you and your families with LOGO Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Section End-->
  <!--Section Start-->
  <div
    class="section section-padding bg-cover bg-center secondary-overlay"
    style="background-image: url(assets/img/bg-1.jpg)"
  >
    <div class="sigma_service-wrapper">
      <div class="container">
        <div class="section-title centered">
          <span class="subtitle text-white">Real Time Tracking</span>
          <h3 class="title text-white">Why choose our services</h3>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="sigma_service style-8">
              <div class="sigma_service-thumb">
                <img [src]="images['our_services_balikbayan_box']" alt="img" />
                <span class="icon-box">01</span>
              </div>
              <div class="sigma_service-body">
                <h5>
                  <a routerLink="/balikbayan-box">Balikbayan Box</a>
                </h5>
                <p>
                  Enjoy a secure and easy way of sending Balikbayan packages
                  anywhere in the Philippines with just one click!​
                </p>
                <a routerLink="/balikbayan-box" class="btn-link primary-color">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="sigma_service style-8">
              <div class="sigma_service-thumb">
                <img
                  [src]="images['our_services_money_remittance']"
                  alt="img"
                />
                <span class="icon-box">02</span>
              </div>
              <div class="sigma_service-body">
                <h5>
                  <a routerLink="/remittance-details">Money Remittance</a>
                </h5>
                <p>
                  Check out LOGO's reliable remittance service with low fees
                  offered at great exchange rates. No hang-ups, no delays!
                </p>
                <a
                  routerLink="/remittance-details"
                  class="btn-link primary-color"
                >
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="sigma_service style-8">
              <div class="sigma_service-thumb">
                <img [src]="images['our_services_logistics']" alt="img" />
                <span class="icon-box">03</span>
              </div>
              <div class="sigma_service-body">
                <h5>
                  <a routerLink="/">Logistics</a>
                </h5>
                <p>
                  LOGO upholds safe and up-to-par standards in its logistics
                  line throughout the Philippines.
                </p>
                <p>&nbsp;</p>
                <a routerLink="/" class="btn-link primary-color">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="sigma_service style-8">
              <div class="sigma_service-thumb">
                <img [src]="images['our_services_brokerage']" alt="img" />
                <span class="icon-box">04</span>
              </div>
              <div class="sigma_service-body">
                <h5>
                  <a routerLink="/">Brokerage</a>
                </h5>
                <p>
                  Avoid the hassles of customs clearance processing and let our
                  expert agents do the work for you.​
                </p>
                <p>&nbsp;</p>
                <a routerLink="/" class="btn-link primary-color">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="sigma_service style-8">
              <div class="sigma_service-thumb">
                <img [src]="images['our_services_ecommerce']" alt="img" />
                <span class="icon-box">05</span>
              </div>
              <div class="sigma_service-body">
                <h5>
                  <a routerLink="/">E-Commerce</a>
                </h5>
                <p>
                  Take advantage of our one-stop-shop online store that caters
                  high-quality products proudly made in the Philippines.
                </p>

                <a routerLink="/" class="btn-link primary-color">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section section-padding"></div>
  <!--Section End-->
  <!--Section Start-->
  <div class="section section-padding bg-gray">
    <img src="assets/img/illustration-1.png" alt="img" class="texture-1" />
    <div class="container">
      <div class="section-title">
        <span class="subtitle">Testimonials</span>
        <h3 class="title">Customers approve!</h3>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Christine Joy Magi</h5>
                  <span class="sigma_testimonial-category"
                    >Money Remittance Customer</span
                  >
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “Hindi ako nahihirapan magpadala online dahil inaassist ako ng
                  customer service.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Darwin Calara</h5>
                  <span class="sigma_testimonial-category"
                    >LOGO Mobile User</span
                  >
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “Maganda ang app madali gamitin. Dito na ako nagta-track at
                  nag babantay ng kahon ko kung kelan ang dating.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Josua Saldua</h5>
                  <span class="sigma_testimonial-category"
                    >LOGO Mobile User</span
                  >
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “Yes I do have your LOGO Mobile app. Wala naman naging
                  problema, nagagamit ko sa pagtrack ng kahon.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Julius Cotas</h5>
                  <span class="sigma_testimonial-category"
                    >LOGO Mobile User</span
                  >
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “May nag text sakin, tinuruan ako mag download ng LOGO Mobile
                  app.”
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Cresta Ann Baker</h5>
                  <span class="sigma_testimonial-category">LOGO Customer</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “The new name is fine, as long as it keeps offering the best
                  service.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Ann Evelyn Demerin</h5>
                  <span class="sigma_testimonial-category">LOGO Customer</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “It's okay if you change the name for expansion, as long as
                  the team and service stay the same.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Jose Porras</h5>
                  <span class="sigma_testimonial-category">LOGO Customer</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “We're okay with both the balikbayan box and money remittance
                  being under LOGO, Inc.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Anvalerie Titius</h5>
                  <span class="sigma_testimonial-category">LOGO Customer</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “Komportable na kami sa inyo. Ka-LOGO na tayo!”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Section End-->
</div>

<!--Section Start-->
