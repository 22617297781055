<h2 mat-dialog-title align="center" class="mb-4">Payment</h2>
<mat-dialog-content class="mat-typography">

    <div class="row">
        <p class="text-center">You have schedule a box to be pickup with Invoice Number <strong>{{ item.InvoiceNo
                }}</strong></p>
        <div class="col-lg-12">
            <div class="sigma_testimonial style-11 mb-0">
                <div class="sigma_testimonial-body">
                    <div class="d-flex align-items-center text-center">
                        <div class="sigma_author-block col-6">
                            <h5>Source Branch:</h5>
                            <h5>Delivery Zone:</h5>
                        </div>
                        <div class="sigma_author-block col-6">
                            <span>{{ item.BranchName }}</span><br>
                            <span>{{ item.InvoiceNo }}</span>
                        </div>
                    </div>
                    <div *ngFor="let boxItem of item.invoiceItemDetailList">
                        <hr>
                        <div class="d-flex align-items-center text-center mt-4">
                            <div class="sigma_author-block col-4">
                                <h5>Box Size</h5>
                                <span>{{ boxItem.boxSizeDescription }}</span>
                            </div>
                            <div class="sigma_author-block col-4">
                                <h5>Dimension</h5>
                                <span>{{ boxItem.dimension }}</span>
                            </div>
                            <div class="sigma_author-block col-4">
                                <h5>Item Price</h5>
                                <span>{{ boxItem.itemPrice }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <h3 class="mt-3 mb-0 mx-5">Order Detail</h3>

    <div class="container mx-25">
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-4">
                Order Amount
            </div>
            <div class="col-3" align="end">
                {{ item.Freight }}
            </div>
            <div class="col-3">
            </div>
        </div>

        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-4">
                Tip Amount
            </div>
            <div class="col-3" align="end">
                {{ item.Tip != null ? item.Tip : 0 }}
            </div>
            <div class="col-3">
            </div>
        </div>

        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-4">
                Merchant Fee
            </div>
            <div class="col-3" align="end">
                {{ item.MerchantFee != null ? item.MerchantFee : 0 }}
            </div>
            <div class="col-3">
            </div>
        </div>

        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-4">
                Less Discount
            </div>
            <div class="col-3" align="end">
                - {{ item.Discount != null ? item.Discount : 0 }}
            </div>
            <div class="col-3">
            </div>
        </div>
        <br>

        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-4">
                Total Amount
            </div>
            <div class="col-3" align="end">
                <strong>{{currency[item.BillingCountry]}} {{ item.TotalAmount != null ? item.TotalAmount : 0 }}</strong>
            </div>
            <div class="col-3">
            </div>
        </div>
    </div>

    <hr>
    <h3 class="mt-3 mb-0 mx-5">Payment Detail</h3>


    <div class="container mx-25">

        <ng-container *ngFor="let item of item.Payments">

            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-4">
                    {{item.PaymentDate|date:'MMM dd, yyyy'}}<br />
                    {{item.PaymentName}}
                </div>
                <div class="col-3" align="end">
                    <span style="color: green;"><strong>$ {{ item.PaymentAmount|number:'1.2-2' }}</strong></span>
                </div>
                <div class="col-3">
                </div>
            </div>
            <div class="row" *ngIf="item.CardType">
                <div class="col-2">
                </div>
                <div class="col-4">
                    {{ item.CardType }}
                </div>
                <div class="col-3" align="end">
                </div>
                <div class="col-3">
                </div>
            </div>
            <div class="row" *ngIf="item.CreditCardExpDate">
                <div class="col-2">
                </div>
                <div class="col-4">
                    {{ item.CreditCardExpDate | date:'MM/YYYY' }}
                </div>
                <div class="col-3" align="end">
                </div>
                <div class="col-3">
                </div>
            </div>
            <div class="row" *ngIf="item.CreditCardNumber">
                <div class="col-2">
                </div>
                <div class="col-4">
                    {{ item.CreditCardNumber|accountNumberMask }}
                </div>
                <div class="col-3" align="end">
                </div>
                <div class="col-3">
                </div>
            </div>
            <div class="row" *ngIf="item.CheckNo">
                <div class="col-2">
                </div>
                <div class="col-4">
                    {{ item.CheckNo }}
                </div>
                <div class="col-3" align="end">
                </div>
                <div class="col-3">
                </div>
            </div>
            <hr />
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancel()" class="btn btn-axp-theme">Close</button>
</mat-dialog-actions>