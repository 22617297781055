<app-spinner></app-spinner>

<!--Section Start-->
<div class="sigma_subheader style-6 bg-cover bg-center title-banner">
    <div class="container">
        <div class="sigma_subheader-inner">
            <h1>My Transactions</h1>
        </div>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a class="btn-link" routerLink="/">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">My Transactions</li>
        </ol>
    </div>
</div>
<!--Section End-->

<!--Section Start-->
<div class="section">
    <div class="container" *ngIf="!isPayment;else payment">
        <div class="row">
            <mat-tab-group mat-align-tabs="start">
                <mat-tab label="All ({{invoiceList.length}})">
                    <app-transaction-table [historyList]="invoiceList" (payNow)="onPayment($event)">
                    </app-transaction-table>
                </mat-tab>
                <mat-tab label="Pending ({{pendingList.length}})">
                    <app-transaction-table [historyList]="pendingList" (payNow)="onPayment($event)">
                    </app-transaction-table>
                </mat-tab>
                <mat-tab label="Paid ({{paidList.length}})">
                    <app-transaction-table [historyList]="paidList" (payNow)="onPayment($event)">
                    </app-transaction-table>
                </mat-tab>
                <mat-tab label="Delivered ({{deliveredList.length}})">
                    <app-transaction-table [historyList]="deliveredList" (payNow)="onPayment($event)">
                    </app-transaction-table>
                </mat-tab>
                <mat-tab label="Cancelled ({{cancelledList.length}})">
                    <app-transaction-table [historyList]="cancelledList" [hasAction]="false"
                        (payNow)="onPayment($event)">
                    </app-transaction-table>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <ng-template #payment>
        <app-payment [invoice]="selInvoice" [subType]="'Axp Website Payment'" [states]="billingStateList" [deliveryZoneList]="deliveryZoneList"
            [branchList]="branchList" (back)="onPaymentBack()">
        </app-payment>
    </ng-template>
</div>

<!--Section End-->