import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilityService } from './services/utility.service';

// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'AtlasXPadala2';

  constructor(
    private router: Router,
    private meta: Meta,
    private utilityService: UtilityService
  ) {
    this.utilityService.loadWebsiteConfigJson();
    this.utilityService.loadImagesJson();
    this.utilityService.loadPartnersJson();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.urlAfterRedirects);
        gtag('config', environment.gTagId, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
