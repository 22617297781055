<app-spinner></app-spinner>

<div class="overflow-hidden">
  <div class="sigma_subheader style-6 bg-cover bg-center title-banner">
    <div class="container">
      <div class="sigma_subheader-inner">
        <h1>BALIKBAYAN BOXES</h1>
      </div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="btn-link" routerLink="/">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Balikbayan Boxes
        </li>
      </ol>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="sigma_post-details-inner">
            <div class="entry-content">
              <div class="sigma_post-details-categories">
                <a routerLink="/balikbayan-box" class="sigma_post-category"
                  >Balikbayan Boxes</a
                >
              </div>
              <p class="entry-title">
                Welcome to LOGO, your premier Door-to-Door Balikbayan Cargo Delivery service!
                Through advanced automated logistics systems you can rest assured, your
                Balikbayan Boxes will be safely and securely delivered from our numerous
                branches across the U.S., Canada and Europe to any destination in the
                Philippines.
              </p>
            </div>

            <!-- Recent services Widget -->
            <div class="widget widget-sigma-recent-posts mb-3">
              <div class="sigma_recent-post">
                <a class="recent-post-image">
                  <i class="flaticon-logistics" style="font-size: 64px"></i>
                </a>
                <div class="recent-post-descr">
                  <h5>OCEAN FREIGHT</h5>
                  <p>
                    LOGO ensures that all your Balikbayan box shipments are handled efficiently
                    and will arrive at their destination completely intact.
                  </p>
                </div>
              </div>
              <div class="sigma_recent-post">
                <a class="recent-post-image">
                  <i class="flaticon-export" style="font-size: 64px"></i>
                </a>
                <div class="recent-post-descr">
                  <h5>CONTAINER SHIPMENT</h5>
                  <p>
                    LOGO offers competitive rates for large-scale volume shipments for
                    our balikbayan fellows and their belongings.
                  </p>
                </div>
              </div>
              <div class="sigma_recent-post">
                <a class="recent-post-image">
                  <i class="flaticon-cargo" style="font-size: 64px"></i>
                </a>
                <div class="recent-post-descr">
                  <h5>LOOSE CARGO SHIPMENT</h5>
                  <p>
                    LOGO provides shipments for non-packaged cargo for your special
                    padala and packages.
                  </p>
                </div>
              </div>
            </div>

            <!--Section Start-->
            <!-- <div class="section-title">
              <h4>Calculate Shipping Estimates</h4>
            </div>
            <div class="row justify-content-start">
              <div class="col-md-12">
                <form method="post">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Origin</label>
                        <input type="text" name="origin" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Destination</label>
                        <input type="text" name="destination" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Balikbayan Box weight in kg.</label>
                        <input type="text" name="box_weight" />
                      </div>
                    </div>
                    <div class="col-12">
                      <button type="button" class="btn-block">Calculate</button>
                    </div>
                  </div>
                </form>
              </div>
            </div> -->
            <!--Section End-->

            <div class="col-lg-12 mb-lg-30">
              <h4>Track Shipment</h4>

              <div class="col-lg-12">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="tracking_number"
                    id="invoiceno"
                    placeholder="Enter your Tracking # / Invoice #"
                    [formControl]="invoiceFormControl"
                  />
                  <button class="sigma_btn" (click)="trackInvoice()">
                    TRACK
                  </button>
                </div>
              </div>
              <p id="trackmessage" style="margin-top: 20px">
                To track your boxes, please enter your invoice number in the
                search box and press the <b style="color: red">"Track"</b> button.
              </p>
            </div>

            <div class="row justify-content-center mt-2" *ngIf="errorMsg">
              <div class="col-md-12">
                <div
                  class="alert alert-warning alert-dismissible fade show text-center"
                  role="alert"
                >
                  {{ errorMsg }}
                </div>
              </div>
            </div>

            <div class="row justify-content-center mt-2" *ngIf="searchResult != null">
              <div class="col-md-12" *ngIf="hasConsigneeDetails">
                <div class="card box">
                  <div class="card-body">
                    <h3 class="card-title text-success mb-2">
                      <i class="fa fa-file-invoice"></i>&nbsp; Invoice Details
                    </h3>
                    <p class="card-text mb-1">
                      Consignee Name:
                      <strong>{{ searchResult.consigneeName }}</strong>
                    </p>
                    <p class="card-text mb-1">
                      Number of Boxes:
                      <strong>{{ searchResult.noOfBoxes }}</strong>
                    </p>
                    <p class="card-text mb-1">
                      Date Delivered:
                      <strong>{{
                        searchResult.dateDelivered | date: "fullDate"
                      }}</strong>
                    </p>
                    <p class="card-text mb-1">
                      Remarks: <strong>{{ searchResult.remarks }}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <table class="table table-bordered table-striped mt-3">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of searchResult.invoiceStatuses; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ item.date | date: "fullDate" }}</td>
                      <td [innerHTML]="item.status"></td>
                      <td [innerHTML]="item.comments"></td>
                    </tr>
                    <tr *ngIf="searchResult.invoiceStatuses.length === 0">
                      <td colspan="6" class="text-center text-secondary">
                        <em>~ There's no update on this invoice yet ~</em>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Sidebar Start -->
        <div class="col-lg-4">
          <div class="sidebar">
            <!-- Cargo Widget -->
            <div class="widget widget-categories">
              <h5 class="widget-title">Categories</h5>
              <ul>
                <li>
                  <a routerLink="/balikbayan-box"> Balikbayan Box </a>
                </li>
                <li>
                  <a routerLink="/remittance-details"> Money Remittance </a>
                </li>
                <li>
                  <a routerLink="/"> Logistics </a>
                </li>
                <li>
                  <a routerLink="/"> Brokerage </a>
                </li>
                <li>
                  <a routerLink="/"> E-Commerce </a>
                </li>
              </ul>
            </div>
            <!-- Recent Posts Widget -->
            <!-- <div class="widget widget-sigma-recent-posts">
              <h5 class="widget-title">Most Commented</h5>
              <div class="sigma_recent-post">
                <a href="blog-details.html" class="recent-post-image">
                  <img src="assets/img/services/sm/1.jpg" alt="img">
                  <span>12</span>
                </a>
                <div class="recent-post-descr">
                  <h6>
                    <a href="blog-details.html">Every Next Level Of Your Life Will Demand</a>
                  </h6>
                  <a href="blog-details.html" class="date">
                    <i class="far fa-clock mr-2"></i>
                    June 4, 2021
                  </a>
                </div>
              </div>
              <div class="sigma_recent-post">
                <a href="blog-details.html" class="recent-post-image">
                  <img src="assets/img/services/sm/2.jpg" alt="img">
                  <span>9</span>
                </a>
                <div class="recent-post-descr">
                  <h6>
                    <a href="blog-details.html">This is a test to try out how things work</a>
                  </h6>
                  <a href="blog-details.html" class="date">
                    <i class="far fa-clock mr-2"></i>
                    January 4, 2021
                  </a>
                </div>
              </div>
              <div class="sigma_recent-post">
                <a href="blog-details.html" class="recent-post-image">
                  <img src="assets/img/services/sm/3.jpg" alt="img">
                  <span>6</span>
                </a>
                <div class="recent-post-descr">
                  <h6>
                    <a href="blog-details.html">It is them who will figure out the pattern</a>
                  </h6>
                  <a href="blog-details.html" class="date">
                    <i class="far fa-clock mr-2"></i>
                    June 4, 2021
                  </a>
                </div>
              </div>
            </div> -->
            <!-- Subscribe Widget -->
            <!-- <div class="widget widget-newsletter">
              <h5 class="widget-title">Join Newsletter</h5>
              <form method="post">
                <input type="email" name="email" placeholder="Enter your email">
                <button type="button" class="btn-block mt-4">Subscribe</button>
              </form>
            </div> -->
            <!-- Recent Posts Widget -->
            <!-- <div class="widget widget-sigma-recent-posts style-2">
              <h5 class="widget-title">Recommended</h5>
              <div class="sigma_recent-post">
                <a href="blog-details.html" class="recent-post-image">
                  <img src="assets/img/posts/md/1.jpg" alt="img">
                </a>
                <div class="recent-post-descr">
                  <h6>
                    <a href="blog-details.html">Every Next Level Of Your Life Will Demand Something</a>
                  </h6>
                  <a href="blog-details.html" class="date">
                    <i class="far fa-clock mr-2"></i>
                    June 4, 2021
                  </a>
                </div>
              </div>
              <div class="sigma_recent-post">
                <a href="blog-details.html" class="recent-post-image">
                  <img src="assets/img/posts/md/2.jpg" alt="img">
                </a>
                <div class="recent-post-descr">
                  <h6>
                    <a href="blog-details.html">We are the champions, we do not give up, we try</a>
                  </h6>
                  <a href="blog-details.html" class="date">
                    <i class="far fa-clock mr-2"></i>
                    June 4, 2021
                  </a>
                </div>
              </div>
            </div> -->
            <!-- Recent Posts Widget -->
            <!-- <div class="widget widget-sigma-recent-posts style-3">
              <h5 class="widget-title">Recent Post</h5>
              <div class="sigma_recent-post">
                <div class="sigma_post-categories">
                  <a routerLink="/">Cargo</a>
                </div>
                <div class="recent-post-descr">
                  <h6>
                    <a href="blog-details.html">Every Next Level Of Your Life Will Demand Something</a>
                  </h6>
                  <div class="author-info d-flex align-items-center">
                    <span>TA</span>
                    <div>
                      <a routerLink="/" class="author-name">Tim Abell</a>
                      <a href="blog-details.html" class="date">June 4, 2021</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sigma_recent-post">
                <div class="sigma_post-categories">
                  <a routerLink="/">Shipping</a>
                </div>
                <div class="recent-post-descr">
                  <h6>
                    <a href="blog-details.html">We are the champions, we do not give up, we try</a>
                  </h6>
                  <div class="author-info d-flex align-items-center">
                    <span>TA</span>
                    <div>
                      <a routerLink="/" class="author-name">Tim Abell</a>
                      <a href="blog-details.html" class="date">June 4, 2021</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sigma_recent-post">
                <div class="sigma_post-categories">
                  <a routerLink="/">Cargo</a>
                </div>
                <div class="recent-post-descr">
                  <h6>
                    <a href="blog-details.html">Just believe in yourself, you are truly worth it.</a>
                  </h6>
                  <div class="author-info d-flex align-items-center">
                    <span>TA</span>
                    <div>
                      <a routerLink="/" class="author-name">Tim Abell</a>
                      <a href="blog-details.html" class="date">June 4, 2021</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Tags Widget -->
            <!-- <div class="widget tagcloud">
              <h5 class="widget-title">Popular Tags</h5>
              <a routerLink="/">Destination</a>
              <a routerLink="/">Cargo</a>
              <a routerLink="/">News</a>
              <a routerLink="/">Startup</a>
              <a routerLink="/">Strategy</a>
            </div> -->
            <!-- Follow Widget -->
            <div class="widget widget-follow">
              <h5 class="widget-title">Affiliates</h5>
              <div class="social-buttons">
                <img
                  src="assets/img/clients/mlhuiller.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/bdo.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/bpi.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/cebuana.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/landbank.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/palawan.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/pnb.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/psbank.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/rdpawnshop.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/tambunting.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/metrobank.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/rcbc.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/securitybank.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/eastwest.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/chinabank.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/unionbank.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
                <img
                  src="assets/img/clients/robinson.jpg"
                  alt="clients"
                  class="btn-block"
                  style="width: 220px"
                />
              </div>
            </div>

            <!-- Recent Post Widget -->
            <!-- <div class="widget widget-sigma-recent-posts style-4">
              <h5 class="widget-title">Recent Comments</h5>
              <div class="sigma_recent-post align-items-start">
                <a routerLink="/" class="recent-post-image">
                  <i class="fal fa-comment-alt-dots"></i>
                </a>
                <div class="recent-post-descr">
                  <h6>
                    <a routerLink="/"><span>Tim Abell On</span> Five Places to visit in Oakland with Fantastic Ngrito </a>
                  </h6>
                </div>
              </div>
              <div class="sigma_recent-post align-items-start">
                <a routerLink="/" class="recent-post-image">
                  <i class="fal fa-comment-alt-dots"></i>
                </a>
                <div class="recent-post-descr">
                  <h6>
                    <a routerLink="/"><span>Tim Abell On</span> Five Places to visit in Oakland with Fantastic Ngrito </a>
                  </h6>
                </div>
              </div>
              <div class="sigma_recent-post align-items-start">
                <a routerLink="/" class="recent-post-image">
                  <i class="fal fa-comment-alt-dots"></i>
                </a>
                <div class="recent-post-descr">
                  <h6>
                    <a routerLink="/"><span>Tim Abell On</span> Five Places to visit in Oakland with Fantastic Ngrito </a>
                  </h6>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- Sidebar End -->
      </div>
    </div>
  </div>
</div>

