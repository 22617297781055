import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class JobApplicationService {
  constructor(
    private http: HttpClient
  ) { }

  saveJobApplication(params: any) {
    return this.http.post(`${API_URL}/job-application/save`, params);
  }
}
