import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { invoiceStatus, TrackFremanItem, TrackItem } from 'src/app/models';
import { DataService } from 'src/app/services/data.service';
import { environment } from '../../../environments/environment';

interface InvoiceNotes {
  dateTime: Date;
  invNotes: any[];
}

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css'],
})
export class TrackingComponent implements OnInit {
  @ViewChild('input')
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
      window.scrollTo(0, 0);
    }
  }

  errorMsg = '';
  result: TrackFremanItem = null;
  notes: any[] = [];
  shouldTrackFremanInvoice: boolean = environment.SHOULD_TRACK_FREMAN_INVOICE;
  telephone: string = environment.contact.telephone_us;
  isPackageReceived = false;
  invoiceForm: FormGroup;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Package Tracker | LOGO');
    this.meta.updateTag({ name: 'description', content: 'LOGO - Package Tracker' });

    this.invoiceForm = this.formBuilder.group({
      invoice: ['']
    });

    this.route.queryParams.subscribe(params => {
      const invoiceNo = params?.invoice;
      if (invoiceNo) {
        this.invoiceForm.get('invoice').setValue(invoiceNo);
        this.loadInvoiceStatuses();
      }
    });
  }

  loadInvoiceStatuses(): void {
    this.spinner.show();
    this.errorMsg = '';
    let invoice: string = this.invoiceForm.get('invoice').value;
    invoice = invoice.trim();
    this.dataService.getInvoiceStatuses(invoice).subscribe(
      (res: TrackFremanItem) => {
        this.result = res;
        this.changeDetector.detectChanges();
        this.spinner.hide();
        this.loadInvoiceNotes();
      },
      error => {
        console.log(error);
        this.spinner.hide();
        this.result = null;
        this.errorMsg = 'We are unable to find the invoice number you provided. Please make sure you have the correct number or you can call our help desk support at Tel. No. (707) 561-8091';
      }
    );
  }

  loadInvoiceNotes(): void {
    this.spinner.show();
    this.errorMsg = '';
    const invoice = this.invoiceForm.get('invoice').value;
    this.dataService.getInvoiceNotes(invoice).subscribe(
      (res: InvoiceNotes) => {
        this.notes = res.invNotes;
        this.changeDetector.detectChanges();
        this.spinner.hide();
      },
      error => {
        console.log(error);
        this.spinner.hide();
        this.notes = null;
        this.errorMsg = 'We are unable to find the invoice number you provided. Please make sure you have the correct number or you can call our help desk support at Tel. No. (707) 561-8091';
      }
    );
  }

  array_move(arr: any[], old_index: number, new_index: number) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }
}
