import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  invoiceFormControl: FormControl;

  slides = [
    { img: 'assets/img/services/logistics-320x200.jpg' },
    { img: 'http://placehold.it/350x150/111111' },
    { img: 'http://placehold.it/350x150/333333' },
    { img: 'http://placehold.it/350x150/666666' },
  ];
  /* slideConfig = {"slidesToShow": 1, "slidesToScroll": 1}; */

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  website_config: any = JSON.parse(localStorage.getItem('website_config'));
  images: any = JSON.parse(localStorage.getItem('res_images'));
  partners: any = JSON.parse(localStorage.getItem('res_partners'));

  constructor(
    public utilityService: UtilityService,
    private router: Router,
    private toastr: ToastrService,
    private title: Title,
    private meta: Meta
  ) {
    this.website_config
  }

  ngOnInit() {
    this.title.setTitle('Home | LOGO');
    this.meta.updateTag({ name: 'description', content: 'LOGO - Home Page' });

    $('.sigma_banner-slider').slick({
      autoplay: true,
      autoplaySpeed: 5000,
    });

    $('.sigma_team-slider').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      slideConfig: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    });

    this.countJs();

    this.invoiceFormControl = new FormControl('');
  }

  countJs() {
    (function ($) {
      $.fn.countTo = function (options) {
        // merge the default plugin settings with the custom options
        options = $.extend({}, $.fn.countTo.defaults, options || {});

        // how many times to update the value, and how much to increment the value on each update
        var loops = Math.ceil(options.speed / options.refreshInterval),
          increment = (options.to - options.from) / loops;

        return $(this).each(function () {
          var _this = this,
            loopCount = 0,
            value = options.from,
            interval = setInterval(updateTimer, options.refreshInterval);

          function updateTimer() {
            value += increment;
            loopCount++;
            $(_this).html(value.toFixed(options.decimals));

            if (typeof options.onUpdate == 'function') {
              options.onUpdate.call(_this, value);
            }

            if (loopCount >= loops) {
              clearInterval(interval);
              value = options.to;

              if (typeof options.onComplete == 'function') {
                options.onComplete.call(_this, value);
              }
            }
          }
        });
      };

      $.fn.countTo.defaults = {
        from: 0, // the number the element should start at
        to: 100, // the number the element should end at
        speed: 1000, // how long it should take to count between the target numbers
        refreshInterval: 100, // how often the element should be updated
        decimals: 0, // the number of decimal places to show
        onUpdate: null, // callback method for every time the element is updated,
        onComplete: null, // callback method for when the element finishes updating
      };
    })(jQuery);

    jQuery(function ($) {
      $('.counter').countTo({
        from: 0,
        to: 20,
        speed: 5000,
        refreshInterval: 50,
        onComplete: function (value) {
          console.debug(this);
        },
      });
    });
  }

  next() {
    this.slickModal.slickNext();
  }

  prev() {
    this.slickModal.slickPrev();
  }

  redirectTrackingPage() {
    const invoice = this.invoiceFormControl.value;
    if (invoice === '') {
      this.toastr.warning('Please input invoice number to proceed tracking.');
    } else {
      this.router.navigateByUrl(`/tracking?invoice=${this.invoiceFormControl.value}`);
    }
  }

  openSocialLink(link: string) {
    window.open(link, '_blank');
  }

  downloadApp(device: string): void {
    if (device === 'android') {
      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.location.href = 'market://details?id=com.axp.customer';
      } else {
        window.location.href = environment.appStoreLink[device];
      }
    } else if (device === 'ios') {
      if (navigator.userAgent.toLowerCase().indexOf('ios') > -1) {
        window.location.href = 'market://details?id=com.axp.customer';
      } else {
        window.location.href = environment.appStoreLink[device];
      }
    }
  }
}
