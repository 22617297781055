import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { IpService } from 'src/app/services/ip.service';
import { LocationService } from 'src/app/services/location.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { User } from 'src/app/models';

const timezone: string = moment.tz.guess();

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  currUser: Observable<any>;
  countryFlagLink: string = '';

  form: FormGroup;
  isSubmitted: boolean = false;
  isSucceeded: boolean = false;

  isMobileNavShown: boolean = false;
  isAuthBtnShown: boolean = true;

  telephone: string = environment.contact.telephone_us;
  telephone_canada: string = environment.contact.telephone_canada;
  telephone_uk: string = environment.contact.telephone_uk;
  mobileSmart: string = environment.contact.mobile_smart;
  mobileSmart2: string = environment.contact.mobile_smart2;
  mobileGlobe: string = environment.contact.mobile_globe;

  images: any = JSON.parse(localStorage.getItem('res_images'));

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private ipService: IpService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.currUser = this.authService.user;

    this.currUser.subscribe(user => {
      if (user) {
        this.isAuthBtnShown = false;
      } else {
        this.isAuthBtnShown = true;
      }
    });

    this.getCountryFlag();

    this.form = this.formBuilder.group({
      email: ['', [
        Validators.required, Validators.email
      ]],
    });
  }

  async getCountryFlag() {
    this.locationService.getLocation().subscribe
    (async (pos: GeolocationPosition) => {
      const latitude = pos.coords.latitude;
      const longitude = pos.coords.longitude;
      const geocoded = await this.locationService.getAddress(latitude, longitude);
      const clientTimeZone = timezone;
      let countryCode = geocoded.countryObj.short_name.toLowerCase();
      if (!countryCode || countryCode === '' || countryCode === null) {
        countryCode = 'us';
      }

      localStorage.setItem(environment.CLIENT_COUNTRY, countryCode);
      localStorage.setItem(environment.CLIENT_TIMEZONE, clientTimeZone);
      this.countryFlagLink = `../../../assets/img/flags/${countryCode}.svg`;
    }, error => {
      console.error(error);
    });
  }

  toggleMobileNav() {
    this.isMobileNavShown = !this.isMobileNavShown;
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('home', { replaceUrl: true });
  }

  openSocialLink(link: string) {
    window.open(link, '_blank');
  }

  async onSubmit() {
    if (this.form.invalid) {
      this.toastr.warning('Please complete required fields.');
      return;
    }

    try {
      this.isSubmitted = true;
      this.spinner.show();
      await this.dataService.sendNewsLetterSubscription(this.form.value).toPromise();
      this.spinner.hide();
      this.form.reset();
      window.scrollTo(0, 0);
      this.isSucceeded = true;
      this.toastr.success('Please check your email regularly.', 'Sent Successfully!');
    } catch (error) {
      this.toastr.error('Someting went wrong sending request. Please try again.', 'Oops!');
      console.log(error.error);
      this.spinner.hide();
    }
  }

  get formControl() {
    return this.form.controls;
  }

  get version() {
    return environment.version;
  }

  downloadApp(device: string): void {
    if (device === 'android') {
      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.location.href = 'market://details?id=com.axp.customer';
      } else {
        window.location.href = environment.appStoreLink[device];
      }
    } else if (device === 'ios') {
      if (navigator.userAgent.toLowerCase().indexOf('ios') > -1) {
        window.location.href = 'market://details?id=com.axp.customer';
      } else {
        window.location.href = environment.appStoreLink[device];
      }
    }
  }
}
