import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { FremanService } from 'src/app/services/freman.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  result = {};
  isLoaded = new BehaviorSubject<boolean>(false);
  hasExpired = new BehaviorSubject<boolean>(false);
  hasError = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private fremanService: FremanService
  ) { }

  ngOnInit(): void {
    let id = this.route.snapshot.params['id'];

    this.spinner.show();

    this.fremanService.getSmsInvoiceDetails(id).subscribe(res => {
      if (this.isExpired(res.createdDate)) {
        this.hasExpired.next(true);
      } else {
        this.isLoaded.next(true);
        this.result = res;
      }
    }, err => {
      this.hasError.next(true);
    });
  }

  isExpired(date) {
    let invDate = new Date(date);
    let expDate = new Date(date);

    expDate.setDate(expDate.getDate() + 90);

    return invDate >= expDate;
  }
}
