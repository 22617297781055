import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { CmsService } from 'src/app/services/cms.service';

const timezone = moment.tz.guess();

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {
  blogs: any = [];
  shouldShowCMS: boolean = false;

  constructor(
    private meta: Meta,
    private title: Title,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private cmsService: CmsService
  ) { }

  ngOnInit(): void {
    if(this.authService.getUser() != null && this.authService.getUser().userType == 'ADMINISTRATOR') {
      this.shouldShowCMS = true;
    }

    this.updateMetaTags();
    this.loadBlogs();
  }

  formatStamp(timestamp) {
    return moment(timestamp).tz(timezone).format('MMMM D, YYYY hh:mm A');
  }

  humanReadableStamp(timestamp) {
    return moment(timestamp).fromNow();
  }

  encryptText(text) {
    return btoa(text);
  }

  updateMetaTags() {
    this.title.setTitle('LOGO - Blogs');
    this.meta.updateTag({ name: 'og:type', content: 'website' });
  }

  loadBlogs() {
    this.spinner.show();

    this.cmsService.listPublishedBlogs().subscribe((result: any) => {
      this.spinner.hide();

      console.log(result);

      this.blogs = result.map((item: any) => {
        let doc = new DOMParser().parseFromString(item.blogContent, 'text/html');
        let first_image = '';
        let first_paragraph = '';
        let max_paragraph_display = 200;

        doc.querySelectorAll('img').forEach((el, i) => {
          if(i == 0) {
            first_image = el.src;
          }
        });

        doc.querySelectorAll('p').forEach((el) => {
          let el_content = el.textContent.trim();

          if(first_paragraph == '' && el_content != '' && el_content.length > 0) {
            first_paragraph = el_content;
          }
        });

        return {
          ...item,
          first_image: first_image.length > 0 ? first_image : '',
          first_paragraph: first_paragraph.length > max_paragraph_display ? first_paragraph.substring(0, max_paragraph_display) + '...' : first_paragraph
        };
      });
    }, (error) => {
      this.spinner.hide();

      this.blogs = [];
    });
  }
}
