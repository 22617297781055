import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dropoff',
  templateUrl: './dropoff.component.html',
  styleUrls: [
    './../template.scss',
    './dropoff.component.scss'
  ]
})
export class DropoffComponent implements OnInit {
  @Input() details: any;

  constructor() { }

  ngOnInit(): void { }

  formatHtml(html) {
    var formattedHtml = html
      .replace(/class="details"/g, `style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: auto;"`)
      .replace(/<th>/g, `<th style='border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #04AA6D;color: white;font-size:13px;font-weight: 700;'>`)
      .replace(/<td>/g, `<td style='border: 1px solid #ddd;padding: 8px;'>`)
      .replace(/style='text-align:right;'/g, `style='text-align:right;border: 1px solid #ddd;padding: 8px;'`)
      .replace(/border:none;/g, 'padding: 8px;');

    return formattedHtml;
  }
}
