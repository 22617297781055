import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeolocationService } from '@ng-web-apis/geolocation';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private readonly geolocation$: GeolocationService,
    private http: HttpClient
  ) {}

  geocode(lat: number, lng: number): Observable<any> {
    const latlng = `${lat}, ${lng}`;
    const url = `${environment.googleMapsAPIUrl}/geocode/json?latlng=${latlng}&key=${environment.googleMapAPIKey}`;
    return this.http.get(url);
  }

  async getAddress(lat: number, lng: number) {
    const data = await this.geocode(lat, lng).toPromise();
    const result = data.results[0];
    const address = result.formatted_address;
    const addressComponents = result.address_components;

    const localityObj = _.find(addressComponents, { types: ['locality'] });
    const locality = localityObj != null ? localityObj.short_name : '';
    const stateObj = _.find(addressComponents, {
      types: ['administrative_area_level_1'],
    });
    const state = stateObj != null ? stateObj.short_name : '';

    const countryObj = _.find(addressComponents, { types: ['country'] });
    const country = countryObj != null ? countryObj.long_name : '';

    const zipCodeObj = _.find(addressComponents, { types: ['postal_code'] });
    const zipCode = zipCodeObj != null ? zipCodeObj.short_name : '';

    return {
      address,
      localityObj,
      locality,
      stateObj,
      state,
      countryObj,
      country,
      zipCode,
    };
  }

  getLocation(): Observable<GeolocationPosition> {
    return this.geolocation$.pipe(take(1));
  }
}
