import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const API_URL = environment.apiUrl;
const helper = new JwtHelperService();

export const CMS_TOKEN_KEY = 'cms-jwt-token';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private cmsUserSubject = new BehaviorSubject(null);
  public cmsUser: Observable<any>;

  constructor(
    private http: HttpClient
  ) {
    this.loadStoredToken();
  }

  loadStoredToken() {
    const token = localStorage.getItem(CMS_TOKEN_KEY);
    let decodedToken = helper.decodeToken(token);

    this.cmsUserSubject.next(decodedToken);
    this.cmsUser = this.cmsUserSubject;
  }

  login(credentials) {
    return this.http.post(`${API_URL}/cms/login`, credentials, {
        responseType: 'text'
      }).pipe(
        map(token => {
          localStorage.setItem(CMS_TOKEN_KEY, token);

          let decodedToken = helper.decodeToken(token);

          this.cmsUserSubject.next(decodedToken);

          return token;
        })
      );
  }

  register(credentials) {
    return this.http.post(`${API_URL}/cms/register`, credentials, {
        responseType: 'text'
      }).pipe(
        map(token => {
          localStorage.setItem(CMS_TOKEN_KEY, token);

          let decodedToken = helper.decodeToken(token);

          this.cmsUserSubject.next(decodedToken);

          return token;
        })
      );
  }

  changePassword(params) {
    return this.http.put(`${API_URL}/cms/change-password`, params);
  }

  logout() {
    localStorage.clear();

    this.cmsUserSubject.next(null);
  }

  getCmsUser() {
    return this.cmsUserSubject.getValue();
  }

  /*
  * Blog
  */
  listBlogs() {
    return this.http.get(`${API_URL}/cms/blog/list`);
  }

  getPublishedBlog(id) {
    return this.http.get(`${API_URL}/cms/blog/published/get/${id}`);
  }

  listPublishedBlogs() {
    return this.http.get(`${API_URL}/cms/blog/published/list`);
  }

  createBlog(params) {
    return this.http.post(`${API_URL}/cms/blog/create`, params);
  }

  updateBlog(params) {
    return this.http.put(`${API_URL}/cms/blog/update`, params);
  }

  deleteBlog(id) {
    return this.http.delete(`${API_URL}/cms/blog/delete/${id}`);
  }

  publishBlog(params) {
    return this.http.post(`${API_URL}/cms/blog/publish`, params);
  }

  /*
  * User
  */
  listUsers() {
    return this.http.get(`${API_URL}/cms/user/list`);
  }

  createUser(params) {
    return this.http.post(`${API_URL}/cms/user/create`, params);
  }

  updateUser(params) {
    return this.http.put(`${API_URL}/cms/user/update`, params);
  }

  deleteUser(id) {
    return this.http.delete(`${API_URL}/cms/user/delete/${id}`);
  }

  /*
  * Other Tools
  */
  httpErrorPrompt(message, e) {
    console.log(e);

    Swal.fire({
      icon: 'error',
      title: message,
      text: (e.error != null && e.error.InnerException != null ? e.error.InnerException.Message : (e.error.detail != null ? e.error.detail : ''))
    })
  }
}
