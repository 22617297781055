import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor(private http: HttpClient) { }

  getIPAddress(): Observable<any> {  
    return this.http.get("https://api.ipify.org?format=json");  
  }

  getIPLocation(ipaddress: string): Observable<any> {
    return this.http.get(`https://ipapi.co/${ipaddress}/json`);  
  }
}
