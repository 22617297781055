import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import { toDataURL } from 'qrcode';
import { environment } from 'src/environments/environment';
import autoTable from 'jspdf-autotable';
import * as _ from 'lodash';
import { ucfirst } from '../utils/FormatterUtil';

const DEFAULT_FONT = 'Helvetica';
const API_URL = environment.apiUrl;
const settings = {
  margin: 5,
  color: {
    black: '#0F0F0F',
    'black-light': '#616161',
    gray: '#BBBBBB',
    green: '#97AD75',
    purple: '#8650AD',
    'purple-light': '#BBBADC',
  },
};

export interface TableData {
  boxSizeCode: string;
  zoneCode: string;
  body: any[];
  foot: any[];
}

export interface PaymentMethod {
  paymentType: string;
  isChecked: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InvoicePdfService {
  pdf: string = '';

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) {}

  async generateJSPDF(invoices: any[]) {
    const doc: jsPDF = new jsPDF('p', 'mm', 'a4');

    const pageWith = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    for (var i = 0; i < invoices.length; i++) {
      let data = invoices[i];
      await this.header(doc, data);
      await this.mainContent(doc, data);
      doc.addPage();
    }

    doc.deletePage(doc.getNumberOfPages());

    return doc;
  }

  async header(doc: jsPDF, data) {
    const pageWith = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const yCenter = pageHeight / 2;
    const xCenter = pageWith / 2;

    /*     doc
          .setFontSize(10)
          .setFont(DEFAULT_FONT, 'bolditalic')
          .text('LOGO', 30, 12);
        doc
          .setFontSize(8)
          .setFont(DEFAULT_FONT, 'italic')
          .text(`Door-to-Door Cargo from ${data.BranchCountyId} to the Philippines`, 30, 16); */

    let countryId = data.BranchCountyId;
    if (countryId == 'UK') {
      var logo = new Image();
      logo.src = '/assets/img/bm-logo.png';
      doc.addImage(logo, 'png', 3, 3.5, 37, 25);

      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'bold')
        .text(`IN PARTNERSHIP WITH:`, 44, 8);

      logo = new Image();
      logo.src = '/assets/img/abxp-logo.png';
      doc.addImage(logo, 'png', 42, 8.5, 40, 17);

      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'bold')
        .text(`POWERED BY:`, 86.5, 8);

      logo = new Image();
      logo.src = '/assets/img/logo.png';
      doc.addImage(logo, 'png', 85, 10, 35, 14.5);
    } else if (countryId == 'US') {
      const logo = new Image();
      logo.src = '/assets/img/atlas-asi.png';
      doc.addImage(logo, 'png', 3, 3.5, 32, 23);

      logo.src = '/assets/img/axpremit.png';
      doc.addImage(logo, 'png', 32.5, 5.5, 68.5, 20);
    } else {
      const logo = new Image();
      logo.src = '/assets/img/axp-cargo.png';
      doc.addImage(logo, 'png', 3, 3.5, 72, 20);
    }

    let phOfficeXPos =
      countryId === 'UK'
        ? xCenter / 2 + xCenter + 6
        : countryId === 'US'
        ? xCenter - 2.5
        : xCenter - 16;
    doc
      .setFontSize(8)
      .setFont(DEFAULT_FONT, 'bold')
      .text('PH MAIN OFFICE', phOfficeXPos, 8);
    doc
      .setFontSize(8)
      .setFont(DEFAULT_FONT, 'normal')
      .text('Purok 1, Brgy. Matapitap', phOfficeXPos, 12);
    doc
      .setFontSize(8)
      .setFont(DEFAULT_FONT, 'normal')
      .text('Gerona, Tarlac 2302', phOfficeXPos, 16);
    doc
      .setFontSize(8)
      .setFont(DEFAULT_FONT, 'normal')
      .text('cs@logocargo.com', phOfficeXPos, 20);
    doc
      .setFontSize(8)
      .setFont(DEFAULT_FONT, 'normal')
      .text('0930-226-7580 / 0966-428-8618', phOfficeXPos, 24);
    // Col4 ------------
    let mainOfficeXPos =
      countryId === 'UK' ? xCenter + 20 : xCenter / 2 + xCenter - 10;
    doc
      .setFontSize(8)
      .setFont(DEFAULT_FONT, 'bold')
      .text(
        `${
          countryId === 'US'
            ? 'US MAIN OFFICE'
            : countryId === 'UK'
            ? 'MANCHESTER, UK'
            : 'CA Corporate OFFICE'
        }`,
        mainOfficeXPos,
        8
      );

    if (countryId === 'US') {
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text(
          '4950 Fulton Drive, Unit F Fairfield, CA 94534',
          mainOfficeXPos,
          12
        );
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('24/7 Customer Service : +1 (800) 883-1998', mainOfficeXPos, 16);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('cs@logocargo.com', mainOfficeXPos, 20);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('www.atlasxpadala.com | www.axpgroup.com', mainOfficeXPos, 24);
    } else if (data.BranchCountyId === 'CA') {
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('800 Boyer Boulevard, MISSISSAUGA, ON', mainOfficeXPos, 12);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('L5V 2Y1, Canada', mainOfficeXPos, 16);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('24/7 Customer Service : (888) 721-5298', mainOfficeXPos, 20);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('cs@logocargo.com', mainOfficeXPos, 24);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('www.atlasxpadala.com | www.axpgroup.com', mainOfficeXPos, 28);
    } else if (data.BranchCountyId === 'UK') {
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('19 Ancoats Grove Beswick,', mainOfficeXPos, 12);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('Manchester M4 7DL', mainOfficeXPos, 16);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('Tel. #: +44 20 4571 1959', mainOfficeXPos, 20);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('Registration No.: 10584523', mainOfficeXPos, 24);
      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'normal')
        .text('www.bien-myrna.co.uk', mainOfficeXPos, 28);
    }
  }

  async mainContent(doc: jsPDF, data) {
    data = this.preloadDefaultData(data);
    const countryId = data.BranchCountyId;

    const pageWith = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const yCenter = pageHeight / 2;
    const xCenter = pageWith / 2;

    const senderConsigneeLine = pageWith - 70;
    const secondCol = senderConsigneeLine / 2 + settings.margin + 5;
    const thirdCol = senderConsigneeLine / 2 + settings.margin + 2;
    const separatorXPos = (pageWith - 70) / 2;
    // Sender/Consignee
    doc.setLineWidth(0.5);
    doc.setDrawColor(settings.color.green);
    doc.line(settings.margin, 30, senderConsigneeLine, 30);
    doc.line(separatorXPos, 30, separatorXPos, 80);

    doc
      .setFontSize(12)
      .setFont(DEFAULT_FONT, 'bold')
      .setTextColor(settings.color.purple)
      .text('Sender', settings.margin, 36);

    doc
      .setFontSize(12)
      .setFont(DEFAULT_FONT, 'bold')
      .setTextColor(settings.color.purple)
      .text('Consignee', secondCol + 2 - 10, 36);

    const customer = ucfirst(data.CustomerFullname);
    this.senderDetails(doc, data, secondCol + 2);
    this.consigneeDetails(doc, data, secondCol + 2);
    // ------------

    // Branch
    const branchDimensions = {
      x: senderConsigneeLine + 5,
      y: 36,
    };

    const branchAddress = ucfirst(data.BranchAddress.trim());
    const branchCityName = ucfirst(data.BranchCityName.trim());
    const branchStateName = ucfirst(data.BranchStateName.trim());
    const branchZipcode = ucfirst(data.BranchZipcode.trim());
    const branchCountyId = ucfirst(data.BranchCountyId.trim());

    const branchCompleteAddress = `${branchAddress} ${branchCityName} ${branchStateName} ${branchZipcode} ${branchCountyId}`;
    const splitBranchAddress = doc.splitTextToSize(branchCompleteAddress, 50);

    doc
      .setFontSize(10)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black)
      .text('Branch:', branchDimensions.x, branchDimensions.y)
      .setFont(DEFAULT_FONT, 'bold')
      .text(data.BranchName, branchDimensions.x + 13, branchDimensions.y)
      .setFontSize(9)
      .setFont(DEFAULT_FONT, 'normal')
      .text(splitBranchAddress, branchDimensions.x, 41)
      .text(`Tel. No.: ${data.BranchPhoneNumber}`, branchDimensions.x, 50)
      .text(
        data.CreatedBy != 'null' ? `Agent Code: ${data.CreatedBy}` : '',
        branchDimensions.x,
        55
      )
      .text(`Invoice No.: ${data.InvoiceNo}`, branchDimensions.x, 60)
      .text(
        `${
          data.ServiceTypeCode === 'PICKUP' ? 'Pick Up Date' : 'Drop Off Date'
        }: ${this.datePipe.transform(data.ServiceTypeDate, 'MMM d, yyyy')}`,
        branchDimensions.x,
        65
      );

    this.paymentMethod(doc, data, branchDimensions.x);

    doc
      .setLineWidth(0.1)
      .setDrawColor(settings.color.gray)
      .line(branchDimensions.x, 93, pageWith - settings.margin - 4, 93);

    const freight = this.decimalPipe.transform(data.Freight, '1.2-2') ?? '0.00';
    const discount =
      this.decimalPipe.transform(data.Discount, '1.2-2') ?? '0.00';
    const insurance =
      this.decimalPipe.transform(data.Insurance, '1.2-2') ?? '0.00';
    const salesTax =
      this.decimalPipe.transform(data.SalesTax, '1.2-2') ?? '0.00';
    const totalAmount =
      this.decimalPipe.transform(data.TotalAmount, '1.2-2') ?? '0.00';
    const amountPaid =
      this.decimalPipe.transform(data.TotalPayment, '1.2-2') ?? '0.00';
    const tip = this.decimalPipe.transform(data.TipAmount, '1.2-2') ?? '';
    const merchantFee =
      this.decimalPipe.transform(data.MerchantFee, '1.2-2') ?? '';
    const balance = this.decimalPipe.transform(data.Balance, '1.2-2') ?? '0.00';

    const currency =
      countryId.toLowerCase() === 'us'
        ? 'USD'
        : countryId.toLocaleLowerCase() === 'uk'
        ? 'GBP'
        : 'CAD';
    doc
      .text('Freight', branchDimensions.x, 98)
      .text(currency, branchDimensions.x + 27, 98)
      .text(freight, branchDimensions.x + 40, 98)
      .text('Discount', branchDimensions.x, 103)
      .text(currency, branchDimensions.x + 27, 103)
      .text(discount, branchDimensions.x + 40, 103)
      .text('Insurance', branchDimensions.x, 108)
      .text(currency, branchDimensions.x + 27, 108)
      .text(insurance, branchDimensions.x + 40, 108)
      .text('Sales Tax', branchDimensions.x, 113)
      .text(currency, branchDimensions.x + 27, 113)
      .text(salesTax, branchDimensions.x + 40, 113);

    var additional = 0;
    if (tip) {
      doc
        .text('Tip', branchDimensions.x, 118)
        .text(currency, branchDimensions.x + 27, 118)
        .text(tip, branchDimensions.x + 40, 118);
      additional += 5;
    }

    if (merchantFee) {
      doc
        .text('Merchant Fee', branchDimensions.x, 123)
        .text(currency, branchDimensions.x + 27, 123)
        .text(merchantFee, branchDimensions.x + 40, 123);
      additional += 5;
    }

    doc.setDrawColor('#BBBBBB');
    doc.line(senderConsigneeLine + 2, 30, pageWith - settings.margin, 30);
    doc.line(
      senderConsigneeLine + 2,
      30,
      senderConsigneeLine + 2,
      134 + additional
    );
    doc.line(
      pageWith - settings.margin,
      30,
      pageWith - settings.margin,
      134 + additional
    );
    doc.line(
      senderConsigneeLine + 2,
      134 + additional,
      pageWith - settings.margin,
      134 + additional
    );

    doc
      .setLineWidth(0.1)
      .setDrawColor(settings.color.gray)
      .line(
        branchDimensions.x,
        116 + additional,
        pageWith - settings.margin - 4,
        116 + additional
      );

    doc
      .text('Total Amount', branchDimensions.x, 121 + additional)
      .text(currency, branchDimensions.x + 27, 121 + additional)
      .text(totalAmount, branchDimensions.x + 40, 121 + additional);

    if (amountPaid !== '0.00') {
      doc
        .setFontSize(10)
        .setFont(DEFAULT_FONT, 'bold')
        .setTextColor(settings.color.purple)
        .text('Amount Paid', branchDimensions.x + 3, 126 + additional)
        /* .text('Balance', branchDimensions.x + 32, 126) */
        .setFont(DEFAULT_FONT, 'normal')
        .setTextColor(settings.color.black)
        .text(
          `${currency} ${amountPaid}`,
          branchDimensions.x + 5,
          130 + additional
        );
      /* .text(`${currency} ${balance}`, branchDimensions.x + 31, 130); */
    }
    // QR Code Frame
    doc.setLineWidth(0.5);
    doc.setDrawColor(settings.color.gray);
    doc.line(
      senderConsigneeLine + 2,
      136 + additional,
      pageWith - settings.margin,
      136 + additional
    );
    doc.line(
      senderConsigneeLine + 2,
      136 + additional,
      senderConsigneeLine + 2,
      199 + additional
    );
    doc.line(
      pageWith - settings.margin,
      136 + additional,
      pageWith - settings.margin,
      199 + additional
    );
    doc.line(
      senderConsigneeLine + 2,
      199 + additional,
      pageWith - settings.margin,
      199 + additional
    );

    const invoiceNo = data.InvoiceNo;
    const qrcode = await toDataURL(
      'https://www.atlasxpadala.com/tracking?invoice=' + invoiceNo
    );
    doc
      .setFont(DEFAULT_FONT, 'bold')
      .setFontSize(15)
      .text(
        'SCAN QR',
        this.centerText(
          doc,
          'SCAN QR',
          15,
          senderConsigneeLine + 2,
          pageWith - settings.margin - (senderConsigneeLine + 2)
        ),
        143 + additional
      )

      .addImage(
        qrcode,
        'png',
        branchDimensions.x + 8.5,
        150 + additional,
        40,
        40
      )
      .setFont(DEFAULT_FONT, 'bold')
      .setFontSize(10)
      .text(
        'To Track your Balikbayan Box',
        this.centerText(
          doc,
          'To Track your Balikbayan Box',
          10,
          senderConsigneeLine + 2,
          pageWith - settings.margin - (senderConsigneeLine + 2)
        ),
        148 + additional
      )
      .setFontSize(24)
      .setFont(DEFAULT_FONT, 'bold')
      .text(
        invoiceNo,
        this.centerText(
          doc,
          invoiceNo,
          24,
          senderConsigneeLine + 2,
          pageWith - settings.margin - (senderConsigneeLine + 2)
        ),
        197 + additional
      ); /*
      .setFontSize(8)
      .setFont(DEFAULT_FONT, 'bold')
      .setTextColor(settings.color['black-light'])
      .text('INVOICE NUMBER', branchDimensions.x + 14, 205); */

    // Package Details
    doc
      .setFontSize(20)
      .setFont(DEFAULT_FONT, 'bold')
      .setTextColor(settings.color.purple)
      .text('Package Details', secondCol, 90);

    doc
      .setFillColor(settings.color['purple-light'])
      .rect(secondCol, 93, 18, 13, 'F')
      .rect(secondCol + 20, 93, 18, 13, 'F')
      .rect(secondCol + 40, 93, 18, 13, 'F')
      .rect(secondCol, 108, 18, 13, 'F')
      .rect(secondCol + 20, 108, 18, 13, 'F')
      .rect(secondCol + 40, 108, 18, 13, 'F')
      .rect(secondCol, 123, 58, 13, 'F');

    if (countryId.toLowerCase() === 'us') {
      const regular = (data.invoiceBoxTally.R || 0).toString();
      const macho = (data.invoiceBoxTally.M || 0).toString();
      const junior = (data.invoiceBoxTally.JR || 0).toString();
      const irregular = (data.invoiceBoxTally.IRR || 0).toString();
      const ate = (data.invoiceBoxTally.A || 0).toString();
      const totalQty = (data.TotalQty || 0).toString();
      doc
        .setFontSize(16)
        .setTextColor(settings.color.black)
        .text(regular, secondCol + 7.5, 99)
        .text(macho, secondCol + 27.5, 99)
        .text(junior, secondCol + 47.5, 99)
        .text(irregular, secondCol + 7.5, 114)
        .text(ate, secondCol + 27.5, 114)
        .text(totalQty, secondCol + 28, 129)
        .setFontSize(7)
        .setTextColor(settings.color.purple)
        .text('REGULAR', secondCol + 3, 103)
        .text('MACHO', secondCol + 24.5, 103)
        .text('JUNIOR', secondCol + 44, 103)
        .text('IRREGULAR', secondCol + 2, 118)
        .text('ATE', secondCol + 26.5, 118)
        .text('TOTAL NO. OF BOXES', secondCol + 16, 133);
    } else if (countryId.toLowerCase() === 'ca') {
      const doydoy = (data.invoiceBoxTally.D || 0).toString();
      const irregular = (data.invoiceBoxTally.IRR || 0).toString();
      const totalQty = (data.TotalQty || 0).toString();
      doc
        .setFontSize(16)
        .setTextColor(settings.color.black)
        .text(doydoy, secondCol + 7.5, 99)
        .text(irregular, secondCol + 27.5, 99)
        .text(totalQty, secondCol + 28, 129)

        .setFontSize(7)
        .setTextColor(settings.color.purple)
        .text('DOYDOY', secondCol + 4, 103)
        .text('IRREGULAR', secondCol + 22, 103)
        .text('TOTAL NO. OF BOXES', secondCol + 16, 133);
    } else if (countryId.toLowerCase() === 'uk') {
      const medium = (data.invoiceBoxTally.MD || 0).toString();
      const jumbo = (data.invoiceBoxTally.JB || 0).toString();
      //const irregular = (data.invoiceBoxTally.IRR || 0).toString();
      const superjumbo = (data.invoiceBoxTally.SJ || 0).toString();
      const odd = (data.invoiceBoxTally.ODD || 0).toString();
      const totalQty = (data.TotalQty || 0).toString();
      doc
        .setFontSize(16)
        .setTextColor(settings.color.black)
        .text(medium, secondCol + 7.5, 99)
        .text(jumbo, secondCol + 27.5, 99)
        //.text(irregular, secondCol + 47.5, 99)
        .text(superjumbo, secondCol + 47.5, 99)
        .text(odd, secondCol + 7.5, 114)
        .text(totalQty, secondCol + 28, 129)
        .setFontSize(7)
        .setTextColor(settings.color.purple)
        .text('MEDIUM', secondCol + 4, 103)
        .text('JUMBO', secondCol + 24.5, 103)
        //.text('IRREGULAR', secondCol + 42, 103)
        .text('SUPER JUMBO', thirdCol + 43, 103)
        .text('ODD', secondCol + 6, 118)
        .text('TOTAL NO. OF BOXES', secondCol + 16, 133);
    }

    // Content
    const content1 = ` By signing this form, the Sender warrants that he/she has read, understood and agrees to all terms of TERMS AND CONDITIONS OF SERVICE as stated herein and found in its website (www.atlasxpadala.com).`;
    const splitContent1 = doc.splitTextToSize(content1, 70);

    const content2 = `The Sender certify that the information provided is true and correct, that he/she is the owner of the shipment, that the declared contents and value of the items are accurate, and that it does not contain any regulated, prohibited, underdecared or illegal items pursuant to the customs law of the country of origin and of the Republic of the Philippines.`;
    const splitContent2 = doc.splitTextToSize(content2, 70);
    doc
      .setFontSize(7)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black)
      .text(splitContent1, settings.margin, 95);
    /* doc
      .setFontSize(5)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black)
      .text(' By signing this form, the', settings.margin, 95);
    doc
      .setFontSize(5)
      .setFont(DEFAULT_FONT, 'bold')
      .setTextColor(settings.color.black)
      .text('Sender', settings.margin + this.getTextWidth(doc, ' By signing this form, the', 6) - 1, 95);
    doc
      .setFontSize(5)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black)
      .text('warrants that he/she has read, understood', settings.margin + (this.getTextWidth(doc, ' By signing this form, the Sender', 6) - 1), 95);
 */
    doc
      .setFontSize(7)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black)
      .text(splitContent2, settings.margin, 110);

    if (data.CustomerSignature) {
      const customerSignature = new Image();
      customerSignature.src = `data:image/png;base64,${data.CustomerSignature}`;
      doc.addImage(customerSignature, 'png', 20, 125, 20, 20);
    }

    doc
      .setLineWidth(0.4)
      .setDrawColor(settings.color.black)
      .line(settings.margin, 135, 45, 135)
      .setFontSize(8)
      .text(
        customer,
        this.centerText(doc, customer, 8, settings.margin, 37),
        133
      )
      .text('Signature over printed name', settings.margin + 0.5, 139);

    const orderDate = this.datePipe.transform(
      data.ServiceTypeDate,
      'MMM d, yyyy'
    );
    doc
      .setLineWidth(0.4)
      .setDrawColor(settings.color.black)
      .line(settings.margin + 45, 135, 75, 135)
      .setFontSize(8)
      .text(
        orderDate ?? '',
        this.centerText(doc, orderDate ?? '', 8, 53, 22),
        133
      ) // settings.margin + 48
      .text('Date', settings.margin + 52.5, 139);

    doc
      .setFont(DEFAULT_FONT, 'bold')
      .setFontSize(10)
      .text('IMPORTANT NOTICE:', settings.margin, 148);

    const importantNotice = `The Sender warrants that he/she has read and understood LOGO's Data Privacy Policy and hereby consents to allow personal data to be collected/processed in manner that ensures appropriate privacy and security safeguards pursuants to the provisions of Republic Act. No. 10173 (Data Privacy Act of 2012) and its corresponding implementing Rules and Regulations.`;
    const splitImportantNotice = doc.splitTextToSize(importantNotice, 180);

    doc
      .setFont(DEFAULT_FONT, 'normal')
      .setFontSize(7)
      .text(splitImportantNotice, settings.margin, 153);

    const importantNotice2 = `The Sender shall be held liable in case the check's issued for the Shipment are returned to LOGO for the reason 'No Sufficient Fund (NSF).`;
    const splitImportantNotice2 = doc.splitTextToSize(importantNotice2, 135);
    doc
      .setFont(DEFAULT_FONT, 'normal')
      .setFontSize(7)
      .text(splitImportantNotice2, settings.margin, 168);

    doc
      .setLineWidth(0.4)
      .setDrawColor(settings.color.gray)
      .line(settings.margin, 175, pageWith - 73, 175);

    if (data.DriverSignature) {
      const driverSignature = new Image();
      driverSignature.src = `data:image/png;base64,${data.DriverSignature}`;
      doc.addImage(driverSignature, 'png', 45, 174, 20, 20);
    }

    let driver = `${
      data.ServiceTypeCode === 'PICKUP'
        ? data.DriverReceivedById
        : ucfirst(data.ReceivedByAgent)
    }`;
    driver =
      driver == null || driver == 'null' || driver == 'undefined' ? '' : driver;

    doc
      .setFont(DEFAULT_FONT, 'normal')
      .setFontSize(8)
      .text('Received By:', settings.margin, 184)
      .setLineWidth(0.3)
      .setDrawColor(settings.color.black)
      .line(23, 184, 80, 184)
      .text(driver || '', this.centerText(doc, driver, 8, 30, 50), 183)
      .text('Signature over printed name', 38, 188);

    const receivedDate =
      this.datePipe.transform(data.ServiceTypeDate, 'MMM d, yyyy') ?? '';
    doc
      .setFont(DEFAULT_FONT, 'normal')
      .setFontSize(8)
      .text('Received Date:', 85, 184)
      .setLineWidth(0.3)
      .setDrawColor(settings.color.black)
      .line(107, 184, 138, 184)
      .text(
        receivedDate,
        this.centerText(doc, receivedDate, 8, 107, 34.5),
        183
      );

    this.packingList(doc, data);
  }

  centerText(
    doc: any,
    text: string,
    fontSize: number,
    xStart: number,
    xLen: number
  ): number {
    const textUnitWith =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    return (xLen - textUnitWith) / 2 + xStart;
  }

  getTextWidth(doc, text, fontSize) {
    const textUnitWith =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    return textUnitWith;
  }

  senderDetails(doc: any, data: any, xPos: number) {
    const maxLen = 65;

    const customer = data.CustomerFullname
      ? data.CustomerFullname.toUpperCase()
      : '';
    const secondCustomer = data.SecondCustomerFullname
      ? data.SecondCustomerFullname.toUpperCase()
      : '';
    const billingAddress = ucfirst(
      data.BillingAddress +
        ' ' +
        (data.BillingAddress2 ? data.BillingAddress2 : '')
    );
    const billingCityName = data.BillingCityName
      ? ucfirst(data.BillingCityName)
      : '';
    const billingStateName = data.BillingStateName
      ? ucfirst(data.BillingStateName)
      : '';
    const billingZipcode = data.BillingZipcode ? data.BillingZipcode : '';
    const billingCountry = data.BillingCountry ? data.BillingCountry : '';
    const phoneNumber =
      data.PhoneNumber +
      `${data.AltPhoneNumber ? ` / ${data.AltPhoneNumber}` : ''}`;
    const emailAddress = data.EmailAddress ? data.EmailAddress : '';
    const senderID =
      data.SenderID +
      `${data.SecondarySenderID != null ? ` / ${data.SecondarySenderID}` : ''}`;

    doc
      .setFontSize(9)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black);

    const addressUnitWith =
      (doc.getStringUnitWidth(billingAddress) * 10) / doc.internal.scaleFactor;
    const splitAddress = doc.splitTextToSize(billingAddress, maxLen);
    const addressLines = Math.ceil(addressUnitWith / maxLen);
    let addLineHeight = 0;
    splitAddress.forEach((element, index) => {
      if (index != 0) {
        addLineHeight += 5;
      }
    });
    /*     if (addressLines > 1) {
          for (let index = 0; index < addressLines; index++) {
            addLineHeight = + 5;
          }
        } */
    let yPos = 42;
    doc.text(customer, settings.margin, 42);

    if (secondCustomer) {
      yPos += 5;
      doc.text(secondCustomer, settings.margin, yPos);
    }

    doc
      .text(splitAddress, settings.margin, yPos + 5)
      .text(
        `${billingCityName} ${billingStateName}`,
        settings.margin,
        yPos + 10 + addLineHeight
      )
      .text(
        `${billingZipcode} ${billingCountry}`,
        settings.margin,
        yPos + 15 + addLineHeight
      )
      .text(`${phoneNumber}`, settings.margin, yPos + 20 + addLineHeight)
      .text(`${emailAddress}`, settings.margin, yPos + 25 + addLineHeight);

    if (senderID != null && senderID != '') {
      const splitSenderID = doc.splitTextToSize(senderID, maxLen);

      doc.text(
        splitSenderID != 'null' ? `Sender ID: ${splitSenderID}` : '',
        settings.margin,
        yPos + 30 + addLineHeight
      );
    }
  }

  consigneeDetails(doc: any, data: any, xPos: number) {
    const maxLen = 65;
    const consigneeXPos = xPos - 10;

    const consignee =
      data.ConsigneeName.toUpperCase() +
      ' ' +
      data.ConsigneeLastName.toUpperCase();
    const consignee2ndName = data.ConsigneeSecondName
      ? data.ConsigneeSecondName.toUpperCase()
      : '';
    const brgy = data.phbrgy != null ? ucfirst(data.phbrgy.trim()) : '';
    const address = data.ConsigneeAddress
      ? ucfirst(data.ConsigneeAddress.trim() + ' ' + brgy)
      : '';
    const city = data.phcity ? ucfirst(data.phcity.trim()) : '';
    const state = data.phstate ? ucfirst(data.phstate.trim()) : '';
    const consigneeZipcode = data.ConsigneeZipcode
      ? data.ConsigneeZipcode.trim()
      : '';
    const consigneeProvince = data.ConsigneeProvince
      ? data.ConsigneeProvince.trim()
      : '';
    const consigneeTelNo =
      data.ConsigneeTelNo.trim() +
      `${data.ConsigneeTelNo1 ? ` / ${data.ConsigneeTelNo1}` : ''}` +
      `${data.ConsigneeTelNo2 ? ` / ${data.ConsigneeTelNo2}` : ''}`;
    const deliveryZoneCode = data.DeliveryZoneCode
      ? data.DeliveryZoneCode.trim()
      : '';
    const deliveryZoneName = data.DeliveryZoneName
      ? data.DeliveryZoneName.trim()
      : '';

    doc
      .setFontSize(9)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black);

    const addressUnitWith =
      (doc.getStringUnitWidth(address) * 10) / doc.internal.scaleFactor;
    const splitAddress = doc.splitTextToSize(address, maxLen);
    const addressLines = Math.ceil(addressUnitWith / 70);

    let addLineHeight = 0;
    /* if (addressLines > 1) {
      for (let index = 0; index < addressLines; index++) {
        addLineHeight = + 5;
      }
    } */
    splitAddress.forEach((element, index) => {
      if (index != 0) {
        addLineHeight += 5;
      }
    });

    const cityState = `${city} ${state}`;
    const cityStateUnitWith =
      (doc.getStringUnitWidth(cityState) * 10) / doc.internal.scaleFactor;
    const splitCityState = doc.splitTextToSize(cityState, maxLen);
    const cityStateLines = Math.ceil(cityStateUnitWith / 70);
    let cityStateAddLineHeight = 0;
    splitCityState.forEach((element, index) => {
      if (index != 0) {
        cityStateAddLineHeight += 5;
      }
    });

    doc.text(consignee, consigneeXPos, 42);
    let consigneeAddLineHeight = 0;
    if (consignee2ndName) {
      const split2ndConsignee = doc.splitTextToSize(consignee2ndName, 55);
      split2ndConsignee.forEach((name) => {
        consigneeAddLineHeight += 5;
        doc.text(name, consigneeXPos, 42 + consigneeAddLineHeight);
      });
    }
    doc.text(splitAddress, consigneeXPos, 47 + consigneeAddLineHeight);
    doc.text(
      splitCityState,
      consigneeXPos,
      52 + addLineHeight + consigneeAddLineHeight
    );
    doc.text(
      `${consigneeZipcode} ${consigneeProvince}`,
      consigneeXPos,
      57 + addLineHeight + cityStateAddLineHeight + consigneeAddLineHeight
    );
    doc.text(
      `${consigneeTelNo}`,
      consigneeXPos,
      62 + addLineHeight + cityStateAddLineHeight + consigneeAddLineHeight
    );
    doc.text(
      `${deliveryZoneCode} - ${deliveryZoneName}`,
      consigneeXPos,
      67 + addLineHeight + cityStateAddLineHeight + consigneeAddLineHeight
    );
  }

  paymentMethod(doc: jsPDF, data: any, xPos: number) {
    let lastYPos = 0;

    // tslint:disable-next-line: max-line-length
    if (
      data.TotalPaymentCash > 0 ||
      data.TotalPaymentCheck > 0 ||
      data.TotalPaymentCreditCard > 0 ||
      data.TotalPaymentAccountsReceivable > 0 ||
      data.TotalPaymentPaidAtBranch > 0
    ) {
      doc
        .setFontSize(12)
        .setFont(DEFAULT_FONT, 'bold')
        .setTextColor(settings.color.purple)
        .text('Payment Method', xPos, 71);

      doc.setFontSize(10);

      const paymentMethods: PaymentMethod[] = [];
      if (data.TotalPaymentCash > 0) {
        paymentMethods.push({
          paymentType: 'Cash',
          isChecked: data.HasCash,
        });
      }
      if (data.TotalPaymentCheck) {
        paymentMethods.push({
          paymentType: 'Check',
          isChecked: data.HasCheck,
        });
      }
      if (data.TotalPaymentCreditCard) {
        paymentMethods.push({
          paymentType: data.SubType || 'Talech Payment',
          isChecked: data.HasCreditCard,
        });
      }
      if (data.TotalPaymentAccountsReceivable) {
        paymentMethods.push({
          paymentType: 'Accounts Receivable',
          isChecked: data.HasAccountsReceivable,
        });
      }
      if (data.TotalPaymentPaidAtBranch) {
        paymentMethods.push({
          paymentType: 'Paid at Branch',
          isChecked: data.invoicePaymentCheck,
        });
      }

      const maxColWidth = 60;
      let rowCount = 1;
      let itemCount = 0;
      let currColWidth = 70;
      const xPoss = [xPos];
      const checkMarkSize = 3;
      const boxPadding = 4;
      let yPos = 78.5;

      const patterns = [];
      paymentMethods.forEach((item: PaymentMethod) => {
        const paymentTypeUnitWith =
          (doc.getStringUnitWidth(item.paymentType) * 10) /
          doc.internal.scaleFactor;
        let innerWidth = 0;
        let boxWidth = 0;
        let boxHeight = 0;

        if (item.isChecked) {
          innerWidth = checkMarkSize + 1 + paymentTypeUnitWith;
          boxWidth = innerWidth + boxPadding;
          boxHeight = 8;
        } else {
          innerWidth = paymentTypeUnitWith + 2;
          boxWidth = innerWidth;
          boxHeight = 8;
        }

        const lastXPos = xPoss[xPoss.length - 1];
        let currXPos = lastXPos + boxWidth + 1;

        if (currXPos - xPos < maxColWidth) {
          currColWidth -= boxWidth;
          itemCount += 1;
        } else {
          currColWidth = 70;
          rowCount += 1;
          itemCount += 1;
          xPoss.splice(-1, 1);
          currXPos = xPos;
          yPos += 9;
        }

        xPoss.push(currXPos);

        patterns.push({
          paymentType: item.paymentType,
          isChecked: item.isChecked,
          boxWidth,
          itemCount,
          rowCount,
          yPos,
        });
      });

      patterns.forEach((item, i) => {
        doc
          .setFillColor(settings.color['purple-light'])
          .rect(xPoss[i], item.yPos - 5, item.boxWidth, 8, 'F')
          .setFontSize(10);

        if (item.isChecked) {
          const checkImage = new Image();
          checkImage.src = '/assets/img/check.png';
          doc
            .text(item.paymentType, xPoss[i] + 6, item.yPos)
            .addImage(checkImage, xPoss[i] + 2, item.yPos - 2.5, 3, 3);
        } else {
          doc.text(item.paymentType, xPoss[i] + 1, item.yPos);
        }

        lastYPos = item.yPos;
      });
    }

    if (data.HasCheck || data.HasCreditCard) {
      if (data.HasCheck) {
        doc
          .setFontSize(10)
          .setFont(DEFAULT_FONT, 'bold')
          .setTextColor(settings.color.black)
          .text('Check Number:', xPos, lastYPos + 8)
          .setFont(DEFAULT_FONT, 'normal')
          .text(
            data.invoicePaymentCheck,
            xPos + this.getTextWidth(doc, 'Check Number:', 11),
            lastYPos + 8
          );
      } else if (data.HasCreditCard) {
        doc
          .setFontSize(10)
          .setFont(DEFAULT_FONT, 'bold')
          .setTextColor(settings.color.black)
          .text('Credit Card Number:', xPos, lastYPos + 8)
          .setFont(DEFAULT_FONT, 'normal')
          .text(
            data.invoicePaymentCreditCard,
            xPos + this.getTextWidth(doc, 'Credit Card Number:', 11),
            lastYPos + 8
          );

        if (data.AuthCode) {
          doc
            .setFontSize(10)
            .setFont(DEFAULT_FONT, 'bold')
            .setTextColor(settings.color.black)
            .text('Auth ID:', xPos, lastYPos + 12)
            .setFont(DEFAULT_FONT, 'normal')
            .text(
              data.AuthCode,
              xPos + this.getTextWidth(doc, data.AuthCode, 11),
              lastYPos + 12
            );
        }
      }
    }

    this.resetFont(doc);
  }

  preloadDefaultData(data: any) {
    let temp_boxes: any[] = [];
    let temp_tally_boxes: any = {};

    data.AllBoxesTotalPrice = 0;

    data.invoiceItemDetailList.forEach((invoiceItemDetails: any) => {
      let is_exist = false;
      let current_index: any = null;

      temp_boxes.forEach((temp_item: any, temp_index: any) => {
        if (temp_item.BoxSizeCode == invoiceItemDetails.BoxSizeCode) {
          current_index = temp_index;
          is_exist = true;
        }
      });

      if (!is_exist) {
        temp_boxes.push({
          GroupQty: invoiceItemDetails.Qty,
          ...invoiceItemDetails,
        });
        temp_tally_boxes[invoiceItemDetails.BoxSizeCode] =
          invoiceItemDetails.Qty;
      } else {
        temp_boxes[current_index].GroupQty += 1;
        temp_tally_boxes[invoiceItemDetails.BoxSizeCode] += 1;
      }

      data.HasCash = false;
      data.HasCheck = false;
      data.HasCreditCard = false;
      data.HasAccountsReceivable = false;
      data.HasPaidAtBranch = false;
      data.AuthCode = '';

      data.TotalPaymentCash = 0;
      data.TotalPaymentCheck = 0;
      data.TotalPaymentCreditCard = 0;
      data.TotalPaymentAccountsReceivable = 0;
      data.TotalPaymentPaidAtBranch = 0;

      data.invoicePaymentDetailList.forEach((item: any) => {
        if (item.PaymentType.trim() == 'CASH') {
          data.HasCash = true;
          data.TotalPaymentCash += item.PaymentAmount;
        }

        if (item.PaymentType.trim() == 'CHECK') {
          data.HasCheck = true;
          data.TotalPaymentCheck += item.PaymentAmount;
          data.invoicePaymentCheck = item.CheckNo;
        }

        if (item.PaymentType.trim() == 'CC') {
          data.HasCreditCard = true;
          data.TotalPaymentCreditCard += item.PaymentAmount;
          data.invoicePaymentCreditCard = item.CreditCardNumber.substr(-4);
          data.AuthCode = item.AuthCode;
          data.SubType = item.SubType;
        }

        if (item.PaymentType.trim() == 'AR') {
          data.HasAccountsReceivable = true;
          data.TotalPaymentAccountsReceivable += item.PaymentAmount;
        }

        if (item.PaymentType.trim() == 'PAB') {
          data.HasPaidAtBranch = true;
          data.TotalPaymentPaidAtBranch += item.PaymentAmount;
        }
      });

      data.groupedInvoiceItemDetailList = temp_boxes;
      data.invoiceBoxTally = temp_tally_boxes;
      data.AllBoxesTotalPrice +=
        invoiceItemDetails.Qty * invoiceItemDetails.UnitPrice;
    });

    return data;
  }

  packingList(doc: jsPDF, data: any) {
    const remarks = doc.splitTextToSize(
      '(Sender attests that contents are true, correct and accurate and does not contain banned, restricted, and highly regulated items.)',
      doc.internal.pageSize.getWidth() / 2 - 5
    );

    if (data.Comments1) {
      const comment = doc.splitTextToSize(
        data.Comments1,
        doc.internal.pageSize.getWidth() / 2 - 5
      );

      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'bold')
        .setTextColor(settings.color.purple)
        .text('Notes:', doc.internal.pageSize.getWidth() / 2, 200)
        .setFont(DEFAULT_FONT, 'normal')
        .setTextColor(settings.color.black)
        .text(comment, doc.internal.pageSize.getWidth() / 2, 204);
    }

    doc
      .setFontSize(12)
      .setFont(DEFAULT_FONT, 'bold')
      .setTextColor(settings.color.purple)
      .text('Packing List', settings.margin, 200)
      .setFontSize(8)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black)
      .text(remarks, settings.margin, 204);

    const nextYPos = { boxZone: 207.5, table: 211.5 };

    const items = data.invoiceItemDetailList;
    console.log(items);
    let tableDatas = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const tableData: TableData = {
        boxSizeCode: '',
        zoneCode: '',
        body: [],
        foot: [],
      };

      tableData.boxSizeCode = item.BoxSizeCode || '';
      tableData.zoneCode = item.ZoneCode || '';

      if (item.invoicePackingList.length > 0) {
        item.invoicePackingList.forEach((invoicePack: any) => {
          tableData.body.push([
            ucfirst(invoicePack.GoodsDescription),
            invoicePack.Quantity,
            this.decimalPipe.transform(
              invoicePack.ActualEstimatedValue,
              '1.2-2'
            ),
          ]);
        });

        /* if (i < data.invoiceItemDetailList.length - 1 && i % 2 === 0) {
          this.countNextBoxItemCount(item.invoicePackingList, data.invoiceItemDetailList[i + 1].invoicePackingList).forEach(() => tableData.body.push(['', '', '']));
        }

        if (i > 0 && i % 2 === 1) {
          this.countPrevBoxItemCount(data.invoiceItemDetailList[i - 1].invoicePackingList, item.invoicePackingList).forEach(() => tableData.body.push(['', '', '']));
        } */

        tableData.foot.push([
          'Box Total Value',
          '',
          this.decimalPipe.transform(item.TotalEstimatedValue, '1.2-2'),
        ]);

        tableDatas.push(tableData);
      }
    }

    let yPosGuide: any = [];
    let newArr: any[] = [];
    let prevYpos = nextYPos.table;

    const xPosGuide = [];

    _.chunk(tableDatas, 3).map((item, i) => {
      xPosGuide.push(8);
      xPosGuide.push(70);
      xPosGuide.push(132);

      const highestRowHeight = Math.max.apply(
        null,
        item.map((item) => item.body.length)
      );

      yPosGuide.push(prevYpos);

      const arr = item.map((q) => {
        return {
          ...q,
          yPos: yPosGuide[i],
        };
      });
      newArr = newArr.concat(arr);

      prevYpos += (highestRowHeight + 2) * 5;
    });

    let currPage = doc.getCurrentPageInfo().pageNumber;

    let yStartPos = [211.5, 211.5, 211.5];
    newArr.forEach((item: any, i) => {
      let text = `${item.boxSizeCode} (${item.zoneCode})`;

      if (yStartPos[i] < 211.5) {
        doc.setPage(doc.getNumberOfPages());
      } else {
        doc.setPage(currPage);
      }

      doc
        .setFontSize(8)
        .setFont(DEFAULT_FONT, 'bold')
        .setTextColor(settings.color.black)
        .text(text, xPosGuide[i] || 8, yStartPos[i]);

      autoTable(doc, {
        /*
        pageBreak: "avoid", */
        margin: {
          left: xPosGuide[i] || 8,
          right: 8,
          bottom: 0,
        },
        styles: {
          fontSize: 6,
          cellPadding: 0.4,
        },
        tableWidth: 60,
        startY: yStartPos[i] + 2,
        headStyles: {
          fillColor: settings.color.green,
        },
        footStyles: {
          fillColor: '#DDDDDD',
          textColor: settings.color.black,
        },
        head: [['Item', 'Qty', 'Price']],
        body: item.body,
        showFoot: 'lastPage',
        showHead: 'firstPage',
        foot: item.foot,
        didDrawPage: (d) => {
          if (d.pageCount > 1) {
            yStartPos.pop();
          }
          yStartPos.push(d.cursor.y + 5);
        },
      });
    });
  }

  countNextBoxItemCount(leftItem: any, rightItem: any) {
    let count = 0;
    if (leftItem.length < rightItem.length) {
      count = rightItem.length - leftItem.length;
    }
    return Array(count)
      .fill(0)
      .map((v, i) => i);
  }

  countPrevBoxItemCount(leftItem: any, rightItem: any) {
    let count = 0;
    if (leftItem.length > rightItem.length) {
      count = leftItem.length - rightItem.length;
    }
    return Array(count)
      .fill(0)
      .map((v, i) => i);
  }

  resetFont(doc: jsPDF) {
    doc
      .setFontSize(10)
      .setFont(DEFAULT_FONT, 'normal')
      .setTextColor(settings.color.black);
  }
}
