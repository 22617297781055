import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  form: FormGroup;
  isSubmitted: boolean = false;
  isSucceeded: boolean = false;

  telephone: string = environment.contact.telephone_us;
  telephone_canada: string = environment.contact.telephone_canada;
  telephone_uk: string = environment.contact.telephone_uk;
  mobileSmart: string = environment.contact.mobile_smart;
  mobileSmart2: string = environment.contact.mobile_smart2;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Contact Us | LOGO');
    this.meta.updateTag({ name: 'description', content: 'LOGO - Contact Us' });

    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [
        Validators.required, Validators.email
      ]],
      message: ['', [
        Validators.required, Validators.minLength(10)
      ]]
    });
  }

  async onSubmit() {
    if (this.form.invalid) {
      this.toastr.warning('Please complete required fields.');
      return;
    }

    try {
      this.isSubmitted = true;
      this.spinner.show();
      await this.dataService.sendContactUs(this.form.value).toPromise();
      this.spinner.hide();
      this.form.reset();
      window.scrollTo(0, 0);
      this.isSucceeded = true;
    } catch (error) {
      this.toastr.error('Someting went wrong sending inquiry. Please try again.', 'Oops!');
      console.log(error.error);
      this.spinner.hide();
    }
  }

  get formControl() {
    return this.form.controls;
  }
}
