import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import { FremanService } from 'src/app/services/freman.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-freman-online-payment',
  templateUrl: './freman-online-payment.component.html',
  styleUrls: ['./freman-online-payment.component.scss']
})
export class FremanOnlinePaymentComponent implements OnInit {
  invoice: any = {};
  billingStateList: any[] = [];
  deliveryZoneList: any[] = [];
  branchList: any[] = [];

  isLinkExpired = false;
  isLoaded = false;
  currency = environment.currency;

  constructor(private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private fremanService: FremanService,
    private router: Router) { }

  ngOnInit() {
    this.loadSelections();
    this.loadInvoice();
  }

  loadSelections() {
    this.fremanService.getInvoiceDetails().subscribe(res => {
      this.billingStateList = res?.StateList;
      this.deliveryZoneList = res?.DeliveryZoneList;
    });
  }

  loadInvoice() {
    var id = this.activatedRoute.snapshot.params['id'];
    this.spinner.show();
    this.dataService.getEncryptedInvoice(id).subscribe(res => {
      this.isLinkExpired = res.expiredLink;
      this.invoice = res.invoice;
      this.loadBranches(this.invoice['BillingCountry']);
      this.billingStateList = this.billingStateList.filter(x=>x.CountryID == this.invoice['BillingCountry']);
      this.isLoaded = true;
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
    });
  }

  loadBranches(countryId) {
    this.fremanService
      .getBranchesByCountryId(countryId).subscribe(res => {
        this.branchList = res;
      })
  }

  onPaymentBack() {
    this.router.navigateByUrl('/');
  }
}
