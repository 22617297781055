import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FeeCalculatorService {

    constructor() { }

    calculateMerchantFee(amount, card, paymentType, country) {
        var processFeeObj = card['ProcessFee'];
        var countryFee = processFeeObj[country];
        var feePercentage = countryFee[paymentType];

        if (paymentType === 'DC') {
            return feePercentage;
        } else {
            return amount * feePercentage;
        }
    }
}