import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../models';
import { AuthService, TOKEN_KEY } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  whitelist: string[] = [
    '/assets/data/branches.json'
  ];

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private dialogRef: MatDialog,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(TOKEN_KEY);
    const user: any = this.authService.getUser();

    if (!(this.whitelist.indexOf(request.url) >= 0)
      && token
      && user != null
    ) {
      request = this.addToken(request, token);
    }

    return next.handle(request)
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // Swal.fire('Unauthorized', 'You have no access from this account', 'error');
            console.log('Unauthorized');
            this.authService.logout();
            this.dialogRef.closeAll();
            this.spinner.hide();
          }

          return throwError(error);
        })
      );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    const user: User = this.authService.getUser();

    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'UserId': user.id.toString(),
        'TimeZone': user.timeZone
      }
    });
  }
}
