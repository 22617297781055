export function ucfirst(text) {
    let newText = '';

    if (text) {
        text.split(' ').forEach((word: any, index: any) => {
            if (index > 0) {
                newText += ' ';
            }

            newText += word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
        });
    }

    return newText;
}