<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  color="#fff"
  type="square-jelly-box"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>

<div class="sigma_subheader style-6 bg-cover bg-center title-banner">
  <div class="container">
    <div class="sigma_subheader-inner">
      <h1>Job Application</h1>
    </div>
  </div>
</div>

<div class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-12 contents">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="d-flex flex-column align-items-center">
              <div class="display-4">Job Application Form</div>
              <hr style="margin: 2rem 0 3rem 0; width: 25%;">
            </div>
            <form [formGroup]="applicationForm" (submit)="submitApplicationForm()">

              <!-- Section 1: General Information -->
              <div class="section-header">
                <div class="section-number">1</div>
                <div class="section-header-title">General Information</div>
              </div>
              <div class="px-4 px-sm-5 mb-4">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Apply at (Location):</label>
                      <input type="text" formControlName="Location" class="form-control rounded-0" placeholder="Enter Location">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>First Name: <strong class="text-danger">*</strong></label>
                          <input type="text" formControlName="FirstName" class="form-control rounded-0" placeholder="Enter First Name">
                          <div [innerHTML]="displayError(applicationForm, 'FirstName')"></div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>Middle Name:</label>
                          <input type="text" formControlName="MiddleName" class="form-control rounded-0" placeholder="Enter Middle Name">
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>Last Name: <strong class="text-danger">*</strong></label>
                          <input type="text" formControlName="LastName" class="form-control rounded-0" placeholder="Enter Last Name">
                          <div [innerHTML]="displayError(applicationForm, 'LastName')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label>Suffix:</label>
                      <select formControlName="Suffix">
                        <option selected value="">Select a Job Title</option>
                        <option value="JR.">JR.</option>
                        <option value="III">III</option>
                        <option value="IV">IV</option>
                        <option value="V">V</option>
                        <option value="VI">VI</option>
                        <option value="VII">VII</option>
                        <option value="VIII">VIII</option>
                        <option value="IX">IX</option>
                        <option value="X">X</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-8">
                    <div class="form-group">
                      <label>Street Address: <strong class="text-danger">*</strong></label>
                      <input type="text" formControlName="AddressLine1" class="form-control rounded-0" placeholder="Enter Street Address">
                      <div [innerHTML]="displayError(applicationForm, 'AddressLine1')"></div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Country: <strong class="text-danger">*</strong></label>
                      <select formControlName="CountryId" (change)="onCountryChange($event.target.value)">
                        <option selected value="">Select a Country</option>
                        <option
                          *ngFor="let item of countries"
                          [value]="item.CountryId"
                        >
                          {{ item.CountryName }}
                        </option>
                      </select>
                      <div [innerHTML]="displayError(applicationForm, 'CountryId')"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>State: <strong class="text-danger">*</strong></label>
                      <input
                        type="text"
                        placeholder="Search for State"
                        formControlName="_StateProvCode"
                        [matAutocomplete]="stateInput"
                        autocomplete="disabled"
                      />
                      <mat-autocomplete
                        #stateInput="matAutocomplete"
                        [displayWith]="displayState"
                        (optionSelected)="onStateChange($event.option.value)"
                      >
                        <mat-option value="">Select a State</mat-option>
                        <mat-option
                          *ngFor="let item of filteredStates$ | async"
                          [value]="item"
                        >
                          {{ item.StateProvName }}
                        </mat-option>
                      </mat-autocomplete>
                      <div [innerHTML]="displayError(applicationForm, 'StateProvCode')"></div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>City: <strong class="text-danger">*</strong></label>
                      <input
                        type="text"
                        placeholder="Search for City"
                        formControlName="_CityCode"
                        [matAutocomplete]="cityInput"
                        autocomplete="disabled"
                      />
                      <mat-autocomplete
                        #cityInput="matAutocomplete"
                        [displayWith]="displayCity"
                        (optionSelected)="onCityChange($event.option.value)"
                      >
                        <mat-option value="">Select a City</mat-option>
                        <mat-option
                          *ngFor="let item of filteredCities$ | async"
                          [value]="item"
                        >
                          {{ item.CityName }}
                        </mat-option>
                      </mat-autocomplete>
                      <div [innerHTML]="displayError(applicationForm, 'CityCode')"></div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Zip Code: <strong class="text-danger">*</strong></label>
                      <input type="text" formControlName="ZipCode" class="form-control rounded-0" placeholder="Enter Zip Code">
                      <div [innerHTML]="displayError(applicationForm, 'ZipCode')"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Position Applying For: <strong class="text-danger">*</strong></label>
                      <select formControlName="JobPosition">
                        <option selected value="">Select a Job Title</option>
                        <option value="Administrative Support">Administrative Support</option>
                        <option value="Driver">Driver</option>
                        <option value="Warehouse Personnel">Warehouse Personnel</option>
                      </select>
                      <div [innerHTML]="displayError(applicationForm, 'JobPosition')"></div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Minimum Salary Desired: <strong class="text-danger">*</strong></label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">{{ selectedCountryCurrency }}</span>
                        </div>
                        <input type="number" formControlName="MinimumDesiredSalary" class="form-control rounded-0">
                      </div>
                      <div [innerHTML]="displayError(applicationForm, 'MinimumDesiredSalary')"></div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Date Availability for Work: <strong class="text-danger">*</strong></label>
                      <input type="date" formControlName="DateAvailableForWork" class="form-control rounded-0">
                      <div [innerHTML]="displayError(applicationForm, 'DateAvailableForWork')"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Date of Birth: <strong class="text-danger">*</strong></label>
                      <input type="date" formControlName="BirthDate" class="form-control rounded-0">
                      <div [innerHTML]="displayError(applicationForm, 'BirthDate')"></div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Telephone Number (Personal): <strong class="text-danger">*</strong></label>
                      <input type="text" formControlName="PersonalContactNumber" class="form-control rounded-0" placeholder="(___) ___-____" [value]="applicationForm.get('PersonalContactNumber').value | phoneFormat: 'US'">
                      <div [innerHTML]="displayError(applicationForm, 'PersonalContactNumber')"></div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Telephone Number (Work):</label>
                      <input type="text" formControlName="WorkContactNumber" class="form-control rounded-0" placeholder="(___) ___-____" [value]="applicationForm.get('WorkContactNumber').value | phoneFormat: 'US'">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-8">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>Have you previously worked for or applied for a position with LOGO, in any of our locations either as an employee or through an employment agency? <strong class="text-danger">*</strong></label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="has-work-or-applied-yes" class="form-check-input" type="radio" formControlName="HasWorkOrApplied" [value]="true">
                              <label class="form-check-label" for="has-work-or-applied-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="has-work-or-applied-no" class="form-check-input" type="radio" formControlName="HasWorkOrApplied" [value]="false">
                              <label class="form-check-label" for="has-work-or-applied-no">No</label>
                            </div>
                          </div>
                          <div [innerHTML]="displayError(applicationForm, 'HasWorkOrApplied')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>If yes, please explain when and, if employed, in what capacity: <strong class="text-danger" *ngIf="applicationForm.get('HasWorkOrApplied').value">*</strong></label>
                      <input type="text" formControlName="ExplainWorkOrApplied" class="form-control rounded-0">
                      <div [innerHTML]="displayError(applicationForm, 'ExplainWorkOrApplied')"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-8">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>Are you related to or in a close personal relationship with anyone now employed at LOGO? (An answer of "Yes" will not automatically disqualify you from the position for which you are applying.) <strong class="text-danger">*</strong></label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="is-related-to-axp-employee-yes" class="form-check-input" type="radio" formControlName="IsRelatedToAxpEmployee" [value]="true">
                              <label class="form-check-label" for="is-related-to-axp-employee-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="is-related-to-axp-employee-no" class="form-check-input" type="radio" formControlName="IsRelatedToAxpEmployee" [value]="false">
                              <label class="form-check-label" for="is-related-to-axp-employee-no">No</label>
                            </div>
                          </div>
                          <div [innerHTML]="displayError(applicationForm, 'IsRelatedToAxpEmployee')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>If yes, state name(s) and where they are located. <strong class="text-danger" *ngIf="applicationForm.get('IsRelatedToAxpEmployee').value">*</strong></label>
                      <input type="text" formControlName="RelatedAxpEmployee" class="form-control rounded-0">
                      <div [innerHTML]="displayError(applicationForm, 'RelatedAxpEmployee')"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>Are you available to work overtime as needed? <strong class="text-danger">*</strong></label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="can-work-overtime-yes" class="form-check-input" type="radio" formControlName="CanWorkOvertime" [value]="true">
                              <label class="form-check-label" for="can-work-overtime-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="can-work-overtime-no" class="form-check-input" type="radio" formControlName="CanWorkOvertime" [value]="false">
                              <label class="form-check-label" for="can-work-overtime-no">No</label>
                            </div>
                          </div>
                          <div [innerHTML]="displayError(applicationForm, 'CanWorkOvertime')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>If yes, are you available weekdays? <strong class="text-danger" *ngIf="applicationForm.get('CanWorkOvertime').value">*</strong></label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="can-overtime-weekdays-yes" class="form-check-input" type="radio" formControlName="CanOvertimeOnWeekdays" [value]="true">
                              <label class="form-check-label" for="can-overtime-weekdays-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="can-overtime-weekdays-no" class="form-check-input" type="radio" formControlName="CanOvertimeOnWeekdays" [value]="false">
                              <label class="form-check-label" for="can-overtime-weekdays-no">No</label>
                            </div>
                          </div>
                          <div [innerHTML]="displayError(applicationForm, 'CanOvertimeOnWeekdays')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>If yes, are you available weekends? <strong class="text-danger" *ngIf="applicationForm.get('CanWorkOvertime').value">*</strong></label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="can-overtime-weekends-yes" class="form-check-input" type="radio" formControlName="CanOvertimeOnWeekends" [value]="true">
                              <label class="form-check-label" for="can-overtime-weekends-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="can-overtime-weekends-no" class="form-check-input" type="radio" formControlName="CanOvertimeOnWeekends" [value]="false">
                              <label class="form-check-label" for="can-overtime-weekends-no">No</label>
                            </div>
                          </div>
                          <div [innerHTML]="displayError(applicationForm, 'CanOvertimeOnWeekends')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Section 2: Permission to Work -->
              <div class="section-header">
                <div class="section-number">2</div>
                <div class="section-header-title">Permission to Work</div>
              </div>
              <div class="px-4 px-sm-5 mb-4">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>Are you legally authorized to work in the United States? <strong class="text-danger">*</strong></label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="is-authorized-to-work-yes" class="form-check-input" type="radio" formControlName="IsAuthorizedToWork" [value]="true">
                              <label class="form-check-label" for="is-authorized-to-work-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="is-authorized-to-work-no" class="form-check-input" type="radio" formControlName="IsAuthorizedToWork" [value]="false">
                              <label class="form-check-label" for="is-authorized-to-work-no">No</label>
                            </div>
                          </div>
                          <div [innerHTML]="displayError(applicationForm, 'IsAuthorizedToWork')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>Will you now or in the future require sponsorship for employment visa status (e.g. H-1B status)? <strong class="text-danger">*</strong></label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="is-needing-visa-sponsorship-yes" class="form-check-input" type="radio" formControlName="IsNeedingVisaSponsorship" [value]="true">
                              <label class="form-check-label" for="is-needing-visa-sponsorship-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="is-needing-visa-sponsorship-no" class="form-check-input" type="radio" formControlName="IsNeedingVisaSponsorship" [value]="false">
                              <label class="form-check-label" for="is-needing-visa-sponsorship-no">No</label>
                            </div>
                          </div>
                          <div [innerHTML]="displayError(applicationForm, 'IsNeedingVisaSponsorship')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Section 3: Referral Information -->
              <div class="section-header">
                <div class="section-number">3</div>
                <div class="section-header-title">Referral Information</div>
              </div>
              <div class="px-4 px-sm-5 mb-4">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>How did you learn about LOGO? <strong class="text-danger">*</strong></label>
                          <div class="row">
                            <div class="col-sm-8">
                              <div class="row align-items-center">
                                <div class="col-sm-4">
                                  <div class="form-check form-check-inline">
                                    <input id="referral-type-1" class="form-check-input" type="radio" formControlName="ReferralType" value="Employment Agency">
                                    <label class="form-check-label" for="referral-type-1">Employment Agency</label>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-check form-check-inline">
                                    <input id="referral-type-2" class="form-check-input" type="radio" formControlName="ReferralType" value="putation of Firm">
                                    <label class="form-check-label" for="referral-type-2">Reputation of Firm</label>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-check form-check-inline">
                                    <input id="referral-type-3" class="form-check-input" type="radio" formControlName="ReferralType" value="Referral">
                                    <label class="form-check-label" for="referral-type-3">Referral</label>
                                  </div>
                                </div>
                              </div>
                              <div class="row align-items-center">
                                <div class="col-sm-4">
                                  <div class="form-check form-check-inline">
                                    <input id="referral-type-4" class="form-check-input" type="radio" formControlName="ReferralType" value="School">
                                    <label class="form-check-label" for="referral-type-4">School</label>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-check form-check-inline">
                                    <input id="referral-type-5" class="form-check-input" type="radio" formControlName="ReferralType" value="Newspaper Advertisement">
                                    <label class="form-check-label" for="referral-type-5">Newspaper Advertisement</label>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-check form-check-inline">
                                    <input id="referral-type-6" class="form-check-input" type="radio" formControlName="ReferralType" value="Other">
                                    <label class="form-check-label" for="referral-type-6">Other</label>
                                  </div>
                                </div>
                              </div>
                              <div [innerHTML]="displayError(applicationForm, 'ReferralType')"></div>
                            </div>
                            <div class="col-sm-4">
                              <input type="text" formControlName="ReferralDetails" class="form-control rounded-0" placeholder="Provide Referral Details">
                              <div [innerHTML]="displayError(applicationForm, 'ReferralDetails')"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Section 4: Work Experience -->
              <div class="section-header">
                <div class="section-number">4</div>
                <div class="section-header-title">Work Experience</div>
              </div>
              <div class="px-4 px-sm-5 mb-4">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>
                            <strong>All employers including your current employer may be contacted to verify the information you provide.</strong>
                            <br>
                            <span>May we contact your current employer prior to any offer of employment? <strong class="text-danger">*</strong></span>
                          </label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="can-contact-current-employer-yes" class="form-check-input" type="radio" formControlName="CanContactCurrentEmployer" [value]="true">
                              <label class="form-check-label" for="can-contact-current-employer-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="can-contact-current-employer-no" class="form-check-input" type="radio" formControlName="CanContactCurrentEmployer" [value]="false">
                              <label class="form-check-label" for="can-contact-current-employer-no">No</label>
                            </div>
                          </div>
                          <div [innerHTML]="displayError(applicationForm, 'CanContactCurrentEmployer')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card mb-4" *ngFor="let item of applicationForm.get('WorkExperiences')['controls']; let i = index">
                      <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                          <h2 class="m-0">{{ item.get('CompanyName').value || '' }}</h2>
                          <mat-icon style="color: red; cursor: pointer;" (click)="removeWorkExperience(i)">delete</mat-icon>
                        </div>
                      </div>
                      <div class="card-body" [formGroup]="item">
                        <div class="row">
                          <div class="col-sm-7">
                            <div class="form-group">
                              <label>Company Name: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="CompanyName" class="form-control rounded-0" placeholder="Enter Company Name">
                              <div [innerHTML]="displayError(item, 'CompanyName')"></div>
                            </div>
                            <div class="form-group">
                              <label>Address: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="CompanyAddress" class="form-control rounded-0" placeholder="Enter Company Address">
                              <div [innerHTML]="displayError(item, 'CompanyAddress')"></div>
                            </div>
                            <div class="form-group">
                              <label>Job Title and Work Responsibilities: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="JobPosition" class="form-control rounded-0" placeholder="Job Title">
                              <div [innerHTML]="displayError(item, 'JobPosition')"></div>
                            </div>
                            <div class="form-group">
                              <textarea formControlName="WorkResponsibilities" class="rounded-0" placeholder="Work Responsibilities"></textarea>
                              <div [innerHTML]="displayError(item, 'WorkResponsibilities')"></div>
                            </div>
                          </div>
                          <div class="col-sm-5">
                            <div class="form-group">
                              <label>Telephone Number: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="CompanyContactNumber" class="form-control rounded-0" placeholder="(___) ___-____" [value]="item.get('CompanyContactNumber').value != null ? (item.get('CompanyContactNumber').value | phoneFormat: 'US') : ''">
                              <div [innerHTML]="displayError(item, 'CompanyContactNumber')"></div>
                            </div>
                            <div class="form-group">
                              <label>Employed From: <strong class="text-danger">*</strong></label>
                              <div class="input-group">
                                <select formControlName="StartingEmploymentMonth">
                                  <option selected value="">Select a Month</option>
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                                <input type="number" formControlName="StartingEmploymentYear" class="form-control rounded-0" min="1900" [max]="thisYear">
                              </div>
                              <div [innerHTML]="displayMergedError(item, 'StartingEmploymentMonth', 'StartingEmploymentYear')"></div>
                            </div>
                            <div class="form-group">
                              <label>Employed To: <strong class="text-danger">*</strong></label>
                              <div class="input-group">
                                <select formControlName="LastEmploymentMonth">
                                  <option selected value="">Select a Month</option>
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                                <input type="number" formControlName="LastEmploymentYear" class="form-control rounded-0" min="1900" [max]="thisYear">
                              </div>
                              <div [innerHTML]="displayMergedError(item, 'LastEmploymentMonth', 'LastEmploymentYear')"></div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="form-group">
                              <label>Supervisor's Name: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="SupervisorName" class="form-control rounded-0" placeholder="Enter Supervisor's Name">
                              <div [innerHTML]="displayError(item, 'SupervisorName')"></div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="form-group">
                              <label>Supervisor's Job Title: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="SupervisorJobPosition" class="form-control rounded-0" placeholder="Enter Supervisor's Job Title">
                              <div [innerHTML]="displayError(item, 'SupervisorJobPosition')"></div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="form-group">
                              <label>Supervisor's Contact Number: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="SupervisorContactNumber" class="form-control rounded-0" placeholder="Enter Supervisor's Contact Number">
                              <div [innerHTML]="displayError(item, 'SupervisorContactNumber')"></div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Reason for Leaving: <strong class="text-danger">*</strong></label>
                          <textarea formControlName="ReasonForLeaving" class="rounded-0" placeholder="Reason for Leaving"></textarea>
                          <div [innerHTML]="displayError(item, 'ReasonForLeaving')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-sm-6">
                    <button type="button" class="btn btn-success w-100" (click)="addWorkExperience()">Add Work Experience</button>
                  </div>
                </div>
              </div>

              <!-- Section 5: Professional References -->
              <div class="section-header">
                <div class="section-number">5</div>
                <div class="section-header-title">Professional References</div>
              </div>
              <div class="px-4 px-sm-5 mb-4">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card mb-4" *ngFor="let item of applicationForm.get('ProfessionalReferences')['controls']; let i = index">
                      <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                          <h2 class="m-0">{{ item.get('Name').value || '' }}</h2>
                          <mat-icon style="color: red; cursor: pointer;" (click)="removeProfessionalReference(i)">delete</mat-icon>
                        </div>
                      </div>
                      <div class="card-body" [formGroup]="item">
                        <div class="row">
                          <div class="col-sm-7">
                            <div class="form-group">
                              <label>Full Name: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="Name" class="form-control rounded-0" placeholder="Enter Full Name">
                              <div [innerHTML]="displayError(item, 'Name')"></div>
                            </div>
                            <div class="form-group">
                              <label>Years Known and Capacity: <strong class="text-danger">*</strong></label>
                              <textarea formControlName="YearsKnownAndCapacity" class="rounded-0"></textarea>
                              <div [innerHTML]="displayError(item, 'YearsKnownAndCapacity')"></div>
                            </div>
                          </div>
                          <div class="col-sm-5">
                            <div class="form-group">
                              <label>Contact Number: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="ContactNumber" class="form-control rounded-0" placeholder="(___) ___-____" [value]="item.get('ContactNumber').value != null ? (item.get('ContactNumber').value | phoneFormat: 'US') : ''">
                              <div [innerHTML]="displayError(item, 'ContactNumber')"></div>
                            </div>
                            <div class="form-group">
                              <label>Occupation:</label>
                              <input type="text" formControlName="Occupation" class="form-control rounded-0" placeholder="Enter Occupation">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label>Address:</label>
                              <input type="text" formControlName="Address" class="form-control rounded-0" placeholder="Enter Address">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-sm-6">
                    <button type="button" class="btn btn-success w-100" (click)="addProfessionalReference()">Add Professional Reference</button>
                  </div>
                </div>
              </div>

              <!-- Section 6: Education & Training -->
              <div class="section-header">
                <div class="section-number">6</div>
                <div class="section-header-title">Education & Training</div>
              </div>
              <div class="px-4 px-sm-5 mb-4">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card mb-4" *ngFor="let item of applicationForm.get('EducationAndTraining')['controls']; let i = index">
                      <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                          <h2 class="m-0">{{ item.get('TypeOfSchool').value }}</h2>
                          <mat-icon style="color: red; cursor: pointer;" (click)="removeEducationAndTraining(i)">delete</mat-icon>
                        </div>
                      </div>
                      <div class="card-body" [formGroup]="item">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Name of School: <strong class="text-danger">*</strong></label>
                              <input type="text" formControlName="SchoolName" class="form-control rounded-0" placeholder="Enter Name of School">
                              <div [innerHTML]="displayError(item, 'SchoolName')"></div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Number of Years Completed: <strong class="text-danger">*</strong></label>
                              <input type="number" formControlName="YearsCompleted" class="form-control rounded-0">
                              <div [innerHTML]="displayError(item, 'YearsCompleted')"></div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Location of School: <strong class="text-danger">*</strong></label>
                          <textarea formControlName="SchoolLocation" class="rounded-0" placeholder="Enter Location of School"></textarea>
                          <div [innerHTML]="displayError(item, 'SchoolLocation')"></div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Degree:</label>
                              <input type="text" formControlName="Degree" class="form-control rounded-0" placeholder="Enter Degree">
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label>Type of Course/Major:</label>
                              <input type="text" formControlName="CourseOrMajor" class="form-control rounded-0" placeholder="Enter Type of Course/Major">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4 px-sm-5">
                  <div class="d-flex justify-content-between">
                    <button type="button" class="btn btn-success" (click)="addEducationAndTraining('Graduate')">Add Graduate</button>
                    <button type="button" class="btn btn-success" (click)="addEducationAndTraining('College')">Add College</button>
                    <button type="button" class="btn btn-success" (click)="addEducationAndTraining('High School')">Add High School</button>
                    <button type="button" class="btn btn-success" (click)="addEducationAndTraining('Business/Trade/Technical')">Add Business/Trade/Technical</button>
                  </div>
                </div>
              </div>

              <!-- Section 7: Job-related Skills and Qualifications -->
              <div class="section-header">
                <div class="section-number">7</div>
                <div class="section-header-title">Job-related Skills and Qualifications</div>
              </div>
              <div class="px-4 px-sm-5 mb-4">
                <div class="form-group">
                  <label>Summarize your job-related skills and qualifications:</label>
                  <textarea formControlName="SkillsAndQualifications" class="rounded-0" placeholder="Job-related Skills and Qualifications"></textarea>
                </div>
              </div>

              <!-- Section 8: Additional Employment Inquiries -->
              <div class="section-header">
                <div class="section-number">8</div>
                <div class="section-header-title">Additional Employment Inquiries</div>
              </div>
              <div class="px-4 px-sm-5 mb-4">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="form-group m-0">
                          <label>Will you be able to provide proof of a valid Driver's License? <strong class="text-danger">*</strong></label>
                          <div>
                            <div class="form-check form-check-inline">
                              <input id="can-provide-driver-license-proof-yes" class="form-check-input" type="radio" formControlName="CanProvideDriverLicenseProof" [value]="true">
                              <label class="form-check-label" for="can-provide-driver-license-proof-yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input id="can-provide-driver-license-proof-no" class="form-check-input" type="radio" formControlName="CanProvideDriverLicenseProof" [value]="false">
                              <label class="form-check-label" for="can-provide-driver-license-proof-no">No</label>
                            </div>
                          </div>
                          <span>If hired, you may be required to provide proof of insurance coverage or proof of driving record to be accepted for coverage under Company's insurance.</span>
                          <div [innerHTML]="displayError(applicationForm, 'CanProvideDriverLicenseProof')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column align-items-center">
                <hr style="margin: 2rem 0 3rem 0; width: 25%;">
              </div>
              <div class="row justify-content-end">
                <div class="col-sm-6">
                  <button type="submit" class="btn btn-axp-theme btn-lg w-100">Submit my Job Application</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
