<div class="bg">
  <div *ngIf="isLoaded | async">
    <div *ngIf="result['type']=='PICKUP';else dropOff;">
      <app-pickup [details]="result"></app-pickup>
    </div>
    <ng-template #dropOff>
      <app-dropoff [details]="result"></app-dropoff>
    </ng-template>
  </div>
  <div *ngIf="hasExpired | async" class="sms-container">
    <div class="sms-content">This page is already expired.</div>
  </div>
  <div *ngIf="hasError | async" class="sms-container">
    <div class="sms-content">An error has occurred.</div>
  </div>
</div>
