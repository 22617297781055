<!-- <div class="sigma_preloader sigma_preloader-gear">
  <div class="sigma_preloader-inner">
    <div class="preloader-gear">
      <div class="preloader-gear-inner">
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!--Section Start-->
<div class="sigma_subheader style-6 bg-cover bg-center title-banner">
  <div class="container">
    <div class="sigma_subheader-inner">
      <h1>Services</h1>
    </div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="btn-link" routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Services</li>
    </ol>
  </div>
</div>
<!--Section End-->
<!--Section Start-->
<!-- <div class="section section-padding bg-gray">
  <div class="container">
    <div class="section-title centered">
      <span class="subtitle">Our Services</span>
      <h3 class="title">What We Do</h3>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="sigma_service style-6">
          <div class="sigma_service-thumb">
            <img src="assets/img/services/md/1.jpg" alt="img">
            <i class="flaticon-truck icon-box"></i>
          </div>
          <div class="sigma_service-body">
            <h5>
              <a href="service-details.html">Ocean Cargo</a>
            </h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod Lorem ipsum dolor sit.</p>
            <a href="service-details.html" class="btn-link secondary-color">
              Read More
              <i class="far fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sigma_service style-6">
          <div class="sigma_service-thumb">
            <img src="assets/img/services/md/2.jpg" alt="img">
            <i class="flaticon-logistics icon-box"></i>
          </div>
          <div class="sigma_service-body">
            <h5>
              <a href="service-details.html">Fly Anywhere</a>
            </h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod Lorem ipsum dolor sit.</p>
            <a href="service-details.html" class="btn-link secondary-color">
              Read More
              <i class="far fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sigma_service style-6">
          <div class="sigma_service-thumb">
            <img src="assets/img/services/md/3.jpg" alt="img">
            <i class="flaticon-order icon-box"></i>
          </div>
          <div class="sigma_service-body">
            <h5>
              <a href="service-details.html">Service Cargo</a>
            </h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod Lorem ipsum dolor sit.</p>
            <a href="service-details.html" class="btn-link secondary-color">
              Read More
              <i class="far fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!--Section End--><!--Section Start-->
<!-- <div class="section section-padding bg-gray">
  <div class="container">
    <div class="section-title centered">
      <span class="subtitle">Our Services</span>
      <h3 class="title">What We Offer</h3>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="sigma_service style-6">
          <div class="sigma_service-thumb">
            <img src="assets/img/services/balikbayanbox-320x200.jpg" alt="img">
          </div>
          <div class="sigma_service-body">
            <h5>
              <a routerLink="/balikbayan-box">Balikbayan Box</a>
            </h5>
            <p>Enjoy a secure and easy way of sending Balikbayan packages anywhere in the Philippines with just one click!</p>
            <p>&nbsp;</p>
            <a routerLink="/balikbayan-box" class="btn-link secondary-color">
              Read More
              <i class="far fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sigma_service style-6">
          <div class="sigma_service-thumb">
            <img src="assets/img/services/mrm-320x200.jpg" alt="img">
          </div>
          <div class="sigma_service-body">
            <h5>
              <a routerLink="/remittance-details">Money Remittance</a>
            </h5>
            <p>Check out AxP’s reliable remittance service with low fees offered at great exchange rates. No hang-ups, no delays!​</p>
             <a routerLink="/remittance-details" class="btn-link secondary-color">
              Read More
              <i class="far fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sigma_service style-6">
          <div class="sigma_service-thumb">
            <img src="assets/img/services/logistics-320x200.jpg" alt="img">
          </div>
          <div class="sigma_service-body">
            <h5>
              <a routerLink="/">Logistics</a>
            </h5>
            <p>LOGO upholds safe and up-to-par standards in its logistics line throughout the Philippines.</p>
            <p>&nbsp;</p>
            <a routerLink="/" class="btn-link secondary-color">
              Read More
              <i class="far fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!--Section End-->
 <!--Section Start-->
 <div class="section section-padding bg-cover bg-center bg-white" style="background-image: url(assets/img/map-texture.png)">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-6">
        <div class="section-title">
          <span class="subtitle">Our Services</span>
          <h3 class="title ">Choose Among Our Best Offers</h3>
          <p>
            With years of expertise in the industry, LOGO continues
            to find the best solutions to connect people at different
            parts of the world.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 pb-4">
        <div class="sigma_service style-9 dark h-100">
          <span>01</span>
          <i class="flaticon-transportation"></i>
          <div class="sigma_service-body">
            <h5>
              <a routerLink="/balikbayan-box">Balikbayan Box</a>
            </h5>
            <p>
              Cross long-distance relationships via our Balikbayan Box
              shipping.
            </p>
            <div class="clearfix">
              <a routerLink="/balikbayan-box" class="btn-link float-end">
                Read More
                <i class="far fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 pb-4">
        <div class="sigma_service style-9 dark h-100">
          <span>02</span>
          <i class="flaticon-warehouse"></i>
          <div class="sigma_service-body">
            <h5>
              <a href="https://axpremit.com" target="_blank">Money Remittance</a>
            </h5>
            <p>Experience fast, secure, and convenient money transfers.</p>
            <div class="clearfix">
              <a href="https://axpremit.com" target="_blank" class="btn-link float-end">
                Read More
                <i class="far fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 pb-4">
        <div class="sigma_service style-9 dark h-100">
          <span>03</span>
          <i class="flaticon-location"></i>
          <div class="sigma_service-body">
            <h5>
              <a href="https://abxpinc.com" target="_blank">Logistics</a>
            </h5>
            <p>
              Tap into our nationwide transport services solutions from
              freight forwarding, logistics support and distribution.
            </p>
            <div class="clearfix">
              <a href="https://abxpinc.com" target="_blank" class="btn-link float-end">
                Read More
                <i class="far fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 pb-4">
        <div class="sigma_service style-9 dark h-100">
          <span>04</span>
          <i class="flaticon-logistics-1"></i>
          <div class="sigma_service-body">
            <h5>
              <a href="https://abxpinc.com" target="_blank">Brokerage</a>
            </h5>
            <p>
              Avoid the hassles of customs clearance processing and let our
              expert agents do the work for you.​
            </p>
            <div class="clearfix">
              <a href="https://abxpinc.com" target="_blank" class="btn-link float-end">
                Read More
                <i class="far fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 pb-4">
        <div class="sigma_service style-9 dark h-100">
          <span>05</span>
          <i class="flaticon-shopping-cart-2"></i>
          <div class="sigma_service-body">
            <h5>
              <a href="https://logomegastore.com" target="_blank">E-Commerce</a>
            </h5>
            <p>
              Take advantage of our one-stop-shop online store that caters
              high-quality products proudly made in the Philippines.
            </p>
            <div class="clearfix">
              <a href="https://logomegastore.com" target="_blank" class="btn-link float-end">
                Read More
                <i class="far fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 pb-4">
        <div class="sigma_service style-9 dark h-100">
          <span>06</span>
          <i class="flaticon-truck"></i>
          <div class="sigma_service-body">
            <h5>
              <a routerLink="/">LOGO Driver</a>
            </h5>
            <p>
              Experience fast delivery of your parcels with LOGO Driver.
            </p>
            <div class="clearfix">
              <a routerLink="/" class="btn-link float-end">
                Read More
                <i class="far fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Section End-->
<!--Section Start-->
<div class="section section-padding">
  <div class="container">
    <div class="section-title centered">
      <span class="subtitle">Our Process</span>
      <h3 class="title" id="work-process">Our Work Process</h3>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="sigma_info style-16">
          <div class="sigma_info-title">
            <span class="sigma_info-icon">
              <i class="flaticon-box"></i>
              <span>1</span>
            </span>
            <h5>Pick Up and Drop Offer</h5>
          </div>
          <div class="sigma_info-description">
            <p>
              Our LOGO staff ensures excellent and high-quality customer
              service, treating all our clients with utmost courtesy and
              handling all shipped Balikbayan Boxes with care.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="sigma_info style-16">
          <div class="sigma_info-title">
            <span class="sigma_info-icon">
              <i class="flaticon-warehouse"></i>
              <span>2</span>
            </span>
            <h5>Warehousing Operation</h5>
          </div>
          <div class="sigma_info-description">
            <p>
              Our shipping operations are powered with smart logistic
              software that tags and tracks their packages, with LOGO staff
              overseeing careful handling and loading of shipment for sea
              transport.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="sigma_info style-16">
          <div class="sigma_info-title">
            <span class="sigma_info-icon">
              <i class="flaticon-shipped"></i>
              <span>3</span>
            </span>
            <h5>Delivery </h5>
          </div>
          <div class="sigma_info-description">
            <p>
              LOGO staff in Manila ensures that all offloaded packages are
              promptly prepared for delivery via dedicated trucks designated
              for specific areas and regions in the country.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="sigma_info style-16">
          <div class="sigma_info-title">
            <span class="sigma_info-icon">
              <i style="background:url('assets/fonts/flaticon/support-b.svg') no-repeat;  width: 36px; height: 35px; " class="text-white"></i>
              <span>4</span>
            </span>
            <h5>Customer Service</h5>
          </div>
          <div class="sigma_info-description">
            <p>
              LOGO's diligent customer service personnel monitors all
              vehicle drivers handling delivery, and meticulously checks that
              all delivery processes are earnestly attended from pick up to
              delivery to customer feedback.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Section End-->
