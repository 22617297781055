<app-spinner></app-spinner>

<div class="sigma_subheader style-6 bg-cover bg-center title-banner">
  <div class="container">
    <div class="sigma_subheader-inner">
      <h1>Tracking</h1>
    </div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="btn-link" routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Tracking</li>
    </ol>
  </div>
</div>

<div class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <h1 class="title">TRACKING UPDATE</h1>
        <form
          [formGroup]="invoiceForm"
          (ngSubmit)="loadInvoiceStatuses()"
          novalidate
        >
          <div class="input-group">
            <input
              #input
              id="invoiceno"
              type="text"
              class="form-control"
              name="tracking_number"
              placeholder="Enter your Tracking # / Invoice #"
              formControlName="invoice"
              autofocus
            />
            <button type="submit" class="sigma_btn">TRACK</button>
          </div>
        </form>
        <p id="trackmessage" style="margin-top: 14px">
          To track your boxes, please enter your invoice number in the search
          box and press the <b style="color: red">"Track"</b> button.
        </p>
        <strong>
          Note:
          <span class="text-danger"
            >Tracker date and time results are in Philippine Time Zone.</span
          >
        </strong>
      </div>

      <div
        class="row justify-content-center mt-4"
        *ngIf="result != null && result.invoice == null"
      >
        <div class="col-md-8">
          <div
            class="alert alert-warning alert-dismissible fade show text-center"
            role="alert"
          >
            We are unable to find the invoice number you provided. Please make
            sure you have the correct number or you can call our help desk
            support at Tel. No. {{ telephone }}
          </div>
        </div>
      </div>

      <div *ngIf="result != null && result.invoice != null">
        <div class="row justify-content-center mt-4">
          <div class="col-md-10">
            <div class="card box">
              <div class="card-body">
                <!-- INVOICE DETAILS -->
                <h3 class="card-title text-success mb-2">
                  <i class="fa fa-file-invoice"></i>&nbsp; Invoice Details
                </h3>

                <div class="row">
                  <div class="col-sm-3">
                    <strong>Sender Information</strong>
                  </div>
                  <div class="col-sm-3">
                    <strong>Consignee Information</strong>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-3">
                    {{ result.invoice["customerFullname"] }}
                  </div>
                  <div class="col-sm-3">
                    {{ result.invoice["consigneeName"] }}
                    {{ result.invoice["consigneeLastName"] }}
                  </div>
                </div>
                <!-- INVOICE DETAILS END -->
                <br />
                <div class="alert alert-primary" role="alert">
                  <ul>
                    <li>
                      <center>
                        A Friendly Reminder:
                        <strong
                          >For smooth delivery and to avoid failed attempts,
                          please keep your consignee's phone number
                          updated.</strong
                        >
                      </center>
                    </li>
                  </ul>
                </div>
                <!-- Delivery -->
                <div class="row mt-2" *ngIf="result.deliveries.length > 0">
                  <div class="col-md-12">
                    <h3 class="text-success mb-0">Delivery Status</h3>
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">No. of Boxes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Delivery -->
                        <ng-container
                          *ngFor="let delivery of result.deliveries"
                        >
                          <ng-container
                            *ngIf="delivery.type === 'CustomerPickUp'"
                          >
                            <ng-container
                              *ngIf="delivery.completedNoOfBoxes > 0"
                            >
                              <tr>
                                <td>
                                  {{
                                    delivery.completedDate
                                      | date : "MMMM dd, yyyy"
                                  }}
                                </td>
                                <td class="text-success">
                                  <strong
                                    >Package has been picked up by
                                    {{ delivery.consignee }} at
                                    {{ delivery.branch }} Branch</strong
                                  >
                                </td>
                                <td>
                                  {{ delivery.completedNoOfBoxes }}
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>

                          <tr
                            *ngIf="
                              delivery.type === 'DeliveryPoint' &&
                              delivery.completedNoOfBoxes > 0
                            "
                          >
                            <td>
                              {{
                                delivery.completedDate | date : "MMMM dd, yyyy"
                              }}
                            </td>
                            <td class="text-success">
                              <strong>Package has been delivered</strong>
                            </td>
                            <td>{{ delivery.completedNoOfBoxes }}</td>
                          </tr>
                          <tr
                            *ngIf="
                              delivery.type === 'DeliveryPoint' &&
                              delivery.inTransitNoOfBoxes > 0
                            "
                          >
                            <td>
                              {{
                                delivery.inTransitDate | date : "MMMM dd, yyyy"
                              }}
                            </td>
                            <td>
                              <!-- <span *ngIf="delivery.deliveryAttempts.length > 0">
                                <strong class="text-danger">FAILED DELIVERY ATTEMPT</strong>
                                <span *ngIf="delivery.deliveryAttempts.length > 0">
                                  <div *ngFor="let item of delivery.deliveryAttempts">
                                    [Reason] {{item.note}}
                                  </div>
                                </span>
                                <br />
                              </span> -->
                              <!--End Delivery Attempts-->

                              Your package is out for delivery.
                              <br />
                              It will take a while depending on your consignees
                              location.<br />
                              [Driver] {{ delivery.driverName }} <br />

                              <span *ngIf="delivery.advisories.length > 0">
                                <div
                                  *ngFor="let advisory of delivery.advisories"
                                  class="text-danger"
                                >
                                  ({{ advisory }})
                                </div>
                              </span>
                            </td>
                            <td>{{ delivery.inTransitNoOfBoxes }}</td>
                          </tr>

                          <!-- Hub Transfer -->
                          <ng-container *ngIf="delivery.type === 'HubTransfer'">
                            <tr
                              *ngIf="
                                delivery.completedNoOfBoxes &&
                                delivery.completedNoOfBoxes > 0
                              "
                            >
                              <td>
                                {{
                                  delivery.completedDate
                                    | date : "MMMM dd, yyyy"
                                }}
                              </td>
                              <td>
                                Package has been received at
                                {{ delivery.destination }}
                              </td>
                              <td>{{ delivery.completedNoOfBoxes }}</td>
                            </tr>
                            <tr *ngIf="delivery.inTransitNoOfBoxes > 0">
                              <td>
                                {{
                                  delivery.inTransitDate
                                    | date : "MMMM dd, yyyy"
                                }}
                              </td>
                              <td>
                                Package is in transit to
                                {{ delivery.destination }}<br />
                                [ETA:
                                {{ delivery.eta | date : "MMMM dd, yyyy" }}]
                                <span *ngIf="delivery.advisories.length > 0">
                                  <div
                                    *ngFor="let advisory of delivery.advisories"
                                    class="text-danger"
                                  >
                                    ({{ advisory }})
                                  </div>
                                </span>
                              </td>
                              <td>{{ delivery.inTransitNoOfBoxes }}</td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Delivery End -->

                <!-- Shipment -->
                <div class="row mt-4" *ngFor="let item of result.shipmentH">
                  <div class="col-md-12">
                    <h3 class="text-success mb-2">Shipment Information</h3>
                    <!-- <h3 class="mb-0 ml-1"><strong>{{ item.shipmentNo }}</strong></h3> -->
                    <!-- Shipment Comments -->
                    <div
                      class="alert alert-warning"
                      role="alert"
                      *ngIf="item.comments.length > 0"
                    >
                      <strong>Advisory</strong>
                      <ul>
                        <li *ngFor="let item of item.comments">
                          {{ item.tdate | date : "shortDate" }} -
                          {{ item.comment }}
                        </li>
                      </ul>
                    </div>
                    <!-- End Comments -->

                    <!-- Contents -->
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">No. of Boxes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Warehouse Decon -->
                        <ng-container *ngIf="item.deconRcvD.noOfBoxes > 0">
                          <tr>
                            <td>
                              {{ item.deconRcvD.date | date : "MMMM dd, yyyy" }}
                            </td>
                            <td>
                              Package has been received at
                              {{ item.deconRcvD.warehouse }} Warehouse
                            </td>
                            <td>{{ item.deconRcvD.noOfBoxes }}</td>
                          </tr>
                        </ng-container>
                        <!-- End Warehouse Decon -->

                        <!-- Container Status -->
                        <ng-container *ngIf="item.manilaAirportDate">
                          <tr>
                            <td>
                              {{
                                item.manilaAirportDate | date : "MMMM dd, yyyy"
                              }}
                            </td>
                            <td>
                              Container arrived at Manila Airport <br />
                              <span class="text-danger"
                                >(Please allow 2-4 working days for Bureau of
                                Customs processing/releasing)</span
                              >
                            </td>
                            <td></td>
                            <!-- <td>{{ item.noOfBoxes }}</td> -->
                          </tr>
                        </ng-container>
                        <!-- <ng-container *ngIf="item.phReceivedManilaDate && item.deconRcvD.noOfBoxes > 0"> -->
                        <ng-container *ngIf="item.phReceivedManilaDate">
                          <tr>
                            <td>
                              {{
                                item.phReceivedManilaDate
                                  | date : "MMMM dd, yyyy"
                              }}
                            </td>
                            <td>
                              Container arrived in Manila Warehouse<br />
                              <span class="text-danger">
                                Please allow below number of days for Warehouse
                                Receiving and<br />
                                Processing:<br />
                                &nbsp;&nbsp;&nbsp;Metro Manila 1-3 Days<br />
                                &nbsp;&nbsp;&nbsp;Luzon 3-6 Days<br />
                                &nbsp;&nbsp;&nbsp;Visayas & Mindanao 10-15 Days
                              </span>
                            </td>
                            <td></td>
                            <!-- <td>{{ item.noOfBoxes }}</td> -->
                          </tr>
                        </ng-container>

                        <ng-container *ngIf="item.phReceivedTarlacDate">
                          <tr>
                            <td>
                              {{
                                item.phReceivedTarlacDate
                                  | date : "MMMM dd, yyyy"
                              }}
                            </td>
                            <td>
                              Container arrived at Tarlac Warehouse<br />
                              <span class="text-danger">
                                Please allow below number of days for Warehouse
                                Receiving and<br />
                                Processing:<br />
                                &nbsp;&nbsp;&nbsp;Metro Manila 1-3 Days<br />
                                &nbsp;&nbsp;&nbsp;Luzon 3-6 Days<br />
                                &nbsp;&nbsp;&nbsp;Visayas & Mindanao 10-15 Days
                              </span>
                            </td>
                            <td></td>
                            <!-- <td>{{ item.noOfBoxes }}</td> -->
                          </tr>
                        </ng-container>

                        <!-- <ng-container *ngIf="item.manilaPortDate && item.deconRcvD.noOfBoxes > 0"> -->
                        <ng-container *ngIf="item.manilaPortDate">
                          <tr>
                            <td>
                              {{ item.manilaPortDate | date : "MMMM dd, yyyy" }}
                            </td>
                            <td>
                              Shipment arrived at the Port of Manila <br />
                              <span class="text-danger"
                                >(Please allow 2-4 working days for Bureau of
                                Customs processing/releasing.)</span
                              >
                            </td>
                            <td></td>
                            <!-- <td>{{ item.noOfBoxes }}</td> -->
                          </tr>
                        </ng-container>

                        <ng-container *ngIf="item.inTransitDate">
                          <tr>
                            <td>
                              {{ item.inTransitDate | date : "MMMM dd, yyyy" }}
                            </td>
                            <td>
                              Shipment is in transit to Philippines <br />
                              <span class="text-danger"
                                >Estimated Time of Arrival at the Port of
                                Manila:
                                {{ item.eta | date : "MM/dd/yyyy" }}</span
                              >
                              <span *ngIf="item.advisories.length > 0">
                                <div
                                  *ngFor="let advisory of item.advisories"
                                  class="text-danger"
                                >
                                  ({{ advisory }})
                                </div>
                              </span>
                            </td>
                            <td></td>
                            <!-- <td>{{ item.noOfBoxes }}</td> -->
                          </tr>
                        </ng-container>

                        <tr>
                          <td>
                            {{ item.receivedDate | date : "MMMM dd, yyyy" }}
                          </td>
                          <td>Package has been loaded in the container</td>
                          <td>{{ item.noOfBoxes }}</td>
                        </tr>
                        <!-- End Container Status -->
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Pick-up / Drop-off Information -->
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h3 class="text-success mb-2">
                      {{
                        result.invoice.serviceTypeCode.toLowerCase() ===
                        "dropoff"
                          ? "Drop-off"
                          : "Pick-up"
                      }}
                      Information
                    </h3>

                    <!-- Contents -->
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">No. of Boxes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Invoice received in warehouse -->
                        <tr *ngIf="result.invoice.isInWarehouse">
                          <td>
                            {{
                              result.invoice.inWarehouseDate
                                | date : "MMMM dd, yyyy"
                            }}
                          </td>
                          <td>
                            <div>Package has arrived in warehouse.</div>
                          </td>
                          <td>{{ result.invoice.noOfBoxes }}</td>
                        </tr>

                        <!-- Box transfer from branch to driver -->
                        <tr
                          *ngIf="
                            result.invoice.serviceTypeCode.toLowerCase() ===
                              'dropoff' &&
                            !result.invoice.isInWarehouse &&
                            result.invoice.inDriverHubDate &&
                            result.invoice.driverHubDriver
                          "
                        >
                          <td>
                            {{
                              result.invoice.inDriverHubDate
                                | date : "MMMM dd, yyyy"
                            }}
                          </td>
                          <td>
                            <div>
                              Package is now being transferred from branch to
                              warehouse.
                            </div>
                            <div>
                              Driver:
                              <strong>{{
                                result.invoice.driverHubDriver
                              }}</strong>
                            </div>
                          </td>
                          <td>{{ result.invoice.noOfBoxes }}</td>
                        </tr>

                        <!-- Invoice received by driver -->
                        <tr
                          *ngIf="
                            result.invoice.serviceTypeCode.toLowerCase() ===
                              'pickup' && result.invoice.isPickupReceived
                          "
                        >
                          <td>
                            {{
                              result.invoice.actualPickupDate
                                | date : "MMMM dd, yyyy"
                            }}
                          </td>
                          <td>
                            <div>Package has been picked up by driver.</div>
                            <div>
                              Driver:
                              <strong>{{ result.invoice.driver }}</strong>
                            </div>
                          </td>
                          <td>{{ result.invoice.noOfBoxes }}</td>
                        </tr>

                        <!-- Invoice received by branch -->
                        <tr
                          *ngIf="
                            result.invoice.serviceTypeCode.toLowerCase() ===
                              'dropoff' && result.invoice.actualDropoffDate
                          "
                        >
                          <td>
                            {{
                              result.invoice.actualDropoffDate
                                | date : "MMMM dd, yyyy"
                            }}
                          </td>
                          <td>
                            <div>
                              Package has been dropped off to
                              <strong>{{
                                result.invoice.assignedBranch
                              }}</strong>
                              branch.
                            </div>
                            <div>
                              Confirmed by:
                              <strong>{{
                                result.invoice.dropOffReceivedBy
                              }}</strong>
                            </div>
                          </td>
                          <td>{{ result.invoice.noOfBoxes }}</td>
                        </tr>

                        <!-- Driver assigned to invoice -->
                        <tr
                          *ngIf="
                            result.invoice.serviceTypeCode.toLowerCase() ===
                              'pickup' &&
                            result.invoice.isPickupAssigned &&
                            result.invoice.driver
                          "
                        >
                          <td>
                            {{
                              result.invoice.pickupAssignDate
                                | date : "MMMM dd, yyyy"
                            }}
                          </td>
                          <td>
                            <div>
                              A driver has been assigned to your invoice.
                            </div>
                            <div>
                              Assigned Driver:
                              <strong>{{ result.invoice.driver }}</strong>
                            </div>
                          </td>
                          <td>{{ result.invoice.noOfBoxes }}</td>
                        </tr>

                        <!-- Invoice created -->
                        <tr>
                          <td>
                            {{
                              result.invoice.dateCreated
                                | date : "MMMM dd, yyyy"
                            }}
                          </td>
                          <td>Invoice has been created.</td>
                          <td>{{ result.invoice.noOfBoxes }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- CSR Notes -->
                <!-- <div *ngIf="notes.length > 0">
                  <hr />
                  <strong>CSR Notes</strong>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <strong>Date</strong>
                    </div>
                    <div class="col-sm-3">
                      <strong>User</strong>
                    </div>
                    <div class="col">
                      <strong>Notes</strong>
                    </div>
                  </div>
                  <div class="row" *ngFor="let item of notes; let i = index">
                    <div class="col-sm-3">
                      {{ item.tdate | date: "MMMM dd, yyyy, h:mm a" }}
                    </div>
                    <div class="col-sm-3">{{ item.user }}</div>
                    <div class="col">
                      {{ item.notes }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
