import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FremanService } from 'src/app/services/freman.service';

@Component({
  selector: 'app-customer-activation',
  templateUrl: './customer-activation.component.html',
  styleUrls: ['./customer-activation.component.scss']
})
export class CustomerActivationComponent implements OnInit {
  tz = moment.tz.guess();
  loaded = false;
  customerName = '';
  userName = '';
  password = '';

  constructor(private fremanService: FremanService,
    private route: Router,
    private routeParam: ActivatedRoute,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    /* let paramId = this.routeParam.snapshot.queryParams["id"];
    if (paramId) {
      this.spinner.show();
      this.fremanService.activateCustomer({
        CustomerId: paramId,
        TimeZoneId: this.tz
      }).subscribe(res => {
        this.loaded = true;
        this.customerName = res.CustomerName;
        this.userName = res.Email;
        this.password = res.Password;
        this.spinner.hide();
      }, error => {
        this.route.navigate(['/404']);
        this.spinner.hide();
      });
    } else {
      this.route.navigate(['/404']);
      this.spinner.hide();
    } */
  }

}
