<div class="mt-5">
    <ng-container *ngIf="historyList.length > 0;else noList">
        <div id="kt_datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="row">
                <div class="col-sm-12 table-responsive">
                    <table class="table table-striped">
                        <!-- *ngIf="invoiceList" datatable [dtOptions]="dtOptions" -->
                        <thead>
                            <tr>
                                <th style="width: auto;">Invoice No.</th>
                                <th style="width: auto;">Service</th>
                                <th style="width: auto;">Branch</th>
                                <th style="width: auto;">Phone No.</th>
                                <th style="width: auto;">No. of boxes</th>
                                <th style="width: auto;">Consignee Name</th>
                                <th style="width: auto; text-align: center;" *ngIf="hasAction">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of historyList">
                                <td>
                                    {{item.InvoiceNo}}
                                </td>
                                <td>
                                    Type: {{item.ServiceTypeCode}}<br>
                                    <!--  Date: {{item.ServiceTypeDate | date:'MMM dd, yyyy, hh:mm a'}} -->
                                </td>
                                <td>
                                    {{item.BranchName}}
                                </td>
                                <td>
                                    {{item.PhoneNumber}}
                                </td>
                                <td>{{item.NoOfBoxes}}</td>
                                <td>{{item.ConsigneeName}}&nbsp;{{item.ConsigneeLastName}}</td>
                                <td align="center" *ngIf="hasAction">
                                    <!-- <button *ngIf="item.IsPaid" class="btn btn-axp-theme" style="background-color: green; color: white; border: none;">Pay Now</button> -->
                                    <button *ngIf="item.IsPaid" class="btn btn-axp-theme"
                                        style="background-color: orange; color: white;width: 100px; border: none;"
                                        (click)="viewTransactionDetails(item)">View</button>

                                    <button *ngIf="!item.IsPaid" class="btn btn-axp-theme" (click)="onPayment(item)"
                                        style="background-color: green; color: white; width: 100px;border: none;">Pay
                                        Now</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #noList>
        <h2 class="text-center">
            <i>
                No Transactions
            </i>
        </h2>
    </ng-template>
</div>