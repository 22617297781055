import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType, CountryCode } from 'libphonenumber-js';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

  transform(number: string, countryCode?: CountryCode): string {
    return new AsYouType(countryCode).input(number);
  }
}
