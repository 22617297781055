<app-spinner></app-spinner>

<!--Section Start-->
<div class="sigma_subheader style-6 bg-cover bg-center title-banner">
    <div class="container">
        <div class="sigma_subheader-inner">
            <h1>Online Payment</h1>
        </div>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a class="btn-link" routerLink="/">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Online Payment</li>
        </ol>
    </div>
</div>
<!--Section End-->

<!--Section Start-->
<div class="section">
    <div class="container" *ngIf="isLoaded">

        <div class="row mb-4" *ngIf="!invoice.IsPaid;">
            <div class="col-sm-2"></div>
            <div class="col-sm-8">
                <div class="card shadow">
                    <div class="card-body">
                        <h3 class="bold-text solid-black-text">
                            Invoice No: <span class="information-text-size"><strong>{{ invoice.InvoiceNo
                                    }}</strong></span>
                        </h3>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <h3 class="bold-text solid-black-text">
                                    Sender Information
                                </h3>
                                <p class="ml-5 information-text-size">
                                    <span class="bold-text solid-black-text">{{ invoice.CustomerFirstName }} {{
                                        invoice.CustomerLastName }}</span>
                                    <br><span>{{ invoice.BillingAddress + ", " + invoice.BillingCity + ", " +
                                        invoice.BillingState + ", " + invoice.BillingCountry + ", " +
                                        invoice.BillingZipCode}}</span>
                                </p>
                            </div>
                            <div class="col-12 col-sm-6">
                                <h3 class="bold-text solid-black-text">
                                    Consignee Information
                                </h3>
                                <p class="ml-5 information-text-size">
                                    <span class="bold-text solid-black-text">{{ invoice.ConsigneeName }}</span>
                                    <br><span>{{ invoice.ConsigneeAddress + ", " + invoice.ConsigneeBrgy + ", " +
                                        invoice.ConsigneeCity + ", " + invoice.ConsigneeState + ", " +
                                        invoice.ConsigneeCountry + ", " + invoice.ConsigneeZipcode}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2"></div>
        </div>
        <ng-container *ngIf="isLinkExpired;else payment">
            <div class="text-center">
                <h1><i class="fa fa-exclamation-circle text-danger"></i> Oops the link is expired or invalid.
                </h1>
            </div>
        </ng-container>
        <ng-template #payment>
            <ng-container *ngIf="invoice.IsPaid;else pay">
                <div class="row">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-8">
                        <div class="text-center card p-5">
                            <h2><i class="fa fa-check-circle text-success"></i>
                                Online Payment
                            </h2>

                            Hi {{invoice['CustomerFirstName']}} {{invoice['CustomerLastName']}}, <br /><br />
                            Your payment for Invoice {{invoice['InvoiceNo']}} has been processed.
                            <hr />
                            <strong>Payment Details</strong><br />
                            <div class="row">
                                
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col text-end">Amount:</div>
                                        <div class="col text-start"><strong>{{currency[invoice['BillingCountry']]}} {{invoice.Payment.Amount}}</strong></div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row">
                                
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col text-end">Method:</div>
                                        <div class="col text-start"><strong>{{invoice.Payment.Method}}</strong></div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row">
                                
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col text-end">Date:</div>
                                        <div class="col text-start"><strong>{{invoice.Payment.Date|date:'MM/dd/yyyy'}}</strong></div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row">
                                
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col text-end">Auth Code:</div>
                                        <div class="col text-start"><strong>{{invoice.Payment.AuthCode}}</strong></div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row">
                                
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col text-end">Account Number:</div>
                                        <div class="col text-start"><strong>{{invoice.Payment.AccountNumber}}</strong></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2"></div>
                </div>
            </ng-container>
            <ng-template #pay>
                <app-payment [invoice]="invoice" [subType]="'Freman Online Payment'" [states]="billingStateList" [deliveryZoneList]="deliveryZoneList"
                    [branchList]="branchList" (back)="onPaymentBack()">
                </app-payment>
            </ng-template>
        </ng-template>
    </div>
</div>

<!--Section End-->