<div class="overflow-hidden">
  <div class="sigma_subheader style-6 bg-cover bg-center title-banner">
    <div class="container">
      <div class="sigma_subheader-inner">
        <h1>Terms and Conditions</h1>
      </div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="btn-link" routerLink="/">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Terms and Conditions
        </li>
      </ol>
    </div>
  </div>
</div>

<div class="section">
  <div class="container">
    <h2><strong>LONGARES GLOBAL OPERATIONS, INC. TERMS AND CONDITIONS</strong></h2>
    <p>
      By the rendering of items and personal effects, hereinafter “Goods”, for shipment
      via LONGARES GLOBAL OPERATIONS, INC, the SHIPPER/CUSTOMER agrees to the terms and
      conditions stated herein and the declaration of the SHIPPER/CUSTOMER made on this
      invoice which are incorporated herein by reference. No agent or employee of
      LONGARES GLOBAL OPERATIONS, INC. or the SHIPPER/CUSTOMER may alter these terms:
    </p>
    <br />

    <h3><strong>1. SERVICES</strong></h3>
    <p>
      Unless any special services are agreed in writing, the service to be provided
      by LONGARES GLOBAL OPERATIONS, INC. is limited to the collection, transportation
      and delivery of Goods packaged by SHIPPER/CUSTOMER in individual boxes (Hereinafter
      referred to as “Shipments”). The SHIPPER/CUSTOMER acknowledges that Shipments will
      be consolidated with those of other SHIPPER/CUSTOMERs for transport.
    </p>
    <br />

    <h3><strong>2. COMPANY</strong></h3>
    <p>
      LONGARES GLOBAL OPERATIONS, INC. is an FMC Licensed OTI/NVOCC Non-Vessel Licensed
      company authorized to conduct SERVICES.
    </p>
    <br />

    <h3><strong>3. SHIPPER / CUSTOMER</strong></h3>
    <p>
      Is an individual who authorizes LONGARES GLOBAL OPERATIONS, INC. to provide SERVICES
      by filling out this Invoice and paying the stated fees.
    </p>
    <br />

    <h3><strong>4. THIS INVOICE</strong></h3>
    <p>
      This Invoice is non-negotiable, and the SHIPPER/CUSTOMER acknowledges that it has
      been prepared by the SHIPPER/CUSTOMER or authorized on behalf of the SHIPPER/CUSTOMER.
      The SHIPPER/CUSTOMER warrants that it is the owner of the Goods transported hereunder,
      or it is the authorized agent of the owner of the Goods, and that it hereby accepts
      the terms and conditions of LONGARES GLOBAL OPERATIONS.
    </p>
    <br />

    <h3><strong>5. PROVISIONS AND LIMITATIONS</strong></h3>
    <p>
      a. The Shipment must not contain any illegal, restricted, contraband, car spare parts
      (known as “Chop-chop”) or otherwise prohibited items by and in the countries of origin
      and destination of cargo. The Shipment must not contain water, petrol products and
      other liquid products that may damage consolidated Shipment/s Boxes of other
      SHIPPER/CUSTOMERs.
    </p>
    <p>
      b. Prohibited items include: Firearms, ammunitions and explosives, including spare
      parts thereof, prohibited drugs including but not limited to marijuana, cocaine,
      barbiturates, heroin, etc. and prescription drugs (prescription attached); absinthe
      (alcohol) alcoholic beverages, automobiles, dismantled auto parts; biological; ceramic
      tableware; cultural artifacts and pottery; defense article or items with military or
      proliferation applications; dog and cat fur; drug paraphernalia, fish and wildlife,
      food products such as cheese, meat, fruits and vegetables, hazardous materials, game
      and hunting trophies, gold; Haitian animal hide drums, meats, livestock and poultry;
      merchandise from embargoed countries; pets, plants and seeds; soil; textile (roll).
      Used clothing of commercial quantity (Ukay-ukay in bales); articles that infringe
      intellectual property rights of their owners, pornographic materials, fluids and
      perishable food items. SHIPPER/CUSTOMER Initials ________
    </p>
    <p>
      c. The Shipment must not contain any of the following: Goods of more than 24 pieces of
      each kind within a Shipment box and/or of commercial quantity or has value exceeding
      $500.
    </p>
    <p>
      d. If it comes to the attention of LONGARES GLOBAL OPERATIONS, INC. that any Shipment
      box does not meet any of the above restrictions or conditions, LONGARES GLOBAL
      OPERATIONS, INC. may refuse to transport the pertinent box (or any Shipment of which
      it is a part) and, if carriage is in progress, LONGARES GLOBAL OPERATIONS, INC. may
      suspend Shipment.
    </p>
    <p>
      e. LONGARES GLOBAL OPERATIONS, INC. may also suspend Shipment if it cannot fulfill
      delivery at the third attempt, if the receiver refuses to accept delivery, or if it is
      unable to effect delivery because of an incorrect address after having used all
      reasonable means to find the correct address.
    </p>
    <p>
      f. LONGARES GLOBAL OPERATIONS, INC. is, at its discretion, entitled to suspend Shipment
      and return the Shipment to the SHIPPER/CUSTOMER.
    </p>
    <p>
      g. SHIPPER/CUSTOMER will be responsible for the reasonable costs and expenses of
      LONGARES GLOBAL OPERATIONS, INC. (including storage) for such losses, taxes and customs,
      duties as LONGARES GLOBAL OPERATIONS, INC. may suffer because of non-compliance by the
      SHIPPER/CUSTOMER of the representations, terms and conditions contained herein. For
      claims made against LONGARES GLOBAL OPERATIONS, INC. because a Shipment does not comply
      with any of the restrictions, conditions or representations herein or because of any
      refusal or suspension of carriage or return of a Shipment, SHIPPER/CUSTOMER shall hold
      free and harmless and indemnify LONGARES GLOBAL OPERATIONS, INC. as set forth in 6d
      below. In the case of the return of a Shipment or any part thereof, the SHIPPER/CUSTOMER
      will also be responsible for paying all applicable charges calculated in accordance with
      the prevailing rates of LONGARES GLOBAL OPERATIONS, INC.
    </p>
    <p>
      h. If the SHIPPER/CUSTOMER tenders to LONGARES GLOBAL OPERATIONS, INC. a Shipment which
      fails to comply with any of the warranties, restrictions or conditions herein, LONGARES
      GLOBAL OPERATIONS, INC. will not have any liability for loss that the SHIPPER/CUSTOMER
      may suffer in connection with the Carriage by LONGARES GLOBAL OPERATIONS, INC. of such
      Shipment (regardless of whether that failure to comply has caused or contributed to the
      loss and notwithstanding any negligence on the part of LONGARES GLOBAL OPERATIONS, INC.
      or its employees, contractors or representatives), and if LONGARES GLOBAL OPERATIONS,
      INC. does suspend carriage for a reason allowed by these Terms, the SHIPPER/CUSTOMER
      shall not be entitled to any refund on the carriage charges it has paid and LONGARES
      GLOBAL OPERATIONS, INC. may bring a claim in respect of such non-compliance.
    </p>
    <p>
      i. If having suspended a Shipment in accordance with these provisions and LONGARES
      GLOBAL OPERATIONS, INC. is unable within a reasonable time to obtain the
      SHIPPER/CUSTOMER’s instruction on disposition of the Shipment or to identify the
      SHIPPER/CUSTOMER or any other person entitled to the Shipment ( if necessary opening
      the Shipment to determine where the Shipment belongs), LONGARES GLOBAL OPERATIONS,
      INC. shall be entitled to destroy or sell the Shipment and retain its absolute charges,
      cost or expenses (including interest) outstanding in respect of the Shipment or
      otherwise due from the SHIPPER/CUSTOMER concerned. Any balance shall be held to the
      SHIPPER/CUSTOMERS’ order.
    </p>
    <p>
      j. LONGARES GLOBAL OPERATIONS, INC. reserves the right, but is not obliged, to scan by
      means of X-ray, any Shipment tendered to it for transportation at any time.
    </p>
    <p>
      k. LONGARES GLOBAL OPERATIONS, INC. reserves the right to withhold delivery of Shipments
      due to payment issues such as, but not limited to, non-payment in full or in part,
      returned checks or declined credit cards.
    </p>
    <br />

    <h3><strong>6. LIMITS AND LIABILITIES</strong></h3>
    <p>
      a. SHIPPER/CUSTOMER agrees that the maximum limit of liability assumed and payable by
      LONGARES GLOBAL OPERATIONS, INC., their agents, employees, for any loss or consequential
      loss arising from a damaged, lost, delivered or undelivered Shipment is $200.00 per
      Shipment/s box.
    </p>
    <p>
      b. SHIPPER/CUSTOMER fully understands and accepts that SHIPPER/CUSTOMER is fully
      responsible for packing and sealing of Shipment boxes. For all Goods, SHIPPER/CUSTOMER
      fully acknowledges that proper handling and packing was done and that LONGARES GLOBAL
      OPERATIONS, INC. is not responsible for any damage or breakage of Goods inside the
      Shipment/s Boxes.
    </p>
    <p>
      c. If LONGARES GLOBAL OPERATIONS, INC. is unable to start or continue with carriage of
      the SHIPPER/CUSTOMER’s Shipment for a reason beyond its control, LONGARES GLOBAL
      OPERATIONS, INC. will not be in breach of its agreement with the SHIPPER/CUSTOMER but
      will take all steps that are reasonably practicable in the circumstances to commence or
      continue the carriage. Examples of events beyond LONGARES GLOBAL OPERATIONS, INC.’
      control are acts of terrorism, strikes, acts of God, power failures, damage or
      destruction of any network facilities or server, disruption to air or ground
      transportation due to bad weather, fire, flood, war, hostilities, civil disturbance,
      acts of government or other authorities (including, without limitation, customs officials)
      and labor disputes of obligations affecting LONGARES GLOBAL OPERATIONS, INC. or some other
      party and delays due to Covid-19 and other Pandemic.
    </p>
    <p>
      d. SHIPPER/CUSTOMER Indemnification: SHIPPER/CUSTOMER shall hold free and harmless,
      defend and indemnify LONGARES GLOBAL OPERATIONS, INC., its officers, agents and
      employees from any and all damages, liabilities, fines and penalties and other causes
      of action that may arise from non-compliance by SHIPPER/CUSTOMER of the representations,
      warranties, terms and conditions contained herein.
    </p>
    <br />

    <h3><strong>7. DELEGATION</strong></h3>
    <p>
      A Shipment may be carried via any intermediate stopping places that LONGARES GLOBAL
      OPERATIONS, INC. deems appropriate. LONGARES GLOBAL OPERATIONS, INC. may engage
      sub-contractors if and when necessary to perform services and contracts both on its own
      behalf and on behalf of its agents and sub-contractors, each of whom shall have the
      benefit of these Terms.
    </p>
    <br />

    <h3><strong>8. CLAIMS PROCEDURES</strong></h3>
    <p>
      All claims against LONGARES GLOBAL OPERATIONS, INC. must be made in writing as soon as
      reasonably practicable and in any event within 14 days of receipt in the case of damage
      (including partial loss of a Shipment), within 21 days in the case of delay, and in the
      case of loss, within 60 days of the Shipment being consigned with LONGARES GLOBAL
      OPERATIONS, INC. for carriage.
    </p>
    <br />

    <h3><strong>9. ENTIRE AGREMENT & SEVERABILITY</strong></h3>
    <p>
      It is the intention of LONGARES GLOBAL OPERATIONS, INC. that all the terms of the
      contract between it and the SHIPPER/CUSTOMER are contained in this document. If the
      SHIPPER/CUSTOMER wishes to rely on any variation to these terms, it must ensure that it
      is recorded in writing and signed by the SHIPPER/CUSTOMER and on behalf of LONGARES
      GLOBAL OPERATIONS, INC. before the shipment is accepted for carriage by LONGARES GLOBAL
      OPERATIONS, INC. If any part of these terms is not enforceable, this will not affect the
      enforceability of any other part.
    </p>
    <br />

    <h3><strong>10. GOVERNING LAW</strong></h3>
    <p>
      These terms shall be governed by the Customs laws of the United States of America and
      the state laws where the Shipment originated and this Shipment shall be subject to and
      governed by the Carriage of Goods by Sea Act of the United States of America; Section
      4281 and 4288 of the Revised Statute of the United States, as amended, and all applicable
      statutes of the United States, and pursuant to Philippine Bureau of Customs Memorandum
      Circular No 79-90 and its subsequent amendments.
    </p>
    <br />

    <h3><strong>11. LIEN ON GOODS SHIPPED</strong></h3>
    <p>
      LONGARES GLOBAL OPERATIONS, INC shall have a lien on any Goods shipped for all freight
      charges, customs duties, advances or other charges of any kind arising out of the
      transportation hereunder and may refuse to surrender the possession of the Goods until
      such charges are paid. Any expenses incurred by LONGARES GLOBAL OPERATIONS, INC. on
      behalf of SHIPPER/CUSTOMER including but not limited to taxes, interest, penalties,
      fines, surcharges, duties, etc. arising from non-declaration or mis-declaration shall be
      reimbursed or refunded by the SHIPPER/CUSTOMER upon submission by LONGARES GLOBAL
      OPERATIONS, INC of proper proof or evidence of payment.
    </p>
    <br />

    <h3><strong>12. DECLARATION</strong></h3>
    <p>
      a. SHIPPER/CUSTOMER declares and warrants that SHIPPER/CUSTOMER is the owner of the Goods
      contained in this Shipment and takes full responsibility of ownership of them, and must
      provide proper identification and/or documentation at the time of shipment.
      SHIPPER/CUSTOMER is the one who personally packed and sealed items inside the Shipment’s
      box, and fully certifies that the actual contents of the boxes are the same as the Goods
      listed in the PACKING LIST. SHIPPER/CUSTOMER certifies all items inside the Shipment’s
      box are not illegal, restricted, contraband and prohibited such as, but not limited to,
      guns, ammunitions, grenades, night vision scope, gun parts, military equipment including
      weapon slings, holsters, any weapon parts and other electronic military gadgets.
      SHIPPER/CUSTOMER Initials _______
    </p>
    <p>
      b. SHIPPER/CUSTOMER declares that should contraband, illegal and restricted items be
      found in the Shipment box, Dept. of Homeland Security will seize these contrabands in
      accordance with the U.S. Customs Laws. Violators will be fined and/or prosecuted
      accordingly. LONGARES GLOBAL OPERATIONS, INC. reserves the right to sue SHIPPER/CUSTOMER
      for any damages or loss to LONGARES GLOBAL OPERATIONS, INC. arising from prohibited
      items contained in a Shipment, as well as under the SHIPPER/CUSTOMER’s indemnity
      obligation in Section 6. The SHIPPER/CUSTOMER will be responsible for paying the total
      cost of inspection and damages that will be incurred in the course of examination and the
      cost of legal proceedings and delays. SHIPPER/CUSTOMER Initials _______
    </p>
    <p>
      c. SHIPPER/CUSTOMER declares that he/she has carefully read, understood, and agreed to
      the above terms by signing the front of this invoice and by affixing his/her initials on
      the said provisions. SHIPPER/CUSTOMER Initials _______
    </p>

  </div>
</div>
