import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-transaction-details',
  templateUrl: './view-transaction-details.component.html',
  styleUrls: ['./view-transaction-details.component.scss']
})
export class ViewTransactionDetailsComponent implements OnInit {
  paymentCash: any = {}
  paymentCheck: any = {}
  paymentCreditCard: any = {}
  paymentDebitCard: any = {}

  paymentAccNo!: string;
  currency = environment.currency;

  constructor(
    public dialogRef: MatDialogRef<ViewTransactionDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public item: any,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }
}
