import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControlOptions, FormControl } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { State } from '@popperjs/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map, find } from 'rxjs/operators';
import { City } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { FremanService } from 'src/app/services/freman.service';
import { IpService } from 'src/app/services/ip.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { PhoneUtil } from 'src/app/utils';
import { formatUsTelephoneNumber } from 'src/app/utils/formatter';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { FeeCalculatorService } from 'src/app/services/fee-calculator.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @Input() invoice: any;
  @Input() states: State[] = [];
  @Input() branchList: any[] = [];
  @Input() deliveryZoneList: any[] = [];
  @Input() subType: any;

  @Output() back = new EventEmitter();

  minDate = moment.tz().format('YYYY-MM');
  currUser: any;

  paymentTypes: any[] = [];
  cardTypes: any[] = environment.cardTypes;
  payments: any[] = [];

  form: FormGroup;
  isSubmitted: boolean = false;

  redirectURL: string = '';
  isSummary: boolean = false;
  addTip: boolean = false;

  amountForm: FormGroup;
  currency = environment.currency;
  filterStates$: Observable<State[]>;
  _state = [{}]

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private fremanService: FremanService,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private feeCalculatorService: FeeCalculatorService
  ) {
    this.currUser = this.authService.getUser();
  }

  get branchName() {
    var branch = _.find(this.branchList, { 'BranchCode': this.invoice.BranchCode });
    if (!_.isEmpty(branch)) {
      return branch.BranchName;
    } else {
      return '';
    }
  }

  get deliveryZone() {
    var branch = _.find(this.deliveryZoneList, { 'DeliveryZoneCode': this.invoice.DeliveryZoneCode });
    if (!_.isEmpty(branch)) {
      return branch.DeliveryZoneName;
    } else {
      return '';
    }
  }

  async ngOnInit() {
    let params = this.route.snapshot.queryParams;
    if (params['redirectURL']) {
      this.redirectURL = params['redirectURL'];
    }

    console.log(this.invoice);

    const timezone = moment.tz.guess();
    const userId = _.isEmpty(this.currUser) ? null : this.currUser.id;

    this.form = new FormGroup({
      UserId: new FormControl(userId),
      PaymentTypeCode: new FormControl('CC', Validators.required),
      AccountCardNo: new FormControl('', Validators.required),
      AccountCardCvv: new FormControl(''),
      AccountName: new FormControl(''),
      BankName: new FormControl(''),
      CardType: new FormControl('', Validators.required),
      ExpireDate: new FormControl('', Validators.required),
      BillingAddress: new FormControl('', Validators.required),
      BillingCity: new FormControl('', Validators.required),
      BillingState: new FormControl('', Validators.required),
      BillingCountry: new FormControl(this.invoice['BillingCountry']),
      PostalCode: new FormControl('', Validators.required)
    });

    this.amountForm = new FormGroup({
      TipAmount: new FormControl(0, [Validators.min(0), Validators.max(20)]),
      PaymentAmount: new FormControl(this.invoice['TotalAmount'], [Validators.required]),
      MerchantFee: new FormControl(0)
    })

    this.form.get('CardType').valueChanges.subscribe(val => {
      if (val) {
        var pattern = environment.cardRegex[val];
        this.form.get('AccountCardNo').setValidators([Validators.required, Validators.pattern(pattern)]);
      } else {
        this.form.get('AccountCardNo').setValidators([Validators.required]);
      }
      this.form.get('AccountCardNo').updateValueAndValidity();

      this.calculateTotal();
    });

    this.form.get('PaymentTypeCode').valueChanges.subscribe(val => {
      this.calculateTotal();
    });

    this.amountForm.get('TipAmount').valueChanges.subscribe(res => {
      this.calculateTotal();
    });

    /* this.loadPaymentTypes(); */
    this.loadUserPayments();
  }

  updatePaymentForm(item) {
    this.formControl.PaymentTypeCode.setValue(item.paymentTypeCode.trim());
    this.formControl.AccountCardNo.setValue(item.accountCardNo);
    this.formControl.AccountCardCvv.setValue(item.accountCardCvv);
    this.formControl.CardType.setValue(item.cardType);
    this.formControl.BillingCountry.setValue(item.billingCountry);
    this.formControl.BillingAddress.setValue(item.billingAddress);
    this.formControl.BillingState.setValue(item.billingState);
    this.formControl.BillingCity.setValue(item.billingCity);
    this.formControl.PostalCode.setValue(item.postalCode);
    this.formControl.ExpireDate.setValue(moment(item.expireDate).format('YYYY-MM'));

    this.payments.forEach(item => { item.selected = false; });
    item.selected = true;
  }

  /* async loadPaymentTypes() {
    this.dataService.getUserPaymentTypes().subscribe(res => {
      this.paymentTypes = res;
    }, error => {
      console.log(error);
    });
  } */

  async loadUserPayments() {
    this.dataService.getUserPayments().subscribe(res => {
      this.payments = res;
      if (this.payments.length > 0) {
        this.updatePaymentForm(this.payments[0]);
      }
    }, error => {
      console.log(error);
    });
  }

  async onSubmit() {
    this.isSubmitted = true;

    if (this.isSummary) {
      this.proceedPayment();
    } else {
      this.saveCard();
    }
  }

  calculateTotal() {
    var selCard = _.find(environment.cardTypes, { 'CardTypeCode': this.form.get('CardType').value });
    var tipAmount = Number(this.amountFormControl.TipAmount.value) > 0 ? this.amountFormControl.TipAmount.value : 0;
    var merchantFee = this.feeCalculatorService.calculateMerchantFee(this.invoice['TotalAmount'] + tipAmount, selCard, this.formControl.PaymentTypeCode.value, this.invoice['BillingCountry']);
    this.amountFormControl.MerchantFee.setValue(merchantFee);

    var total = tipAmount + this.invoice['TotalAmount'] + merchantFee;
    this.amountFormControl.PaymentAmount.setValue(total);
    this.amountFormControl.MerchantFee.setValue(merchantFee);
  }

  saveCard() {
    if (!this.form.valid) {
      this.toastr.warning('Please provide all required fields.');
      return false;
    } else {
      // if there is a authenticated user , save his/her card info. Else proceed to payment summary
      if (!_.isEmpty(this.currUser)) {
        this.spinner.show();
        this.dataService.addUserPayment(this.form.getRawValue()).subscribe(
          () => {
            this.spinner.hide();
            this.isSummary = true;
          },
          (error: any) => {
            console.log(error);
            this.toastr.error('Something went wrong. Please try again.');
            this.spinner.hide();
          }
        );
      } else {
        this.isSummary = true;
      }
    }
  }

  proceedPayment() {
    if (this.amountForm.valid) {
      this.spinner.show();

      this.dataService.cardPayment(this.formRequest).subscribe(
        (res) => {
          this.spinner.hide();
          if (res.success) {
            Swal.fire({
              icon: 'info',
              titleText: 'Information',
              text: 'Your payment has been successfully transmitted',
            }).then(res => {
              location.reload();
            })
          } else {
            Swal.fire({
              icon: 'error',
              titleText: 'Error',
              text: res.message,
            });
          }
        },
        (error: any) => {
          console.log(error);
          this.toastr.success('Something went wrong. Please try again.');
          this.spinner.hide();
        }
      );
    }
  }

  onBillingStateChange(state: any) {
    console.log("onBillingStateChange", state);
    /* if (state) {
      console.log("onBillingStateChange", state);
      this.initTypedSelectOptions();

      this.form.get('BillingState').setValue(state);
    } */
  }

  initTypedSelectOptions() {


    this.filterStates$ = this.form
      .get('BillingState')
      .valueChanges.pipe(
        startWith(''),
        map((value) => {
          if (value) {
            return typeof value === 'string' ? value : value.StateProvName;
          }
        }),
        map((name) =>
          name
            ? this.filterOptions(name, this.states, 'StateProvName')
            : this.states.slice()
        )
      );
  }

  displayBillingState(state: any): string {
    console.log("displayBillingState", state);

    const name = state && state.StateProvName ? state.StateProvName : '';
    return name.trim();
  }

  filterOptions(value: string, list: any[], name: string): any[] {
    const filterValue = value.toLowerCase();
    return list.filter((option) =>
      option[name].toLowerCase().includes(filterValue)
    );
  }

  backToHome() {
    if (this.isSummary) {
      this.isSummary = false;
    } else {
      this.back.emit();
    }
  }

  get formControl() {
    return this.form.controls;
  }

  get amountFormControl() {
    return this.amountForm.controls;
  }

  get formRequest() {
    return {
      ...this.amountForm.getRawValue(),
      CardCvv: this.formControl.AccountCardCvv.value,
      CardNumber: this.formControl.AccountCardNo.value,
      ExpirationDate: this.formControl.ExpireDate.value,
      PaymentType: this.formControl.PaymentTypeCode.value,
      CardType: this.formControl.CardType.value,
      CustomerFirstName: !_.isEmpty(this.currUser) ? this.currUser.firstName : this.invoice['CustomerFirstName'],
      CustomerLastName: !_.isEmpty(this.currUser) ? this.currUser.lastName : this.invoice['CustomerLastName'],
      Address: this.formControl.BillingAddress.value,
      ZipCode: this.formControl.PostalCode.value,
      City: this.formControl.BillingCity.value,
      invoiceItemDetailList: this.invoice.invoiceItemDetailList,
      InvoiceNo: this.invoice.InvoiceNo,
      SubType: this.subType
    }
  }
}
