<div class="overflow-hidden">
  <div class="sigma_banner">
    <div class="sigma_banner-slider">
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay banner-slider-slick"
        [style]="utilityService.setBgImage('carousel_tracker')"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">TRACK YOUR SHIPMENT</h1>
                <p class="text-white">
                  Know the status of your balikbayan box shipments.
                </p>

                <div class="row banner-links" style="margin-bottom: -6px">
                  <div class="col-md-12">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        name="tracking_number"
                        id="invoiceno"
                        placeholder="Enter your Tracking # / Invoice #"
                        [formControl]="invoiceFormControl"
                      />
                      <button class="sigma_btn" (click)="redirectTrackingPage()">TRACK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay banner-slider-slick"
        [style]="utilityService.setBgImage('carousel_balikbayan_box')"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">BALIKBAYAN BOX PADALA</h1>
                <p class="text-white">
                  Enjoy a secure and easy way of sending Balikbayan packages
                  anywhere in the Philippines with just one click!
                </p>

                <div class="banner-links d-flex align-items-center">
                  <a routerLink="/booking" class="sigma_btn light"
                    >Schedule A pick up</a
                  >
                  <a routerLink="/balikbayan-box" class="sigma_btn ml-4"
                    >Read more</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay banner-slider-slick"
        [style]="utilityService.setBgImage('carousel_money_remittance')"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">MONEY REMITTANCE</h1>
                <p class="text-white">
                  Check out our reliable remittance service with low fees
                  offered at great exchange rates. No hang-ups, no delays!
                </p>
                <div class="banner-links d-flex align-items-center">
                  <a href="https://axpremit.com" target="_blank" class="sigma_btn light"
                    >Get Started</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay banner-slider-slick"
        [style]="utilityService.setBgImage('carousel_logistics')"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">LOGISTICS</h1>
                <p class="text-white">
                  We uphold safe and up-to-par standards in its logistics line
                  throughout the Philippines.
                </p>
                <div class="banner-links d-flex align-items-center">
                  <a routerLink="/" class="sigma_btn light">Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay banner-slider-slick"
        [style]="utilityService.setBgImage('carousel_brokerage')"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">BROKERAGE</h1>
                <p class="text-white">
                  We provide a hassle-free and streamlined customs clearance
                  processing.
                </p>
                <div class="banner-links d-flex align-items-center">
                  <a routerLink="/" class="sigma_btn light">Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay banner-slider-slick"
        [style]="utilityService.setBgImage('carousel_ecommerce')"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">E-COMMERCE</h1>
                <p class="text-white">
                  We cover all kinds of products available
                  online anywhere in the world.
                </p>
                <div class="banner-links d-flex align-items-center">
                  <a href="https://logomegastore.com" target="_blank" class="sigma_btn light">Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img [src]="images['about_us_image']" alt="img" />
            </div>
            <div class="sigma_media m-0">
              <span>
                <b class="counter" data-from="0" data-to="20">20</b>
                Years of <br />
                Experience
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title">
              <span class="subtitle">About us</span>
              <h3 class="title m-0">LOGO</h3>
              <h1>Leading Balikbayan Box & Money Remittance Company</h1>
            </div>

            <div class="sigma_about-content">
              <p style="text-align: justify;">
                In 2001, Joel P. Longares founded a money remittance company. Over time, it evolved to provide balikbayan box services. Today, with 26 branches in total across Northern California, Nevada, Chicago, Washington, and Hawaii, LOGO has experienced significant growth. The company has also expanded its reach to Canada, establishing branches in Mississauga, Edmonton, Calgary, Scarborough, and Winnipeg, and has plans for further expansion. In addition to North America, LOGO has extended its balikbayan box services to Dubai, Norway, Denmark, and the United Kingdom, marking a global presence.
              </p>
              <p style="text-align: justify;">
                The company proudly stands as the leading provider of balikbayan boxes and money remittance services in the U.S. and Canada. Alongside these core services, LOGO also offers additional services, such as LOGO Megastore and LOGO Driver Express, to cater to the diverse needs of our valued customers. At LOGO, we are committed to providing exceptional and convenient services to our customers, ensuring their needs are met with the utmost efficiency and care.
              </p>
              <div class="row">
                <div class="col-md-6">
                  <div class="sigma_info style-15">
                    <div class="sigma_info-title">
                      <span class="sigma_info-icon bg-primary-1 text-white"
                        >1</span
                      >
                    </div>
                    <div class="sigma_info-description">
                      <h5>
                        <a routerLink="/">Quality Control System</a>
                      </h5>
                      <p>
                        At LOGO, we take pride in our exceptional quality control
                        system, providing our clients with the utmost confidence in
                        the safety and security of our services and package deliveries.
                        You can trust us to ensure a seamless and reliable experience
                        throughout the entire process.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="sigma_info style-15">
                    <div class="sigma_info-title">
                      <span class="sigma_info-icon bg-primary-1 text-white"
                        >2</span
                      >
                    </div>
                    <div class="sigma_info-description">
                      <h5>
                        <a routerLink="/">Highly Professional Staff</a>
                      </h5>
                      <p>
                        At LOGO, our employees undergo acomprehensive series
                        of professional trainings, fostering a well-rounded and
                        customer-centric approach to service. This dedication
                        ensures that our team is fully equipped to meet your needs
                        and deliver the highest level of satisfaction throughout
                        your experience with us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <iframe allowfullscreen
                    frameborder="0"
                    [title]="website_config.main_video.title"
                    [src]="website_config.main_video.url | resourceUrl"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    style="width: 100%; aspect-ratio: 16/9;"
                  ></iframe>
                  <div class="mt-5">
                    <div class="row">
                      <div class="col-md-auto">
                        <h1>LOGO Mobile available on</h1>
                        <div class="app-links">
                          <img src="../../../assets/img/app-store-logo-svg.svg" alt="google-play-image" (click)="downloadApp('ios')"><br />
                          <img src="../../../assets/img/google-play-badge.png" alt="google-play-image" (click)="downloadApp('android')">
                        </div>
                      </div>
                      <div class="col-md-auto">
                        <img src="../../../assets/img/axpmobile-qrcode.png" alt="axpmobile-qrcode" width="200">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="section section-padding bg-cover bg-center bg-white"
    style="background-image: url(assets/img/map-texture.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-6">
          <div class="section-title">
            <span class="subtitle">Our Services</span>
            <h3 class="title">Choose Among Our Best Offers</h3>
            <p>
              With years of expertise in the industry, LOGO continues
              to find the best solutions to connect people at different
              parts of the world.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 pb-4">
          <div class="sigma_service style-9 dark h-100">
            <span>01</span>
            <i class="flaticon-transportation"></i>
            <div class="sigma_service-body">
              <h5>
                <a routerLink="/balikbayan-box">Balikbayan Box</a>
              </h5>
              <p>
                Cross long-distance relationships via our Balikbayan Box
                shipping.
              </p>
              <div class="clearfix">
                <a routerLink="/balikbayan-box" class="btn-link float-end">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 pb-4">
          <div class="sigma_service style-9 dark h-100">
            <span>02</span>
            <i class="flaticon-warehouse"></i>
            <div class="sigma_service-body">
              <h5>
                <a href="https://axpremit.com" target="_blank">Money Remittance</a>
              </h5>
              <p>Experience fast, secure, and convenient money transfers.</p>
              <div class="clearfix">
                <a href="https://axpremit.com" target="_blank" class="btn-link float-end">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 pb-4">
          <div class="sigma_service style-9 dark h-100">
            <span>03</span>
            <i class="flaticon-location"></i>
            <div class="sigma_service-body">
              <h5>
                <a href="https://abxpinc.com" target="_blank">Logistics</a>
              </h5>
              <p>
                Tap into our nationwide transport services solutions from
                freight forwarding, logistics support and distribution.
              </p>
              <div class="clearfix">
                <a href="https://abxpinc.com" target="_blank" class="btn-link float-end">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 pb-4">
          <div class="sigma_service style-9 dark h-100">
            <span>04</span>
            <i class="flaticon-logistics-1"></i>
            <div class="sigma_service-body">
              <h5>
                <a href="https://abxpinc.com" target="_blank">Brokerage</a>
              </h5>
              <p>
                Avoid the hassles of customs clearance processing and let our
                expert agents do the work for you.​
              </p>
              <div class="clearfix">
                <a href="https://abxpinc.com" target="_blank" class="btn-link float-end">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 pb-4">
          <div class="sigma_service style-9 dark h-100">
            <span>05</span>
            <i class="flaticon-shopping-cart-2"></i>
            <div class="sigma_service-body">
              <h5>
                <a href="https://logomegastore.com" target="_blank">E-Commerce</a>
              </h5>
              <p>
                Take advantage of our one-stop-shop online store that caters
                high-quality products proudly made in the Philippines.
              </p>
              <div class="clearfix">
                <a href="https://logomegastore.com" target="_blank" class="btn-link float-end">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 pb-4">
          <div class="sigma_service style-9 dark h-100">
            <span>06</span>
            <i class="flaticon-truck"></i>
            <div class="sigma_service-body">
              <h5>
                <a routerLink="/">LOGO Driver</a>
              </h5>
              <p>
                Experience fast delivery of your parcels with LOGO Driver.
              </p>
              <div class="clearfix">
                <a routerLink="/" class="btn-link float-end">
                  Read More
                  <i class="far fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section section-padding p-0 pt-5">
    <div class="container">
      <div class="section-title centered">
        <span class="subtitle">Our Partners</span>
        <h3 class="title">
          We work with world-class partners to serve you better!
        </h3>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-6 py-2" *ngFor="let partner of partners">
          <a [href]="partner.url != null ? partners.url : ''">
            <img
              [src]="partner.image"
              [alt]="partner.name"
            />
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="section section-padding bg-cover bg-center bg-white"
    style="background-image: url(assets/img/map-texture.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img [src]="images['why_choose_us_image']" alt="img" />
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-6">
          <div class="section-title">
            <h3 class="title">Why Choose Us</h3>
            <p class="">
              With years of industry expertise, LOGO continues to find the
              best solutions to connect people from different parts of the world.
            </p>

            <div class="row">
              <div class="col-md-6">
                <div class="sigma_info style-15">
                  <div class="sigma_info-title">
                    <span class="sigma_info-icon bg-primary-1 text-white"
                      ><i class="fas fa-medal"></i
                    ></span>
                  </div>
                  <div class="sigma_info-description">
                    <h5 style="font-size: large">Trusted by millions</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="sigma_info style-15">
                  <div class="sigma_info-title">
                    <span class="sigma_info-icon bg-primary-1 text-white"
                      ><i class="far fa-shield"></i
                    ></span>
                  </div>
                  <div class="sigma_info-description">
                    <h5 style="font-size: large">24/7 Security</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="sigma_info style-15">
                  <div class="sigma_info-title">
                    <span class="sigma_info-icon bg-primary-1 text-white"
                      ><i class="fal fa-thumbs-up"></i
                    ></span>
                  </div>
                  <div class="sigma_info-description">
                    <h5 style="font-size: large">100% Guarantee</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="sigma_info style-15">
                  <div class="sigma_info-title">
                    <span class="sigma_info-icon bg-primary-1 text-white"
                      ><i class="fal fa-location"></i
                    ></span>
                  </div>
                  <div class="sigma_info-description">
                    <h5 style="font-size: large">Easy access locations</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="info-space"></div>

  <div class="section section-padding bg-secondary-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title">
            <span class="subtitle">Our Process</span>
            <h3 class="title text-white">Our Work Process</h3>
            <!-- <p class="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod.Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet consectetur adipisicing</p> -->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="sigma_info-image style-16">
            <img [src]="images['our_work_process_image']" alt="img" />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="sigma_info style-16">
            <div class="sigma_info-title">
              <span class="sigma_info-icon text-white">
                <i class="flaticon-box"></i>
                <span>1</span>
              </span>
              <h5 class="text-white">
                <a href="services.html#work-process" class="text-white"
                  >Pick Up and Drop Offer</a
                >
              </h5>
              <!-- <div class="sigma_info-description">
                    <p class="text-white">Our LOGO staff ensures excellent and high-quality customer service, treating all our clients with utmost courtesy and handling all shipped Balikbayan Boxes with care.</p>
                  </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="sigma_info style-16">
            <div class="sigma_info-title">
              <span class="sigma_info-icon text-white">
                <i class="flaticon-warehouse"></i>
                <span>2</span>
              </span>
              <h5 class="text-white">
                <a href="services.html#work-process" class="text-white">
                  Warehousing Operation</a
                >
              </h5>
              <!-- <div class="sigma_info-description">
                    <p class="text-white">Our shipping operations are powered with smart logistic software that tags and tracks their packages, with LOGO staff overseeing careful handling and loading of shipment for sea transport.</p>
                  </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="sigma_info style-16">
            <div class="sigma_info-title">
              <span class="sigma_info-icon text-white">
                <i class="flaticon-shipped"></i>
                <span>3</span>
              </span>
              <h5 class="text-white">
                <a href="services.html#work-process" class="text-white"
                  >Delivery</a
                >
              </h5>
              <!-- <div class="sigma_info-description">
                    <p class="text-white">LOGO staff in Manila ensures that all offloaded packages are promptly prepared for delivery via LOGO’s dedicated trucks designated for specific areas and regions in the country.</p>
                  </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="sigma_info style-16">
            <div class="sigma_info-title">
              <span class="sigma_info-icon text-white">
                <i
                  style="
                    background: url('assets/fonts/flaticon/support.svg')
                      no-repeat;
                    width: 36px;
                    height: 35px;
                  "
                  class="text-white"
                ></i>
                <span>4</span>
              </span>
              <h5>
                <a href="services.html#work-process" class="text-white"
                  >Customer Service</a
                >
              </h5>
              <!-- <div class="sigma_info-description">
                    <p class="text-white">LOGO’s diligent customer service personnel monitor all vehicle drivers handling delivery, and meticulously checks that all delivery processes are earnestly attended from pick up to delivery to customer feedback.</p>
                  </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section section-padding">
    <div class="container">
      <div class="section-title centered">
        <span class="subtitle">Real Time Tracking</span>
        <h3 class="title">Services For You</h3>
      </div>

      <div class="row">
        <div class="col-lg-7 mb-4">
          <div class="row">
            <div class="col-sm-6 mb-4">
              <div class="sigma_portfolio custom-style">
                <div class="sigma_portfolio-thumb">
                  <img [src]="images['services_for_you_1']" alt="portfolio" />
                  <div class="sigma_portfolio-content">
                    <h5>
                      <a routerLink="/">Highly Trained and Professional Team</a>
                    </h5>
                    <!-- <p>It is a long established fact that</p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-4">
              <div class="sigma_portfolio custom-style">
                <div class="sigma_portfolio-thumb">
                  <img [src]="images['services_for_you_2']" alt="portfolio" />
                  <div class="sigma_portfolio-content">
                    <h5>
                      <a routerLink="/">Software Developers and IT Technical Support</a>
                    </h5>
                    <!-- <p>It is a long established fact that</p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="sigma_portfolio custom-style">
                <div class="sigma_portfolio-thumb">
                  <img [src]="images['services_for_you_3']" alt="portfolio" />
                  <div class="sigma_portfolio-content">
                    <h5>
                      <a routerLink="/">Operates a customs brokerage firm for guaranteed fast releasing of shipments from abroad</a>
                    </h5>
                    <!-- <p>It is a long established fact that</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 mb-4">
          <div class="sigma_portfolio custom-style">
            <div class="sigma_portfolio-thumb">
              <img [src]="images['services_for_you_4']" alt="portfolio" />
              <div class="sigma_portfolio-content">
                <h5>
                  <a routerLink="/">24 hour Multi-Channel Customer Support Systems</a>
                </h5>
                <!-- <p>It is a long established fact that</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="text-center mt-4">
            <a routerLink="/" class="sigma_btn">

              View All

              <i class="far fa-angle-right"></i>



            </a>
          </div> -->
    </div>
  </div>

  <div class="section section-padding bg-gray">
    <img src="assets/img/illustration-1.png" alt="img" class="texture-1" />
    <div class="container">
      <div class="section-title">
        <span class="subtitle">Testimonials</span>
        <h3 class="title">Customers approve!</h3>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Christine Joy Magi</h5>
                  <span class="sigma_testimonial-category"
                    >Money Remittance Customer</span
                  >
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “Hindi ako nahihirapan magpadala online dahil inaassist ako ng
                  customer service.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Darwin Calara</h5>
                  <span class="sigma_testimonial-category">LOGO Mobile User</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “Maganda ang app madali gamitin. Dito na ako nagta-track at nag
                  babantay ng kahon ko kung kelan ang dating.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Josua Saldua</h5>
                  <span class="sigma_testimonial-category">LOGO Mobile User</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “Yes I do have your LOGO Mobile app. Wala naman naging problema,
                  nagagamit ko sa pagtrack ng kahon.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Julius Cotas</h5>
                  <span class="sigma_testimonial-category">LOGO Mobile User</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “May nag text sakin, tinuruan ako mag download ng LOGO Mobile app.”
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Cresta Ann Baker</h5>
                  <span class="sigma_testimonial-category"
                    >LOGO Customer</span
                  >
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “The new name is fine, as long as it keeps offering the best service.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Ann Evelyn Demerin</h5>
                  <span class="sigma_testimonial-category">LOGO Customer</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “It's okay if you change the name for expansion, as long as the team
                  and service stay the same.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Jose Porras</h5>
                  <span class="sigma_testimonial-category">LOGO Customer</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “We're okay with both the balikbayan box and money remittance being
                  under LOGO, Inc.”
                </p>
              </div>
            </div>
          </div>

          <div class="sigma_testimonial style-11">
            <div class="sigma_testimonial-body">
              <div class="d-flex align-items-center">
                <div class="sigma_author-block">
                  <h5>Anvalerie Titius</h5>
                  <span class="sigma_testimonial-category">LOGO Customer</span>
                </div>
              </div>
              <div class="d-block d-sm-flex mt-4">
                <span class="fas fa-quote-left sigma_testimonial-icon"></span>
                <p class="mt-sm-0 mb-0 mt-3">
                  “Komportable na kami sa inyo. Ka-LOGO na tayo!”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
