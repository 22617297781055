<app-spinner></app-spinner>

<!--Section Start-->
<div
  class="sigma_subheader style-6 bg-cover bg-center title-banner"
>
  <div class="container">
    <div class="sigma_subheader-inner">
      <h1>Blogs</h1>
    </div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="btn-link" routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Blogs</li>
    </ol>
  </div>
</div>
<!--Section End-->
<!--Section Start-->
<div class="section section-padding">
  <div class="container">
    <div class="row">
      <div class="col-sm-2">
        <button class="btn btn-outline-axp-theme rounded-pill w-100" routerLink="/blogs">
          <span class="fas fa-arrow-left fa-fw"></span>
          <span class="ms-2">Go Back</span>
        </button>
      </div>
      <div class="col-sm">
        <div class="blog" *ngIf="blog != null">
          <div class="blog-header">
            <div class="blog-title">{{ blog.title }}</div>
            <div class="blog-subtitle">Published <em style="cursor: pointer;" [matTooltip]="formatStamp(blog.dateCreated)">{{ humanReadableStamp(blog.dateCreated) }}</em> by <strong>{{ blog.authorName }}</strong></div>
          </div>
          <div class="blog-body">
            <quill-view-html [content]="blog.blogContent"></quill-view-html>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Section End-->
