import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dev-testing',
  templateUrl: './dev-testing.component.html',
  styleUrls: ['./dev-testing.component.scss']
})
export class DevTestingComponent implements OnInit {
  redirectCountdown: number = 5;
  redirectInterval: any = null;

  constructor() { }

  ngOnInit(): void { }

  redirectToDeeplink() {
    this.redirectInterval = setInterval(() => {
      if(this.redirectCountdown == 0) {
        clearInterval(this.redirectInterval);

        this.redirectInterval = null;

        window.location.href = 'https://axp.app.link://';
      } else {
        this.redirectCountdown -= 1;
      }
    }, 1000);
  }
}
