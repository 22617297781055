<!-- <div class="sigma_preloader sigma_preloader-gear">
  <div class="sigma_preloader-inner">
    <div class="preloader-gear">
      <div class="preloader-gear-inner">
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!--Section Start-->
<div
  class="remittance-banner sigma_subheader style-6 bg-cover bg-center"
  [style]="utilityService.setBgImage('banner_money_remittance')"
>
  <div class="container">
    <div class="sigma_subheader-inner">
      <h1 class="remittance-banner-title">Send money across the world<br>in the most convenient way</h1>
    </div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="btn-link" routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">LOGO Remit</li>
    </ol>
  </div>
</div>
<!--Section End-->
<!--Section Start-->
<div class="section sigma_post-details">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 on-top">
        <div class="sigma_post-details-inner">
          <div class="entry-content">
            <div class="sigma_post-details-categories">
              <a routerLink="/remittance-details" class="sigma_post-category">ABOUT LOGO REMIT</a>
            </div>
            <div class="section-title mb-0">
              <h2 class="title">WELCOME TO YOUR NEW WORLD OF EXCELLENT MONEY REMITTANCE SERVICE</h2>
            </div>
            <div class="sigma_post-details-meta">
              <!-- <span> <i class="far fa-eye"></i> 131 Views</span>
              <span> <i class="far fa-comments"></i> 2 Comments</span>
              <span> <i class="far fa-calendar"></i> April 12, 2021</span> -->
            </div>
            <h1 class="content-subheader">YOUR TRANSACTION – OUR SERVICE</h1>
            <p>
              Looking for ways to share with your loved ones the fruits of your labor soonest? Send money via a secure,
              fast, reasonably priced, and hassle-free remittance service. LOGO Remit provides a customer-centric way
              of doing money remittance through the powerful remittance processing system of UniTeller Inc. It is
              accessible practically through the tips of your fingers whenever you wish to remit money.
            </p>
            <h3 class="fw-normal mb-0">Secure</h3>
            <p>
              The safety of your money is ensured by carefully screened, reputable establishments participating in the
              execution of your payment instruction. LOGO Remit is committed to ensure that any nonpublic information
              you provide is handled in a careful and confidential manner.
            </p>
            <h3 class="fw-normal mb-0">Fast</h3>
            <p>
              On a regular basis, the proceeds of your remittance will be available to your designated payee within
              hours of your instruction, or on the next business day at the latest, once it has passed the account and
              transaction security review.
            </p>
            <!-- <a href="assets/img/posts/details/1.jpg" class="gallery-thumb">
              <img src="assets/img/posts/details/1.jpg" alt="post">
            </a> -->
            <h3 class="fw-normal mb-0">Reasonably priced</h3>
            <p>
              LOGO Remit provides a service with prevailing market fees, and at very competitive exchange rates if your
              remittance is payable in Philippine currency (PHP).
            </p>
            <h3 class="fw-normal mb-0">Convenient</h3>
            <p>
              You do not have to leave your cruise ship or go anywhere else to remit, but can give your payment
              instructions through a simple and well-structured Remittance Application Form available online. Required
              payments for your remittance will be withdrawn electronically from your ATM account, as instructed by you.
              Your Official Receipt (OR) will be available for printing immediately after executing the transaction.
            </p>
            <h3 class="fw-normal mb-0">Concern about final payment? </h3>
            <p>
              The status of your transaction can be monitored electronically using the unique Transaction Reference No.
              in your OR. You will have 24/7 access to LOGO Remit's call service center until payment of your remittance
              is resolved.
            </p>
            <br>
            <h1 class="content-subheader">HOW WILL YOUR PAYEE RECEIVE YOUR REMITTANCE?</h1>
            <p>
              Choose how the proceeds of your remittance will be paid:
            </p>
            <div class="sigma_general-list style-2">
              <ul>
                <li>
                  <i class="far fa-check"></i>
                  <span>DIRECT CREDIT</span>
                </li>
                <li>
                  <i class="far fa-check"></i>
                  <span>CASH PICK-UP</span>
                </li>
                <li>
                  <i class="far fa-check"></i>
                  <span>CASH DELIVERY</span>
                </li>
                <li>
                  <i class="far fa-check"></i>
                  <span>GCASH</span>
                </li>
              </ul>
            </div>
            <ul class="p-0">
              <li>
                <strong>Direct Credit</strong>
                <ul class="ul-disc-list">
                  <li>Transfer funds directly to bank accounts in the Philippines quickly and easily by providing the account name, Account number, and currency denomination (US DOLLARS)</li>
                  <li>Credits are typically processed within hours, whether the recipient's account is with major banks like Banco De Oro, MetroBank, PNB, RCBC, or smaller banks in the country.</li>
                </ul>
              </li>
              <li>
                <strong>Cash Pick-Up</strong>
                <ul class="ul-disc-list">
                  <li>Collect cash at branches of participating payment agents like Banco De Oro, MetroBank, SM Foreign Exchange, M. Lhuillier, and similar establishments.</li>
                  <li>Remitters receive guidance on where and how the remittance can be claimed.</li>
                </ul>
              </li>
              <li>
                <strong>Cash Delivery</strong>
                <ul class="ul-disc-list">
                  <li>Remittance is delivered to the payee's residence or another designated location.</li>
                  <li>Takes approximately 24 hours in major city centers or around 48 hours in remote locations.</li>
                </ul>
              </li>
              <li>
                <strong>GCash</strong>
                <ul class="ul-disc-list">
                  <li>Send remittance directly to the payee's GCash account.</li>
                  <li>Provide the recipient's full name and GCash mobile number for a seamless transfer.</li>
                  <li>GCash is a popular e-wallet service in the Philippines, enabling users to receive and transfer funds electronically.</li>
                  <li>Recipients can access funds conveniently through their GCash app.</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- Comments End -->
        </div>
      </div>
      <!-- Sidebar Start -->
      <div class="col-lg-4">
        <div class="sidebar">
          <div id="remittance-lady">
            <img src="assets/img/remittance-lady.png">
          </div>
          <!-- Cargo Widget -->
          <div class="widget widget-categories">
            <h5 class="widget-title">Categories</h5>
            <ul>
              <li>
                <a routerLink="/balikbayan-box">
                  Balikbayan Box
                </a>
              </li>
              <li>
                <a routerLink="/remittance-details">
                  Money Remittance
                </a>
              </li>
              <li>
                <a routerLink="/">
                  Logistics

                </a>
              </li>
              <li>
                <a routerLink="/">
                  Brokerage

                </a>
              </li>
              <li>
                <a routerLink="/">
                  E-Commerce

                </a>
              </li>
            </ul>
          </div>

          <!-- Recent Post Widget -->
          <!-- <div class="widget widget-sigma-recent-posts style-4">
            <h5 class="widget-title">Recent Comments</h5>
            <div class="sigma_recent-post align-items-start">
              <a routerLink="/" class="recent-post-image">
                <i class="fal fa-comment-alt-dots"></i>
              </a>
              <div class="recent-post-descr">
                <h6>
                  <a routerLink="/"><span>Tim Abell On</span> Five Places to visit in Oakland with Fantastic Ngrito </a>
                </h6>
              </div>
            </div>
            <div class="sigma_recent-post align-items-start">
              <a routerLink="/" class="recent-post-image">
                <i class="fal fa-comment-alt-dots"></i>
              </a>
              <div class="recent-post-descr">
                <h6>
                  <a routerLink="/"><span>Tim Abell On</span> Five Places to visit in Oakland with Fantastic Ngrito </a>
                </h6>
              </div>
            </div>
            <div class="sigma_recent-post align-items-start">
              <a routerLink="/" class="recent-post-image">
                <i class="fal fa-comment-alt-dots"></i>
              </a>
              <div class="recent-post-descr">
                <h6>
                  <a routerLink="/"><span>Tim Abell On</span> Five Places to visit in Oakland with Fantastic Ngrito </a>
                </h6>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- Sidebar End -->
    </div>
  </div>
</div>
<!--Section End-->
<!-- Section Start -->
<div class="partners-section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 order-2 order-lg-1 px-4 mb-4">
        <h2 class="partners-section-title">Pay-out Locations</h2>
        <div class="grid grid-col-3">
          <img src="assets/img/clients/mlhuiller.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/cebuana.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/palawan.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/rdpawnshop.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/tambunting.jpg" alt="clients"class="btn-block">
        </div>
      </div>
      <div class="col-lg-8 order-1 order-lg-2 px-4 mb-4">
        <h2 class="partners-section-title">Account Credits</h2>
        <div class="grid grid-col-5 grid-col-sm-2">
          <img src="assets/img/clients/bdo.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/bpi.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/landbank.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/pnb.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/psbank.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/metrobank.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/rcbc.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/securitybank.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/eastwest.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/chinabank.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/unionbank.jpg" alt="clients"class="btn-block">
          <img src="assets/img/clients/robinson.jpg" alt="clients"class="btn-block">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Section End -->
