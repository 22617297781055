import { Injectable } from '@angular/core';
import { JsonReaderService } from './json-reader.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(
    private jsonReaderService: JsonReaderService
  ) { }

  loadWebsiteConfigJson() {
    this.jsonReaderService.readJsonFile('website_config')
      .subscribe((result: any) => {
        localStorage.setItem('website_config', JSON.stringify(result));
      }, (error: any) => {
        localStorage.setItem('website_config', null);
      });
  }

  loadImagesJson() {
    this.jsonReaderService.readJsonFile('images')
      .subscribe((result: any) => {
        localStorage.setItem('res_images', JSON.stringify(result));
      }, (error: any) => {
        localStorage.setItem('res_images', null);
      });
  }

  loadPartnersJson() {
    this.jsonReaderService.readJsonFile('partners')
      .subscribe((result: any) => {
        localStorage.setItem('res_partners', JSON.stringify(result.partners));
      }, (error: any) => {
        localStorage.setItem('res_partners', null);
      });
  }

  setBgImage(key: string) {
    const images = JSON.parse(localStorage.getItem('res_images'));

    if(images == null || images[key] == null) {
      return;
    }

    return `background-image: url('${images[key]}');`;
  }
}
