import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DataService } from './data.service';
import { Credential, User } from '../models';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const API_URL = environment.apiUrl;
const helper = new JwtHelperService();
export const TOKEN_KEY = 'jwt-token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject = new BehaviorSubject(null);
  public user: Observable<User>;

  constructor(
    private dataService: DataService,
    private http: HttpClient
  ) {
    this.loadStoredToken();
  }

  loadStoredToken() {
    const token = localStorage.getItem(TOKEN_KEY);
    let decodedToken = helper.decodeToken(token);
    this.userSubject.next(decodedToken);
    this.user = this.userSubject;
  }

  login(credentials: Credential) {
    credentials.platform = 'web';
    return this.dataService.login(credentials).pipe(
      map(token => {
        localStorage.setItem(TOKEN_KEY, token);
        let decodedToken = helper.decodeToken(token);
        this.userSubject.next(decodedToken);
        return token;
      }));
  }

  logout() {
    localStorage.clear();
    this.userSubject.next(null);
  }

  register(userDetails: User) {
    return this.dataService.register(userDetails).pipe(
      map(token => {
        localStorage.setItem(TOKEN_KEY, token);
        let decodedToken = helper.decodeToken(token);
        this.userSubject.next(decodedToken);
        return token;
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${API_URL}/account/forgot-password`, { email }, { responseType: 'text' });
  }

  resetPassword(encryptedId: string, encryptedVerificationCode: string, password: string): Observable<any> {
    return this.http.post(`${API_URL}/account/reset-password`, { encryptedId, encryptedVerificationCode, password }, { responseType: 'text' });
  }

  getUser(): User {
    return this.userSubject.getValue();
  }
}
