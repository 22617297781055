<app-spinner></app-spinner>

<!--Section Start-->
<aside class="sigma_aside sigma_aside-desktop">
  <div class="sigma_close aside-trigger-right">
    <span></span>
    <span></span>
  </div>
  <div class="sidebar">
    <!-- Latest Update Widget -->
    <div
      class="widget widget-newsletter"
      style="margin-top: 5px !important; padding: 5px 30px 5px 30px !important"
    >
      <h5 class="widget-title">Latest Updates</h5>
      <div class="widget widget-sigma-recent-posts">
        <div class="sigma_recent-post">
          <a href="blog-details.html" class="recent-post-image">
            <img src="assets/img/posts/sm/1.jpg" alt="img" />
            <span>12</span>
          </a>
          <div class="recent-post-descr">
            <h6>
              <a href="blog-details.html"
                >Every Next Level Of Your Life Will Demand</a
              >
            </h6>
            <a href="blog-details.html" class="date">
              <i class="far fa-clock mr-2"></i>
              June 4, 2021
            </a>
          </div>
        </div>
        <div class="sigma_recent-post">
          <a href="blog-details.html" class="recent-post-image">
            <img src="assets/img/posts/sm/2.jpg" alt="img" />
            <span>9</span>
          </a>
          <div class="recent-post-descr">
            <h6>
              <a href="blog-details.html"
                >This is a test to try out how things work</a
              >
            </h6>
            <a href="blog-details.html" class="date">
              <i class="far fa-clock mr-2"></i>
              January 4, 2021
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Subscribe Widget -->
    <div
      class="widget widget-newsletter"
      style="margin-top: 25px !important; padding: 5px 30px 5px 30px !important"
    >
      <h5 class="widget-title">Get Connected</h5>
      <p>Follow us for the latest updates, news and promos.</p>
      <form method="post">
        <input type="email" name="email" placeholder="Enter your email" />
        <button type="button" class="btn-block mt-4">Subscribe</button>
      </form>
    </div>
  </div>
</aside>

<div class="sigma_aside-overlay aside-trigger-right"></div>

<aside class="sigma_aside">
  <div class="sigma_close aside-trigger">
    <span></span>
    <span></span>
  </div>
  <div class="sigma_logo-wrapper">
    <a class="sigma_logo" routerLink="/home">
      <img [src]="images.website_logo" alt="logo" />
    </a>
  </div>
  <ul class="navbar-nav">
    <li class="menu-item">
      <a routerLink="/home">Home</a>
    </li>
    <li class="menu-item">
      <a routerLink="/branches">Branches</a>
    </li>
    <li class="menu-item">
      <a routerLink="/tracking">Tracking</a>
    </li>
    <li class="menu-item">
      <a href="https://axpremit.com" target="_blank">Online Remittance</a>
    </li>
    <li class="menu-item">
      <a routerLink="/services">Services</a>
    </li>
    <li class="menu-item">
      <a routerLink="/blogs">Blogs</a>
    </li>
    <li class="menu-item">
      <a routerLink="/about-us">About Us</a>
    </li>
    <li class="menu-item">
      <a routerLink="/contact-us">Contact Us</a>
    </li>
  </ul>
</aside>

<div class="sigma_aside-overlay aside-trigger"></div>

<header class="sigma_header style-5 can-sticky">
  <div class="sigma_header-top d-none d-md-block">
    <div class="container">
      <div class="sigma_header-top-inner">
        <div class="sigma_header-top-links">
          <ul class="sigma_header-top-nav">
            <li>
              <a routerLink="/">
                <i class="fal fa-envelope"></i>
                cs@logocargo.com
              </a>
            </li>
            <li>
              <a routerLink="/">
                <i class="fal fa-map-marker-alt"></i>
                4950 Fulton Dr, Unit F, Fairfield, CA 94534
              </a>
            </li>
          </ul>
        </div>
        <div class="sigma_header-top-contacts">
          <ul class="sigma_header-top-nav">
            <li>
              <img
                class="img-fluid"
                [src]="countryFlagLink"
                alt=""
                style="height: 18px"
              />
            </li>
            <li>
              <a
                class="social-links"
                (click)="openSocialLink('https://www.facebook.com/LOGOinc.USA')"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a
                class="social-links"
                (click)="openSocialLink('https://www.instagram.com/mylogoinc')"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
            <li>
              <a
                class="social-links"
                (click)="
                  openSocialLink('https://www.youtube.com/@LOGOTVKabayan')
                "
                ><i class="fab fa-youtube"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="sigma_header-middle">
    <div class="container">
      <div class="navbar">
        <div class="sigma_logo-wrapper">
          <a class="sigma_logo" routerLink="/home">
            <img [src]="images.website_logo" alt="logo" />
          </a>
        </div>
        <div class="d-none d-lg-flex align-items-center">
          <div class="sigma_header-top-links important-links">
            <ul class="sigma_header-top-nav ps-3">
              <li>
                <a routerLink="/contact-us">
                  <i class="fal fa-map-marker-alt"></i>
                  <div>
                    <p>US Main Office</p>
                    <p>
                      <strong
                        >4950 Fulton Dr, Unit F, Fairfield, CA 94534</strong
                      >
                    </p>
                    <p>Call us at <strong>+1 (800) 883-1998</strong></p>
                  </div>
                </a>
              </li>
              <li>
                <a routerLink="/contact-us">
                  <i class="fal fa-map-marker-alt"></i>
                  <div>
                    <p>Canada Main Office</p>
                    <p>
                      <strong>800 Boyer Blvd., Mississauga, ON L5V 2Y1</strong>
                    </p>
                    <p>Call us at <strong>+1 (888) 721-5298</strong></p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="sigma_header-controls style-2 d-block d-lg-none"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          data-bs-target="#navbarSupportedContent"
        >
          <ul class="sigma_header-controls-inner p-0 m-0">
            <li class="aside-toggle aside-trigger">
              <span></span>
              <span></span>
              <span></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="sigma_header-bottom">
    <div class="container">
      <div class="navbar">
        <ul class="navbar-nav">
          <li class="menu-item menu-item-has-children">
            <a routerLink="/home">Home</a>
          </li>
          <!-- <li class="menu-item" dropdown>
            <a dropdownToggle class="dropdown-toggle">Balikbayan Box</a>
            <ul *dropdownMenu class="dropdown-menu position-absolute">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </li> -->
          <li class="menu-item menu-item-has-children">
            <a>Balikbayan Box</a>
            <ul class="sub-menu nav">
              <li class="nav-item w-100">
                <a class="nav-link" routerLink="/booking"
                  >Schedule for Pick-up / Drop-off</a
                >
              </li>
              <li class="nav-item w-100">
                <a class="nav-link" routerLink="/tracking"
                  >Track your Package</a
                >
              </li>
              <li class="nav-item w-100">
                <a class="nav-link" routerLink="/balikbayan-box"
                  >About our Balikbayan Box Service</a
                >
              </li>
            </ul>
          </li>
          <li class="menu-item menu-item-has-children">
            <a>Money Remittance</a>
            <ul class="sub-menu nav">
              <li class="nav-item w-100">
                <a
                  class="nav-link"
                  href="https://www.axpremit.com"
                  target="_blank"
                  >Online Remittance</a
                >
              </li>
              <li class="nav-item w-100">
                <a class="nav-link" routerLink="/remittance-details"
                  >About our Money Remittance Service</a
                >
              </li>
            </ul>
          </li>
          <li class="menu-item">
            <a routerLink="/branches">Branches</a>
          </li>
          <li class="menu-item">
            <a routerLink="/services">Services</a>
          </li>
          <li class="menu-item">
            <a routerLink="/blogs">Blogs</a>
          </li>
          <li class="menu-item">
            <a routerLink="/about-us">About us</a>
          </li>
          <li class="menu-item">
            <a routerLink="/contact-us">Contact us</a>
          </li>
          <li class="menu-item" *ngIf="currUser | async; else elseTemplate">
            <a (click)="logout()" routerLink="#">Logout</a>
          </li>
          <li class="menu-item btn-profile" *ngIf="currUser | async">
            <a
              >{{ (currUser | async)?.firstName }}
              {{ (currUser | async)?.lastName }}</a
            >
          </li>
          <li class="menu-item" *ngIf="currUser | async">
            <a routerLink="/transaction-history">
              <i class="fa fa-box-open"></i>
            </a>
          </li>
          <ng-template #elseTemplate>
            <li class="menu-item">
              <a routerLink="/login">Login</a>
            </li>
            <li class="menu-item">
              <a routerLink="/register">Register</a>
            </li>
          </ng-template>
        </ul>

        <div class="sigma_header-controls style-2 d-lg-none">
          <ul class="sigma_header-controls-inner">
            <li class="aside-toggle aside-trigger-right desktop-toggler">
              <span></span>
              <span></span>
              <span></span>
            </li>
            <li class="aside-toggle aside-trigger">
              <span></span>
              <span></span>
              <span></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>

<nav class="navbar navbar-expand-lg navbar-light bg-light d-lg-none">
  <div class="container">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle class="nav-link dropdown-toggle">Balikbayan Box</a>
          <div *dropdownMenu class="dropdown-menu">
            <a class="dropdown-item" routerLink="/booking"
              >Schedule for Pick-up / Drop-off</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/tracking"
              >Track your Package</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/balikbayan-box"
              >About our Balikbayan Box Service</a
            >
          </div>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle class="nav-link dropdown-toggle"
            >Money Remittance</a
          >
          <div *dropdownMenu class="dropdown-menu">
            <a
              class="dropdown-item"
              href="https://www.axpremit.com"
              target="_blank"
              >Online Remittance</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/remittance-details"
              >About our Money Remittance Service</a
            >
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/branches">Branches</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/services">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/blogs">Blogs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/about-us">About us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact-us">Contact us</a>
        </li>
        <li class="nav-item" *ngIf="!isAuthBtnShown">
          <a class="nav-link" (click)="logout()">Logout</a>
        </li>
        <li class="nav-item" *ngIf="isAuthBtnShown">
          <a class="nav-link" routerLink="/login">Login</a>
        </li>
        <li class="nav-item" *ngIf="isAuthBtnShown">
          <a class="nav-link" routerLink="/register">Register</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="search-form-wrapper">
  <div class="search-trigger sigma_close">
    <span></span>
    <span></span>
  </div>
  <form class="search-form" method="post">
    <input type="text" placeholder="Search..." value="" />
    <button type="submit" class="search-btn">
      <i class="fal fa-search m-0"></i>
    </button>
  </form>
</div>

<div class="app-container">
  <router-outlet></router-outlet>
</div>

<footer class="sigma_footer style-5 sigma_footer-dark has-newsletter">
  <div class="sigma_footer-middle">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="sigma_footer-widget">
            <div class="sigma_footer-logo mb-4">
              <div
                class="p-1"
                style="
                  background-color: #fff;
                  border-radius: 0.25rem;
                  width: 250px;
                "
              >
                <img
                  [src]="images.website_logo"
                  alt="logo"
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9">
                <p class="mb-0">
                  At last! Your one-stop shop for all your Balikbayan needs!
                </p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <h3 class="text-white">LOGO Mobile available on</h3>
                <div class="app-links mb-3">
                  <img
                    src="../../../assets/img/app-store-logo-svg.svg"
                    alt="Download LOGO Mobile in App Store"
                    (click)="downloadApp('ios')"
                  />
                  <img
                    src="../../../assets/img/google-play-badge.png"
                    alt="Download LOGO Mobile in Google Play"
                    (click)="downloadApp('android')"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <img
                  src="../../../assets/img/axpmobile-qrcode-plain.png"
                  alt="LOGO Mobile QR"
                  width="200"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-sm-12">
          <div class="sigma_footer-widget">
            <h5 class="widget-title">Quick Links</h5>
            <div class="d-none d-lg-block">
              <div class="row">
                <div class="col-lg-6 mb-3">
                  <ul class="sigma_footer-links">
                    <li>
                      <a routerLink="/balikbayan-box">Balikbayan Box</a>
                    </li>
                    <li>
                      <a routerLink="/remittance-details">Money Remittance</a>
                    </li>
                    <li>
                      <a routerLink="/branches">Branches</a>
                    </li>
                    <li>
                      <a routerLink="/services">Services</a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 mb-3">
                  <ul class="sigma_footer-links">
                    <li>
                      <a routerLink="/blogs">Blogs</a>
                    </li>
                    <li>
                      <a routerLink="/about-us">About Us</a>
                    </li>
                    <li>
                      <a routerLink="/contact-us">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="d-block d-lg-none">
              <ul class="sigma_footer-links">
                <li>
                  <a routerLink="/balikbayan-box">Balikbayan Box</a>
                </li>
                <li>
                  <a routerLink="/remittance-details">Money Remittance</a>
                </li>
                <li>
                  <a routerLink="/branches">Branches</a>
                </li>
                <li>
                  <a routerLink="/services">Services</a>
                </li>
                <li>
                  <a routerLink="/blogs">Blogs</a>
                </li>
                <li>
                  <a routerLink="/about-us">About Us</a>
                </li>
                <li>
                  <a routerLink="/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-12 mb-3">
          <div class="widget widget-version mb-4">
            <h5 class="widget-title text-white">Website Version</h5>
            <p class="px-3">v{{ version }}</p>
          </div>
          <div class="widget widget-newsletter">
            <h5 class="widget-title text-white">Join Newsletter</h5>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                formControlName="email"
              />
              <div
                *ngIf="
                  formControl.email.invalid &&
                  (formControl.email.dirty ||
                    formControl.email.touched ||
                    isSubmitted)
                "
              >
                <span
                  class="text-danger"
                  *ngIf="formControl.email.errors?.required"
                  >Email is required.</span
                >
                <span
                  class="text-danger"
                  *ngIf="formControl.email.errors?.email"
                  >Email is invalid.</span
                >
              </div>
              <div class="text-end mt-2">
                <button type="submit" class="btn btn-axp-theme">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12">
          <div class="sigma_info-wrapper">
            <div class="sigma_info style-18">
              <div class="sigma_info-title">
                <span class="sigma_info-icon">
                  <i class="fal fa-box-open"></i>
                </span>
              </div>
              <div class="sigma_info-description">
                <p>4950 Fulton Dr, Unit F</p>
                <p>Fairfield, CA 94534</p>
              </div>
            </div>
            <div class="sigma_info style-18">
              <div class="sigma_info-title">
                <span class="sigma_info-icon">
                  <i class="fal fa-at"></i>
                </span>
              </div>
              <div class="sigma_info-description">
                <p><strong>Inquiries</strong></p>
                <p>cs@logocargo.com</p>
              </div>
            </div>
            <div class="sigma_info style-18">
              <div class="sigma_info-title">
                <span class="sigma_info-icon">
                  <i class="fal fa-mobile"></i>
                </span>
              </div>
              <div class="sigma_info-description">
                <div class="row">
                  <div class="col-md-7">
                    <p><strong>Customer Service 24/7</strong></p>
                    {{ telephone }} (US) <br />
                    {{ telephone_canada }} (Canada) <br />
                    {{ telephone_uk }} (UK) <br /><br />
                  </div>
                  <div class="col-md-5">
                    <p><strong>Manila Office</strong></p>
                    {{ mobileSmart }} <br />
                    {{ mobileSmart2 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="sigma_footer-bottom">
      <ul class="sigma_footer-links">
        <li>
          <a routerLink="/privacy-policy">Privacy Policy</a>
        </li>
        <li>
          <a routerLink="/terms-and-conditions">Terms and Conditions</a>
        </li>
        <li>
          <a routerLink="/terms-and-conditions-shipment"
            >Terms and Conditions of Carriage</a
          >
        </li>
        <li>
          <a routerLink="/">Sitemap</a>
        </li>
        <li>
          <a routerLink="/">Help</a>
        </li>
      </ul>
      <div class="sigma_footer-copyright mt-3">
        <p class="mb-0">Copyright © 2021 Longares Global Operations, Inc.</p>
      </div>
    </div>
  </div>
</footer>
