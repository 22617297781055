import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerComponent } from './spinner/spinner.component';
import { PhoneFormatPipe } from '../pipes/phone-format.pipe';
import { AccountNumberMaskPipe } from '../pipes/account-number-pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { PaymentComponent } from '../booking/payment/payment.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NumberDirective } from '../directives/number-only.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatAutocompleteModule
  ],
  declarations: [SpinnerComponent, PhoneFormatPipe, AccountNumberMaskPipe, PaymentComponent, NumberDirective],
  exports: [SpinnerComponent, PhoneFormatPipe, AccountNumberMaskPipe, MatTabsModule, PaymentComponent, NumberDirective]
})
export class SharedModule { }
