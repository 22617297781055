<div class="overflow-hidden">
    <div class="sigma_subheader style-6 bg-cover bg-center title-banner">
      <div class="container">
        <div class="sigma_subheader-inner">
          <h1>Terms and Conditions of Carriage</h1>
        </div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="btn-link" routerLink="/">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Terms and Conditions
          </li>
        </ol>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <h2><strong>LOGO TERMS AND CONDITIONS OF CARRIAGE</strong></h2>
      <p>
        The Sender/Consignor (“Sender”) hereby ships goods or packages and engages LOGO under the following terms and conditions (“Terms”).
      </p>

      <div class="indent">
        <p>
          <strong>1.</strong> Scope of Services. Unless any special services are agreed, the service to be provided by LOGO is limited to the collection, transportation and delivery of the goods packaged in individual boxes (Hereinafter referred to as “Balikbayan Boxes” or “shipments”). The Sender/Consignor acknowledges that shipments will be consolidated with those of other senders for transport.
        </p>

        <p>
          <strong>2.</strong> Sender/Consignor’s Declarations & Attestations:
        </p>
        <div class="indent">
          <p>
            <strong>2.1.</strong> Sender declares that Sender is the owner of the items contained in the “Balikbayan Boxes” and takes full responsibility of ownership of them.
          </p>
          <p>
            <strong>2.2.</strong> Sender is the one who personally packed and sealed items inside the Balikbayan Boxes, and fully certifies that the actual contents of the boxes are the same as the goods listed in the Packing Lists. Sender certifies all items inside the Balikbayan boxes are not illegal, restricted, contraband and prohibited such as, but not limited to, guns, ammunitions, grenades, night vision scope, gun parts, military equipment including weapon slings, holster any weapon parts and other electronic military gadgets.
          </p>
          <p>
            <strong>2.2.</strong> Should contraband, illegal and restricted items be found in Sender’s boxes, Dept. of Homeland Security will seize these contrabands in accordance with United States Code Title 19, 1594 and Title 22, 401. Violators will be fined and/or prosecuted accordingly. LOGO also reserves the right to sue Sender to the fullest extent of the law. The Sender will be responsible for paying the total cost of inspection and damages that will be incurred in the course of examination and the cost of legal proceedings and delays.
          </p>
          <p>
            <strong>2.3.</strong> Should contraband, illegal and restricted items be found in Sender’s boxes, Dept. of Homeland Security will seize these contrabands in accordance with United States Code Title 19, 1594 and Title 22, 401. Violators will be fined and/or prosecuted accordingly. LOGO also reserves the right to sue Sender to the fullest extent of the law. The Sender will be responsible for paying the total cost of inspection and damages that will be incurred in the course of examination and the cost of legal proceedings and delays.
          </p>
          <p>
            <strong>2.4.</strong> Sender declares that he/she carefully read, understood and agreed to the above terms by signing the front of this invoice and by affixing his/her initials on the said provisions.
          </p>
        </div>

        <p>
          <strong>3.</strong> Conditions of Carriage
        </p>
        <div class="indent">
          <p>
            <strong>3.1</strong> Service Restrictions and Conditions
          </p>
          <div class="indent">
            <p>
              <strong>a.</strong> The packages must not contain any illegal, restricted, contraband, car spare parts (known as “Chop- chop”) or otherwise prohibited items by and in the countries of origin and destination of cargo. The must not contain water, petrol products and other liquid products that may damage consolidated Balikbayan Boxes of other Senders.
            </p>
            <p>
              <strong>b.</strong> The packages must not contain any of the following: Goods more than 24 pieces of each kind within the Balikbayan Boxes and/or has value exceeding $500 or any goods violating Philippine law CMO 79-90. Firearms, ammunitions and explosives, including spare parts thereof, prohibited drugs including but not limited to marijuana, cocaine, barbituates, heroin, etc. and prescription drugs (prescription attached); absinthe (alcohol) alcoholic beverages, automobiles, dismantled auto parts; biological; ceramic tableware; cultural artifacts and pottery; defense article or items with military or proliferation applications; dog and cat fur; drug paraphernalia, fish and wildlife, food products such as cheese, meat, fruits and vegetables, hazardous materials, game and hunting trophies, gold; Haitian animal hide drums, meats, livestock and poultry; merchandise from embargoed countries; pets, plants and seeds; soil; textile (roll). Used clothing of commercial quantity (Ukay-ukay in bales); articles that infringe intellectual property rights of their owners, pornographic materials, fluids and perishable food items.
            </p>
          </div>

          <p>
            <strong>3.2.</strong> Refusal and Suspension of Carriage
          </p>
          <div class="indent">
            <p>
              <strong>a.</strong> If it comes to the attention of LOGO that any package does not meet any of the above restrictions or conditions, LOGO may refuse to transport the pertinent package (or any shipment of which it is a part) and, if carriage is in progress, LOGO may suspend carriage and hold the package or shipment.
            </p>
            <p>
              <strong>b.</strong> LOGO may also suspend carriage if it cannot effect delivery at the third attempt, if the receiver refuses to accept delivery, or if it is unable to effect delivery because of an incorrect address after having used all reasonable means to find the correct address.
            </p>
            <p>
              <strong>c.</strong> Where LOGO is entitled to suspend carriage of a package or shipment it is also entitled to return it to the Sender at its discretion.
            </p>
            <p>
              <strong>d.</strong> The Sender will be responsible for the reasonable cost and expenses of LOGO (including storage) for such losses, taxes and customs, duties as LOGO may suffer. For claims made against LOGO because a package does not comply with any of the restrictions, conditions or representations above or because of any refusal or suspension of carriage or return of a package or shipment Sender shall hold free and harmless and indemnify LOGO, its officers, agents and employees from any and all damages, liabilities, fines and penalties and other causes of action that may arise from such shipment. In the case of the return of package or shipment, the Sender will also be responsible for paying all applicable charges calculated in accordance with the prevailing rates of LOGO
            </p>
            <p>
              <strong>e.</strong> If the Sender tenders to LOGO a package which fails to comply with any of the restrictions or conditions above, LOGO will not assume any economic loss arising which the Sender may suffer in connection with the Carriage by LOGO of such package (regardless of whether that failure to comply has caused or contributed to the loss and notwithstanding any negligence on the part of LOGO or its employees, contractors or representative), and if LOGO does suspend carriage for a reason allowed by these Terms, the Sender shall not be entitled to any refund on the carriage charges it has paid and LOGO may bring a claim in respect of such non-compliance.
            </p>
            <p>
              <strong>f.</strong> If having suspended carriage of a package or shipment in accordance with these provision LOGO is unable within a reasonable time to obtain the Sender’s instruction on disposition of the package or shipment or to identify the Sender or any other person entitled to the goods (having if necessary opened the package), LOGO shall be entitled to destroy or sell the package or shipment at its absolute charges, cost or expenses (including interest) outstanding in respect of the package or shipment or otherwise from the Sender concerned. Any balance shall be held to the Senders’ order.
            </p>
            <p>
              <strong>g.</strong> LOGO reserves the right, but is not obliged, to scan by means of X-ray, any package tendered to it for transportation at any time.
            </p>
            <p>
              <strong>h.</strong> LOGO reserves the right to withhold delivery of boxes due to payment issues such as, but not limited to non-payment in full or in part, returned checks or declined credit cards
            </p>
          </div>
        </div>

        <p>
          <strong>4.</strong> Limits of LOGO Liabilities
        </p>
        <div class="indent">
          <p>
            <strong>a.</strong> Sender fully understands and accepts that the limit of liability assumed and payable by LOGO, their agents, employees, is only in the maximum amount of $200.00 per Balikbayan Box or cargo
          </p>
          <p>
            <strong>b.</strong> Sender fully understands and accepts that Sender is fully responsible for packing and sealing of boxes. For highly breakable items, Sender fully acknowledges that proper handling and packing was made and that LOGO is not responsible for any damage or breakage of items inside the Balikbayan Boxes.
          </p>
          <p>
            <strong>c.</strong> If LOGO is unable to start or continue with carriage of the Senders package for a reason beyond its control, LOGO will not be in breach of its agreement with the Sender but will take all steps that are reasonably practicable in the circumstances to commence or continue the carriage. Examples of events beyond LOGO’ control are acts of terrorism, strikes, acts of God, power failures, damage or destruction of any network facilities or server, disruption to air or around transportation due to bad weather, fire, flood, war, hostilities, civil disturbance, acts of government or other authorities (including, without limitation, customs) and labor disputes of obligations affecting LOGO or some other party and delays due to Covid-19 and other Pandemic.
          </p>
        </div>

        <p>
          <strong>5.</strong> Delegation. A shipment may be carried via any intermediate stopping places that LOGO deems appropriate. LOGO may engage sub-contractors if and when necessary to perform services and contracts both on its own behalf and on behalf of its agents and sub-contractors each of whom shall have the benefit of these Terms.
        </p>
        <p>
          <strong>6.</strong> Claims Procedures. All claims against LOGO must be made in writing as soon as reasonably practicable and in any event within 14 days of receipt in the case of damage (including partial loss of a shipment), within 21 days in the case of delay, and in the case of loss, within 60 days of the goods being consigned with LOGO for carriage. In addition, all claims against LOGO in connection with any shipment shall be prescribed and barred by expiration of time, unless legal proceedings are brought and written notice of them is given to LOGO
        </p>
        <p>
          <strong>7.</strong> Entire Agreement & Severability. It is the intention of LOGO that all the terms of the contract between it and the Sender are contained in this document. If the Sender wishes to rely on any variation to these terms, it must ensure that that is recorded in writing and signed by the Sender and on behalf of LOGO before the shipment is accepted for carriage by LOGO If any part of these terms is not enforceable, this will not affect the enforceability of any other part.
        </p>
        <p>
          <strong>8.</strong> Governing Law. These terms shall be governed by the laws of the United States of America and the prevailing state laws. This shipment shall be subject to and governed by the Carriage of Goods by Sea Act of the United States of America; Section 4281 and 4288 of the Revised Statute of the United States, as amended, and all applicable statutes of the United States, and pursuant to Philippine Bureau of Customs Memorandum Circular No 79-90 and its subsequent  amendments.
        </p>
        <p>
          <strong>9.</strong> Attorney Fees. In any litigation, arbitration, or other proceeding by which one party either seeks to enforce its rights under this Agreement (whether in contract, tort, or both) or seeks a declaration of any rights or obligations under this Agreement, the prevailing party shall be awarded its reasonable attorney fees, and costs and expenses incurred.
        </p>
      </div>
      <br><br>

      <!-- LOGO Miranda -->
      <h2><strong>Important Notice to all LOGO Balikbayan Box Sender</strong></h2>
      <p>
        Please make sure that the following guidelines are strictly followed:
      </p>

      <ul>
        <li>Sender is the owner and takes full responsibility of the Balikbayan boxes. Ask for Sender’s ID or Driver License.</li>
        <li>Sender is the one who personally packed and sealed items inside the Balikbayan Boxes, and fully certifies that the actual contents of the boxes are the same as the goods listed in the Packing Lists.</li>
        <li>Sender certifies that all items inside the Balikbayan boxes are not illegal, restricted, contraband and prohibited such as guns, ammunitions, grenades, night vision scope, gun parts, military equipment including weapon slings, holsters and any weapon parts and other electronic military gadgets.</li>
        <li>Should contraband, illegal, and restricted items be found in their boxes, Dept. of Homeland Security will seize these contrabands in accordance with United States Code Title 19, 1594 and Title 22, 401. Violators will be fined and/or prosecuted accordingly. LOGO also reserves the right to sue Sender to the fullest extent of the law. The Sender will be responsible for paying the total cost of inspection and damages that will be incurred in the course of examination and the cost of legal proceedings, delays, and complaints.</li>
        <li>Sender is aware that there might be considerable delays in the delivery of boxes due to the effect of Covid-19 Pandemic.</li>
        <li>Sender is aware that LOGO is not responsible for any delays beyond control of LOGO such as Port Congestion, Container Availability, Missed Interconnecting Port, and other delays because of Pandemic.</li>
        <li>Sender states that he/she read, understood, and agreed to the terms and condition by signing the front of the invoice and by affixing his/her initials on said provisions.</li>
      </ul>
    </div>
  </div>
