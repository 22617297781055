import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrackItem } from 'src/app/models';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-balikbayan-box',
  templateUrl: './balikbayan-box.component.html',
  styleUrls: ['./balikbayan-box.component.css']
})
export class BalikbayanBoxComponent implements OnInit {

  invoiceFormControl: FormControl;
  searchResult: TrackItem = null;
  errorMsg: string = '';

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.invoiceFormControl = new FormControl('');
  }

  async trackInvoice(): Promise<any> {
    try {
      this.spinner.show();

      this.errorMsg = '';
      this.searchResult = null;

      let result = await this.dataService
        .trackInvoice(this.invoiceFormControl.value)
        .toPromise();

      const items = result.Items;

      // Sort delivered on the top
      const hasDelivered = items.some((i: any) => i.status.includes('Delivered'));
      let results = items;
      if (hasDelivered) {
        results = this.array_move(items, 0, 1);
      }

      result.Items = results;
      this.searchResult = result;
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.searchResult = null;
      this.errorMsg = 'We are unable to find the invoice number you provided. Please make sure you have the correct number or you can call our help desk support at Tel. No. (707) 561-8091';
    }
  }

  get hasConsigneeDetails() {
    if (this.searchResult.ConsigneeName && this.searchResult.DateDelivered && this.searchResult.NoOfBoxes) {
      return true;
    }

    return false;
  }

  array_move(arr: any[], old_index: number, new_index: number) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }
}
