<!--Section Start-->
<div class="sigma_subheader style-6 bg-cover bg-center title-banner">
  <div class="container">
    <div class="sigma_subheader-inner">
      <h1>Branches</h1>
    </div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="btn-link" routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Branches</li>
    </ol>
  </div>
</div>
<!--Section End-->

<!--Section Start-->
<div class="section">
  <div class="container">
    <nav class="nav nav-pills nav-fill theme-axp">
      <button
        type="button"
        class="nav-link"
        *ngFor="let filter of filters"
        [ngClass]="[(filter == selectedBranch ? 'active' : '')]"
        (click)="filterBranchByLocation(filter)"
      >{{ filter }}</button>
    </nav>
    <div class="row mt-4">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6" *ngFor="let item of filteredBranches">
        <div class="sigma_portfolio style-4">
          <div class="sigma_portfolio-thumb">
            <img [src]="item.image" alt="portfolio">
            <div class="sigma_portfolio-content">
              <div class="sigma_portfolio-categories">
                <a routerLink="/" class="sigma_portfolio-category">{{ item.location }}</a>
              </div>
              <h5 class="m-0"><a routerLink="/">{{ item.name }}</a></h5>
              <div class="sigma_address_info">
                <div class="row" *ngFor="let detail of item.additional_details">
                  <div class="col-lg-12" *ngIf="detail.key">
                    <span [innerHTML]="detail.key"></span>
                    <span class="ms-1" [innerHTML]="detail.value" *ngIf="detail.value"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Section End-->
