import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class JsonReaderService {
  constructor(
    private http: HttpClient
  ) { }

  readJsonFile(filename: string) {
    return this.http.get(`/assets/data/${filename}.json`, {});
  }
}
