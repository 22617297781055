import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { CmsService } from 'src/app/services/cms.service';

const timezone = moment.tz.guess();

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogPostComponent implements OnInit, OnDestroy {
  blog: any = null;
  id: any = null;

  constructor(
    private meta: Meta,
    private title: Title,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private cmsService: CmsService
  ) { }

  ngOnInit(): void {
    this.id = atob(this.route.snapshot.params.id);

    if(this.id != null) {
      this.loadBlog();
    }
  }

  ngOnDestroy(): void {
    this.resetMetaTags();
  }

  formatStamp(timestamp) {
    return moment(timestamp).tz(timezone).format('MMMM D, YYYY hh:mm A');
  }

  humanReadableStamp(timestamp) {
    return moment(timestamp).fromNow();
  }

  encryptText(text) {
    return btoa(text);
  }

  updateMetaTags() {
    this.title.setTitle('LOGO - Blog | ' + this.blog.title);

    this.meta.updateTag({ name: 'author', content: this.blog.authorName });

    if(this.blog.keywords != null && this.blog.keywords != '') {
      this.meta.updateTag({ name: 'keywords', content: this.blog.keywords });
    }

    /*
    * OpenGraph Meta Tags
    */
    this.meta.updateTag({ name: 'og:type', content: 'article' });
    this.meta.updateTag({ name: 'og:title', content: 'LOGO - Blog | ' + this.blog.title });

    if(this.blog.first_paragraph != '') {
      this.meta.updateTag({ name: 'og:description', content: this.blog.first_paragraph });
    }

    if(this.blog.first_image != '') {
      this.meta.updateTag({ name: 'og:image', content: this.blog.first_image });
    }

    /*
    * Twitter Meta Tags
    */
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:title', content: 'LOGO - Blog | ' + this.blog.title });

    if(this.blog.first_paragraph != '') {
      this.meta.updateTag({ name: 'twitter:description', content: this.blog.first_paragraph });
    }

    /*
    * Article Meta Tags
    */
    this.meta.addTag({ name: 'article:published_time', content: this.blog.datePublished });
    this.meta.addTag({ name: 'article:author', content: this.blog.authorName });

    if(this.blog.keywords != null && this.blog.keywords != '') {
      this.meta.addTag({ name: 'article:tag', content: this.blog.keywords });
    }
  }

  resetMetaTags() {
    this.meta.updateTag({ name: 'author', content: 'LOGO Developers' });
    this.meta.updateTag({ name: 'keywords', content: 'AXP, AXP Balikbayan Box, AXP Remit, AXP Customer, LOGO, LOGO Balikbayan Box, LOGO Cargo, LOGO Remit, LOGO Customer, Express Padala, Padala, Balikbayan Box, Money Remittance' });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'og:title', content: 'LOGO' });
    this.meta.updateTag({ name: 'og:description', content: 'Remittance and Balikbayan Box' });
    this.meta.updateTag({ name: 'og:image', content: 'https://us.logocargo.com/assets/img/website-logo.png' });
    this.meta.updateTag({ name: 'twitter:card', content: 'LOGO' });
    this.meta.updateTag({ name: 'twitter:title', content: 'LOGO' });
    this.meta.updateTag({ name: 'twitter:description', content: 'LOGO' });
    this.meta.removeTag('name="article:published_time"');
    this.meta.removeTag('name="article:author"');
    this.meta.removeTag('name="article:tag"');
  }

  loadBlog() {
    this.spinner.show();

    this.cmsService.getPublishedBlog(this.id).subscribe((result: any) => {
      this.spinner.hide();

      this.blog = result;

      let doc = new DOMParser().parseFromString(this.blog.blogContent, 'text/html');
      let first_image = '';
      let first_paragraph = '';
      let max_paragraph_display = 200;

      doc.querySelectorAll('img').forEach((el, i) => {
        if(i == 0) {
          first_image = el.src;
        }
      });

      doc.querySelectorAll('p').forEach((el) => {
        let el_content = el.textContent.trim();

        if(first_paragraph == '' && el_content != '' && el_content.length > 0) {
          first_paragraph = el_content;
        }
      });

      this.blog.first_image = first_image.length > 0 ? first_image : '';
      this.blog.first_paragraph = first_paragraph.length > max_paragraph_display ? first_paragraph.substring(0, max_paragraph_display) + '...' : first_paragraph;

      this.updateMetaTags();
    }, (error) => {
      this.spinner.hide();

      this.blog = null;
    });
  }
}
