import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-remittance-details',
  templateUrl: './remittance-details.component.html',
  styleUrls: ['./remittance-details.component.css']
})
export class RemittanceDetailsComponent implements OnInit {
  images: any = JSON.parse(localStorage.getItem('res_images'));

  constructor(
    public utilityService: UtilityService
  ) { }

  ngOnInit(): void { }
}
