import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonReaderService } from 'src/app/services/json-reader.service';

interface Branch {
  branchLocation: string;
  branchName: string;
  image: string;
  address: string;
  schedule: string;
  contactNumber: string;
  email?: string;
}

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css'],
})
export class BranchesComponent implements OnInit {
  filters = [
    'All',
    'Northern California',
    'Southern California',
    'Chicago',
    'Hawaii',
    'Nevada',
    'Texas',
    'Washington',
    'Other States',
    'Canada',
    'Philippines'
  ];
  branches: any = [];

  selectedBranch: any = null;
  filteredBranches: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private jsonReaderService: JsonReaderService
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Branches | LOGO');
    this.meta.updateTag({
      name: 'description',
      content: 'LOGO - Branches',
    });

    this.filteredBranches = [];

    this.jsonReaderService.readJsonFile('branches').subscribe((result: any) => {
      this.branches = result.branches;

      if(result.branches.length > 0) {
        this.filterBranchByLocation('All');
      }
    }, (error: any) => {
      this.branches = [];
    });
  }

  filterBranchByLocation(branchLocation: string) {
    this.selectedBranch = branchLocation;

    this.updateRouteParam({
      branch: this.selectedBranch
    });

    if (branchLocation === 'All') {
      this.filteredBranches = this.branches;
    } else {
      this.filteredBranches = this.branches.filter(
        (item) => item.location === branchLocation
      );
    }
  }

  updateRouteParam(params: any) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params
    });
  }
}
