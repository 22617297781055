import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manuals',
  templateUrl: './manuals.component.html',
  styleUrls: ['./manuals.component.scss']
})
export class ManualsComponent implements OnInit {
  slug: string = null;
  imgSrc: string = null;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    let slug = this.route.snapshot.params.slug;

    if(slug != null) {
      this.imgSrc = `/assets/manuals/${slug}.jpg`;
    }
  }
}
