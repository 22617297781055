import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoicePdfService } from 'src/app/services/invoice-pdf.service';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';
import { FremanService } from 'src/app/services/freman.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  invoiceNumber = "";
  filename = "";
  doc: any;
  blob: any;

  message = "Please wait while downloading...";

  constructor(private invoicePdf: InvoicePdfService,
    private route: ActivatedRoute,
    private fremanService: FremanService) { }

  async ngOnInit(): Promise<void> {
    this.invoiceNumber = atob(this.route.snapshot.params['id']);

    try {
      let invoices = [];

      invoices.push(await this.fremanService.loadInvoice(this.invoiceNumber).toPromise());

      this.filename = this.invoiceNumber + '-invoice-receipt.pdf';

      invoices.forEach((item1: any, index: any) => {
        let temp_boxes = [];
        let temp_tally_boxes = {};

        invoices[index].AllBoxesTotalPrice = 0;

        item1.invoiceItemDetailList.forEach((item2: any) => {
          let is_exist = false;
          let current_index = null;

          temp_boxes.forEach((temp_item: any, temp_index: any) => {
            if (temp_item.BoxSizeCode == item2.BoxSizeCode) {
              current_index = temp_index;
              is_exist = true;
            }
          });

          if (!is_exist) {
            temp_boxes.push({
              GroupQty: item2.Qty,
              ...item2
            });
            temp_tally_boxes[item2.BoxSizeCode] = item2.Qty;
          } else {
            temp_boxes[current_index].GroupQty += 1;
            temp_tally_boxes[item2.BoxSizeCode] += 1;
          }

          invoices[index].HasCash = false;
          invoices[index].HasCheck = false;
          invoices[index].HasCreditCard = false;
          invoices[index].HasAccountsReceivable = false;
          invoices[index].HasPaidAtBranch = false;

          invoices[index].TotalPaymentCash = 0;
          invoices[index].TotalPaymentCheck = 0;
          invoices[index].TotalPaymentCreditCard = 0;
          invoices[index].TotalPaymentAccountsReceivable = 0;
          invoices[index].TotalPaymentPaidAtBranch = 0;

          invoices[index].invoicePaymentDetailList.forEach((item: any) => {
            if (item.PaymentType.trim() == 'CASH') {
              invoices[index].HasCash = true;
              invoices[index].TotalPaymentCash += item.PaymentAmount;
            }

            if (item.PaymentType.trim() == 'CHECK') {
              invoices[index].HasCheck = true;
              invoices[index].TotalPaymentCheck += item.PaymentAmount;
              invoices[index].invoicePaymentCheck = item.CheckNo;
            }

            if (item.PaymentType.trim() == 'CC') {
              invoices[index].HasCreditCard = true;
              invoices[index].TotalPaymentCreditCard += item.PaymentAmount;
              invoices[index].invoicePaymentCreditCard = item.CreditCardNumber.substr(-4);
            }

            if (item.PaymentType.trim() == 'AR') {
              invoices[index].HasAccountsReceivable = true;
              invoices[index].TotalPaymentAccountsReceivable += item.PaymentAmount;
            }

            if (item.PaymentType.trim() == 'PAB') {
              invoices[index].HasPaidAtBranch = true;
              invoices[index].TotalPaymentPaidAtBranch += item.PaymentAmount;
            }
          });

          invoices[index].groupedInvoiceItemDetailList = temp_boxes;
          invoices[index].invoiceBoxTally = temp_tally_boxes;
          invoices[index].AllBoxesTotalPrice += (item2.Qty * item2.UnitPrice);
        });
      });

      invoices = invoices;

      this.doc = await this.invoicePdf.generateJSPDF(invoices);
      this.blob = new Blob([this.doc.output('blob')], {
        type: 'application/pdf'
      });

      this.savePDF();
      this.message = "Downloaded successfully";

    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'There is a problem retrieving the invoices please refresh the page.', 'error');
    }
  }

  savePDF() {
    FileSaver.saveAs(this.blob, this.filename);
  }

}
