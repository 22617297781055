import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.fremanUrl;

@Injectable({
  providedIn: 'root'
})
export class FremanService {

  constructor(private http: HttpClient) { }

  activateCustomer(param): Observable<any> {
    return this.http.post(`${API_URL}/customer/activate`, param);
  }

  getInvoiceDetails(): Observable<any> {
    return this.http.get(`${API_URL}/v1/component/invoice-detail`);
  }

  getCountries(): Observable<any> {
    return this.http.get(`${API_URL}/v1/country/list`);
  }

  getStatesByCountryId(countryId: string): Observable<any> {
    return this.http.get(`${API_URL}/v1/stateprov?countryId=${countryId}`);
  }

  getCitiesByStateId(stateId: string): Observable<any> {
    return this.http.get(`${API_URL}/citiesByStateId/${stateId}`);
  }

  getCityByCityCode(cityCode: string): Observable<any> {
    return this.http.get(`${API_URL}/cities/${cityCode}`);
  }

  getCitiesByCityName(stateProvCode: string, cityName: string): Observable<any> {
    return this.http.get(`${API_URL}/cities?stateProvCode=${stateProvCode}&cityName=${cityName}`);
  }

  getInvoiceDetailCities(provCode: string): Observable<any> {
    return this.http.get(`${API_URL}/v1/component/invoice-detail/cities?ProvCode=${provCode}`);
  }

  getInvoiceDetailBrgy(cityCode: string): Observable<any> {
    return this.http.get(`${API_URL}/v1/component/invoice-detail/brgy?CityCode=${cityCode}`);
  }

  getDeliveryZoneList(): Observable<any> {
    return this.http.get(`${API_URL}/deliveryzone/list`);
  }

  ListAllBoxSizebyBranch(branchcode: string, zonecode: string, serviceTypeCode: string): Observable<any> {
    return this.http.get(`${API_URL}/branchboxsize/listbybranch?branchcode=${branchcode}&zonecode=${zonecode}&servicetypecode=${serviceTypeCode}`);
  }

  getBranchesByCountryId(countryId: string): Observable<any> {
    return this.http.get(`${API_URL}/v1/branch/getbycountry?countryId=${countryId}`);
  }

  generateInvoiceId(): Observable<any> {
    return this.http.get(`${API_URL}/invoice/generateid`);
  }

  getPickupTimeRangeList(): Observable<any> {
    return this.http.get(`${API_URL}/pickup/timerange`);
  }

  getBranchByZipCode(zipCode: string, countryId: string): Observable<any> {
    return this.http.get(`${API_URL}/v1/zip-branch?zipCode=${zipCode}&countryId=${countryId}`);
  }

  getBranchById(branchCode: string): Observable<any> {
    return this.http.get(`${API_URL}/Branch/validate/${branchCode}`);
  }

  getSmsInvoiceDetails(invoiceNo): Observable<any> {
    return this.http.get(`${API_URL}/sms/invoice/${invoiceNo}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'AgentId': 'none' })
    });
  }

  getProvZone(provCode): Observable<any> {
    return this.http.get(`${API_URL}/v1/ph-prov-zone/${provCode}`);
  }

  getCityZone(cityCode): Observable<any> {
    return this.http.get(`${API_URL}/v1/zone/city?cityCode=${cityCode}`);
  }

  getBrgyZone(brgyCode): Observable<any> {
    return this.http.get(`${API_URL}/v1/zone/brgy?brgyCode=${brgyCode}`);
  }

  getBranchByZip(zipCode): Observable<any> {
    return this.http.get(`${API_URL}/v1/branch/search/by-zipcode/${zipCode}`);
  }

  loadInvoice(invno: string): Observable<any> {
    return this.http.get(API_URL + '/v1/print/invoice/load/country?invoiceno=' + invno);
  }
}
