import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deeplink',
  templateUrl: './deeplink.component.html',
  styleUrls: ['./deeplink.component.scss']
})
export class DeeplinkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var vm = this;

    vm.downloadApp(vm.getMobileOperatingSystem());
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "ios";
    }
  }

  downloadApp(device: string): void {
    if (device === 'android') {
      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.location.href = 'market://details?id=com.axp.customer';
      } else {
        window.location.href = environment.appStoreLink[device];
      }
    } else if (device === 'ios') {
      if (navigator.userAgent.toLowerCase().indexOf('ios') > -1) {
        window.location.href = 'market://details?id=com.axp.customer';
      } else {
        window.location.href = environment.appStoreLink[device];
      }
    }
  }
}
