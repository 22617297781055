<app-spinner></app-spinner>

<!--Section Start-->
<div
  class="sigma_subheader style-6 bg-cover bg-center title-banner"
>
  <div class="container">
    <div class="sigma_subheader-inner">
      <h1>Blogs</h1>
    </div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="btn-link" routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Blogs</li>
    </ol>
  </div>
</div>
<!--Section End-->
<!--Section Start-->
<div class="section section-padding">
  <div class="container">
    <div class="row row-cols-1 row-cols-md-3 g-4" *ngIf="blogs.length > 0">
      <div class="col" *ngFor="let blog of blogs">
        <a class="card blog-card h-100" routerLink="/blogs/{{ encryptText(blog.id) }}">
          <img src="{{ blog.first_image }}" class="card-img-top" *ngIf="blog.first_image != ''">
          <div class="card-img-top" *ngIf="blog.first_image == ''">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
              <div class="text-muted">No Image Available</div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-2">
              <div class="card-title">{{ blog.title }}</div>
              <div class="card-subtitle">Published <em style="cursor: pointer;" [matTooltip]="formatStamp(blog.dateCreated)">{{ humanReadableStamp(blog.dateCreated) }}</em> by <strong>{{ blog.authorName }}</strong></div>
            </div>
            <p class="card-text">{{ blog.first_paragraph }}</p>
          </div>
          <div class="card-footer text-end">
            <span class="text-muted">Read More</span>
            <span class="text-muted fas fa-arrow-right fa-fw ms-2"></span>
          </div>
        </a>
      </div>
    </div>
    <div *ngIf="blogs.length == 0">
      <div class="card">
        <div class="card-body">
          <div class="text-center">No blogs at the moment.</div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="shouldShowCMS">
      <hr>
      <div class="text-end">
        <a routerLink="/cms/home" class="btn btn-axp-theme">
          Go to CMS &nbsp;
          <i class="fas fa-arrow-right"></i>
        </a>
      </div>
    </ng-container>
  </div>
</div>
<!--Section End-->
