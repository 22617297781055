<div class="container">
  <div class="row">
    <div class="col-md-12 contents">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="mb-4 text-center">
            <h2><strong>Payment</strong></h2>
          </div>
          <ng-container *ngIf="!isSummary;else summary">
            <ng-container *ngIf="payments.length > 0">

              <div class="row">
                <div class="col-sm-4">
                  <h3>Use Previous Payments</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-4" *ngFor="let item of payments" style="cursor: pointer;">
                  <span class="card-container">
                    <i [ngClass]="(item.selected)?'fas fa-check-circle icon selected':'fas fa-circle icon'"></i>
                    <div class="card p-2" (click)="updatePaymentForm(item)" style="width: 100%;">
                      <img src="../../../assets/img/payment/{{item.cardType}}.png" class="card-type-img" />
                      {{item.paymentType}}<br>
                      {{item.accountCardNo | accountNumberMask}}<br>
                      {{item.expireDate|date:'MM/YYYY'}}
                    </div>
                  </span>
                </div>
              </div>
              <br>
            </ng-container>
            <hr />
            <form [formGroup]="form" novalidate>
              <!-- <div class="form-group row" *ngIf="this.invoice['BillingCountry'] === 'CA'">
                <div class="col-4">
                  <label for="country" class="form-label">Payment Type <span class="text-danger">*</span></label>
                  <select formControlName="PaymentTypeCode">
                    <option value="">Select Payment Type</option>
                    <option value="DC">Debit Card</option>
                    <option value="CC">Credit Card</option>
                  </select>
                  <div *ngIf="
                        formControl.PaymentTypeCode.invalid &&
                        (formControl.PaymentTypeCode.dirty ||
                          formControl.PaymentTypeCode.touched ||
                          isSubmitted)
                      ">
                    <span class="text-danger" *ngIf="formControl.PaymentTypeCode.errors?.required">Payment Type is
                      required.</span>
                  </div>
                </div>

              </div> -->
              <div class="form-group row">
                <div class="col-4">
                  <label for="country" class="form-label">Card Type <span class="text-danger">*</span></label>
                  <select formControlName="CardType">
                    <option value="">Select Card Type</option>
                    <option *ngFor="let item of cardTypes" [value]="item.CardTypeCode">
                      {{ item.CardType }}
                    </option>
                  </select>
                  <div *ngIf="
                        formControl.CardType.invalid &&
                        (formControl.CardType.dirty ||
                          formControl.CardType.touched ||
                          isSubmitted)
                      ">
                    <span class="text-danger" *ngIf="formControl.CardType.errors?.required">Card Type is
                      required.</span>
                  </div>
                </div>
                <div class="col-4">
                  <label class="form-label">Card Number <span class="text-danger">*</span></label>
                  <input type="text" formControlName="AccountCardNo" />
                  <div *ngIf="
                        formControl.AccountCardNo.invalid &&
                        (formControl.AccountCardNo.dirty ||
                          formControl.AccountCardNo.touched ||
                          isSubmitted)
                      ">
                    <span class="text-danger" *ngIf="formControl.AccountCardNo.errors?.required">Account Number is
                      required.</span>
                    <span class="text-danger" *ngIf="formControl.AccountCardNo.errors?.pattern">Account Number is
                      invalid.</span>
                  </div>
                </div>
                <div class="col-4">
                  <label for="cvv" class="form-label">Cvv </label>
                  <input type="text" id="cvv" formControlName="AccountCardCvv" />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-4">
                  <label class="form-label">Expired Date <span class="text-danger">*</span></label>
                  <input type="month" [min]="minDate" formControlName="ExpireDate" />
                  <div *ngIf="
                      formControl.ExpireDate.invalid &&
                      (formControl.ExpireDate.dirty ||
                        formControl.ExpireDate.touched ||
                        isSubmitted)
                    ">
                    <span class="text-danger" *ngIf="formControl.ExpireDate.errors?.required">Expire Date is
                      required.</span>
                  </div>
                </div>
                <div class="col-6">
                  <label class="form-label">Billing Address <span class="text-danger">*</span></label>
                  <input type="text" formControlName="BillingAddress" />
                  <div *ngIf="
                      formControl.BillingAddress.invalid &&
                      (formControl.BillingAddress.dirty ||
                        formControl.BillingAddress.touched ||
                        isSubmitted)
                    ">
                    <span class="text-danger" *ngIf="formControl.BillingAddress.errors?.required">Billing Address is
                      required.</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-4">
                  <label class="form-label">State/Province <span class="text-danger">*</span></label>
                  <input type="text" formControlName="BillingState" />

                  <!-- <ng-select formControlName="BillingState">
                    <ng-option value="" disabled>Select Billing State</ng-option>
                    <ng-option *ngFor="let option of states" [value]="option.StateProvCode">
                      {{option.StateProvName}}
                    </ng-option>
                  </ng-select> -->

                  <!-- <select formControlName="BillingState">
                    <option value="">Select Billing State</option>
                    <option *ngFor="let item of states" [value]="item.StateProvCode">
                      {{ item.StateProvName }}
                    </option>
                  </select> -->
                  <div *ngIf="
                        formControl.BillingState.invalid &&
                        (formControl.BillingState.dirty ||
                          formControl.BillingState.touched ||
                          isSubmitted)
                      ">
                    <span class="text-danger" *ngIf="formControl.BillingState.errors?.required">Billing State is
                      required.</span>
                  </div>
                </div>
                <div class="col-4">
                  <label for="city" class="form-label">City <span class="text-danger">*</span></label>
                  <input type="text" id="city-input" formControlName="BillingCity" autocomplete="disabled" />
                  <div *ngIf="
                        formControl.BillingCity.invalid &&
                        (formControl.BillingCity.dirty ||
                          formControl.BillingCity.touched ||
                          isSubmitted)
                      ">
                    <span class="text-danger" *ngIf="formControl.BillingCity.errors?.required">Billing City is
                      required.</span>
                  </div>
                </div>
                <div class="col-4">
                  <label for="postalCode" class="form-label">Zip Code <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" id="postalCode" formControlName="PostalCode" />
                  <div *ngIf="
                        formControl.PostalCode.invalid &&
                        (formControl.PostalCode.dirty ||
                          formControl.PostalCode.touched ||
                          isSubmitted)
                      ">
                    <span class="text-danger" *ngIf="formControl.PostalCode.errors?.required">Postal Code is
                      required.</span>
                  </div>
                </div>
              </div>
            </form>
          </ng-container>
          <ng-template #summary>
            <div class="row mb-5">
              <div class="col-2"></div>
              <div class="col-8">
                <p class="text-center">You have schedule a box to be pickup with Invoice Number
                  <strong>{{invoice.InvoiceNo}}</strong>
                </p>
                <div class="card">
                  <div class="row text-center mt-5">
                    <div class="col-sm-4">Source Branch:</div>
                    <div class="col-sm-4"><strong>{{branchName}}</strong></div>
                  </div>
                  <div class="row text-center mb-5">
                    <div class="col-sm-4">Delivery Zone:</div>
                    <div class="col-sm-4"><strong>{{this.invoice.DeliveryZoneCode}} - {{deliveryZone}}</strong></div>
                  </div>
                  <hr />
                  <table class="table table-responsive text-center">
                    <tr>
                      <th>Box Size</th>
                      <th>Dimension</th>
                      <th>Item Price</th>
                    </tr>
                    <tr *ngFor="let item of invoice.invoiceItemDetailList">
                      <td>{{item.BoxSizeDescription}}</td>
                      <td>{{item.Dimension}}</td>
                      <td>{{item.UnitPrice|number:'1.2-2'}}</td>
                    </tr>
                  </table>
                </div>
                <p class="text-center mt-5 mb-5">Please pay and wait for customer service to call you for more details
                </p>
                <form [formGroup]="amountForm">
                  <div class="row py-1">
                    <div class="col-sm-6 text-right my-auto">
                      Pay using
                    </div>
                    <div class="col-sm-6">
                      <span class="card-container">
                        <div class="card p-2" style="width: 100%;">
                          <img src="../../../assets/img/payment/{{this.formControl.CardType.value}}.png"
                            class="card-type-img" />
                          {{this.formControl.PaymentTypeCode.value === 'DB '?'Debit Card':'Credit Card' }}<br>
                          {{this.formControl.AccountCardNo.value | accountNumberMask}}<br>
                          {{this.formControl.ExpireDate.value|date:'MM/YYYY'}}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-sm-6 text-right my-auto">
                      Transaction Amount
                    </div>
                    <div class="col-sm-4">
                      <input disabled
                        value="{{currency[invoice.BillingCountry]}} {{invoice.TotalAmount | number:'1.2-2'}}" />
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-sm-6 text-right my-auto">
                      <ng-container *ngIf="addTip">
                        Tip Amount
                      </ng-container>
                    </div>
                    <div class="col-sm-4">
                      <a style="cursor: pointer;color: blue;" (click)="addTip=true" *ngIf="!addTip">
                        <u>
                          <i class="fa fa-plus-circle"></i> Add Tip
                        </u>
                      </a>
                      <ng-container *ngIf="addTip">
                        <input type="number" formControlName="TipAmount" numbersOnly />
                        <div *ngIf="
                    amountFormControl.TipAmount.invalid &&
                          (amountFormControl.TipAmount.dirty ||
                          amountFormControl.TipAmount.touched ||
                            isSubmitted)
                        ">
                          <span class="text-danger" *ngIf="amountFormControl.TipAmount.errors?.min">Minimium is 0</span>
                          <span class="text-danger" *ngIf="amountFormControl.TipAmount.errors?.max">Maximum is 20</span>
                        </div>
                      </ng-container>

                    </div>
                  </div>

                  <div class="row py-1">
                    <div class="col-sm-6 text-right my-auto">
                      {{invoice.BillingCountry == "CA" ? "Surcharge" : "Processing Fee"}}
                    </div>
                    <div class="col-sm-4">
                      <input
                        value="{{currency[invoice.BillingCountry]}} {{amountFormControl.MerchantFee.value | number:'1.2-2'}}"
                        disabled />
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-sm-6 text-right my-auto">
                      Total Payable Amount
                    </div>
                    <div class="col-sm-4">
                      <input
                        value="{{currency[invoice.BillingCountry]}} {{amountFormControl.PaymentAmount.value | number:'1.2-2'}}"
                        disabled />
                    </div>
                  </div>
                  <div *ngIf="
                  amountFormControl.PaymentAmount.invalid &&
                        (amountFormControl.PaymentAmount.dirty ||
                        amountFormControl.PaymentAmount.touched ||
                          isSubmitted)
                      ">
                    <span class="text-danger" *ngIf="amountFormControl.PaymentAmount.errors?.required">Payment Amount is
                      required</span>
                  </div>
                </form>
              </div>
              <div class="col-2"></div>
            </div>
          </ng-template>

          <div class="d-grid gap-2">
            <div class="row">
              <div class="col-12 col-auto text-center">
                <button type="button" (click)="backToHome()" class="btn btn-danger">
                  Back
                </button>&nbsp;
                <button type="submit" class="btn btn-success" (click)="onSubmit()">
                  {{isSummary?'Pay Now':'Continue'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>