import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { FremanService } from 'src/app/services/freman.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { JobApplicationService } from 'src/app/services/job-application.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-job-application',
  templateUrl: './job-application.component.html',
  styleUrls: ['./job-application.component.scss']
})
export class JobApplicationComponent implements OnInit {
  countries: any = [];
  states: any = [];
  cities: any = [];
  thisYear: any = '';
  selectedCountryCurrency: string = '';
  isSubmitted: boolean = false;

  filteredStates$: Observable<any>;
  filteredCities$: Observable<any>;

  applicationForm: FormGroup = new FormGroup({
    Location: new FormControl(''),
    FirstName: new FormControl('', Validators.required),
    MiddleName: new FormControl(''),
    LastName: new FormControl('', Validators.required),
    Suffix: new FormControl(''),
    AddressLine1: new FormControl('', Validators.required),
    CountryId: new FormControl('', Validators.required),
    StateProvCode: new FormControl('', Validators.required),
    CityCode: new FormControl('', Validators.required),
    _StateProvCode: new FormControl('', Validators.required),
    _CityCode: new FormControl('', Validators.required),
    ZipCode: new FormControl('', Validators.required),
    JobPosition: new FormControl('', Validators.required),
    MinimumDesiredSalary: new FormControl('', Validators.required),
    DateAvailableForWork: new FormControl('', Validators.required),
    BirthDate: new FormControl('', Validators.required),
    PersonalContactNumber: new FormControl('', Validators.required),
    WorkContactNumber: new FormControl(''),
    HasWorkOrApplied: new FormControl(null, Validators.required),
    ExplainWorkOrApplied: new FormControl(null, Validators.required),
    IsRelatedToAxpEmployee: new FormControl(null, Validators.required),
    RelatedAxpEmployee: new FormControl(null, Validators.required),
    CanWorkOvertime: new FormControl(null, Validators.required),
    CanOvertimeOnWeekdays: new FormControl(null, Validators.required),
    CanOvertimeOnWeekends: new FormControl(null, Validators.required),
    IsAuthorizedToWork: new FormControl(null, Validators.required),
    IsNeedingVisaSponsorship: new FormControl(null, Validators.required),
    ReferralType: new FormControl('', Validators.required),
    ReferralDetails: new FormControl('', Validators.required),
    CanContactCurrentEmployer: new FormControl(null, Validators.required),
    WorkExperiences: new FormArray([]),
    SkillsAndQualifications: new FormControl(''),
    ProfessionalReferences: new FormArray([]),
    EducationAndTraining: new FormArray([]),
    CanProvideDriverLicenseProof: new FormControl(null, Validators.required)
  })

  constructor(
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private fremanService: FremanService,
    private jobApplicationService: JobApplicationService
  ) {
    this.thisYear = this.datePipe.transform((new Date(Date.now())), 'yyyy');
  }

  ngOnInit(): void {
    this.getCountries();

    this.applicationForm.patchValue({
      CountryId: 'US'
    });

    this.onCountryChange(this.applicationForm.get('CountryId').value);

    this.initTypeSelectOptions();
    this.initRadioButtons();
  }

  initTypeSelectOptions() {
    this.filteredStates$ = this.applicationForm.get('_StateProvCode').valueChanges.pipe(
      startWith(''),
      map((value) => {
        if (value) {
          return typeof value === 'string' ? value : value.StateProvName;
        }
      }),
      map((name) =>
        name
          ? this.filterOptions(name, this.states, 'StateProvName')
          : this.states.slice()
      )
    );

    this.filteredCities$ = this.applicationForm.get('_CityCode').valueChanges.pipe(
      startWith(''),
      map((value) => {
        if (value) {
          return typeof value === 'string' ? value : value.CityName;
        }
      }),
      map((name) =>
        name
          ? this.filterOptions(name, this.cities, 'CityName')
          : this.cities.slice()
      )
    );
  }

  initRadioButtons() {
    this.applicationForm.get('ExplainWorkOrApplied').disable();
    this.applicationForm.get('RelatedAxpEmployee').disable();
    this.applicationForm.get('CanOvertimeOnWeekdays').disable();
    this.applicationForm.get('CanOvertimeOnWeekends').disable();

    this.applicationForm.get('HasWorkOrApplied').valueChanges
      .subscribe((result: any) => {
        if(result) {
          this.applicationForm.get('ExplainWorkOrApplied').enable();
        } else {
          this.applicationForm.get('ExplainWorkOrApplied').disable();
          this.applicationForm.get('ExplainWorkOrApplied').setValue('');
        }
      });

    this.applicationForm.get('IsRelatedToAxpEmployee').valueChanges
      .subscribe((result: any) => {
        if(result) {
          this.applicationForm.get('RelatedAxpEmployee').enable();
        } else {
          this.applicationForm.get('RelatedAxpEmployee').disable();
          this.applicationForm.get('RelatedAxpEmployee').setValue('');
        }
      });

    this.applicationForm.get('CanWorkOvertime').valueChanges
      .subscribe((result: any) => {
        if(result) {
          this.applicationForm.get('CanOvertimeOnWeekdays').enable();
          this.applicationForm.get('CanOvertimeOnWeekends').enable();
        } else {
          this.applicationForm.get('CanOvertimeOnWeekdays').disable();
          this.applicationForm.get('CanOvertimeOnWeekends').disable();
          this.applicationForm.get('CanOvertimeOnWeekdays').setValue(null);
          this.applicationForm.get('CanOvertimeOnWeekends').setValue(null);
        }
      });

    this.applicationForm.get('CanOvertimeOnWeekdays').valueChanges
      .subscribe((result: any) => {
        if(result == false && this.applicationForm.get('CanOvertimeOnWeekends').value == false) {
          this.applicationForm.get('CanOvertimeOnWeekdays').setValue(null);
          this.applicationForm.get('CanOvertimeOnWeekends').setValue(null);
          this.applicationForm.get('CanWorkOvertime').setValue(false);
        }
      });

    this.applicationForm.get('CanOvertimeOnWeekends').valueChanges
      .subscribe((result: any) => {
        if(result == false && this.applicationForm.get('CanOvertimeOnWeekdays').value == false) {
          this.applicationForm.get('CanOvertimeOnWeekdays').setValue(null);
          this.applicationForm.get('CanOvertimeOnWeekends').setValue(null);
          this.applicationForm.get('CanWorkOvertime').setValue(false);
        }
      });
  }

  updateFormValues() {
    let temp = this.applicationForm.getRawValue();

    this.applicationForm.setValue(temp);
  }

  submitApplicationForm() {
    this.isSubmitted = true;

    this.updateFormValues();

    if(this.applicationForm.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Job Application Form',
        text: 'Please check your application form for incomplete/invalid fields.'
      });

      return;
    }

    this.spinner.show();

    this.jobApplicationService.saveJobApplication(this.applicationForm.getRawValue())
      .subscribe((result: any) => {
        this.spinner.hide();

        Swal.fire({
          icon: 'success',
          title: 'Job Application Form',
          text: 'Submission successful.'
        });

        this.applicationForm.reset();
      }, (error: any) => {
        this.spinner.hide();

        Swal.fire({
          icon: 'error',
          title: 'Job Application Form',
          text: 'Something went wrong while submitting your job application form.'
        });
      });
  }

  getCountries() {
    this.countries = [
      {
        CountryId: 'CA',
        CountryName: 'Canada',
        CurrencyCode: 'CAD'
      },
      {
        CountryId: 'US',
        CountryName: 'United States',
        CurrencyCode: 'USD'
      }
    ]
  }

  onCountryChange(countryId: any) {
    if(countryId == null || countryId == '') {
      this.selectedCountryCurrency = 'USD';
    }

    this.selectedCountryCurrency = _.find(this.countries, { CountryId: countryId }).CurrencyCode;

    this.spinner.show();

    this.fremanService.getStatesByCountryId(countryId)
      .subscribe((result: any) => {
        this.spinner.hide();

        this.states = result;

        this.applicationForm.patchValue({
          CountryId: countryId,
          StateProvCode: '',
          CityCode: '',
          _StateProvCode: '',
          _CityCode: '',
          ZipCode: ''
        });
      }, (error: any) => {
        this.spinner.hide();
      });
  }

  onStateChange(stateProv: any) {
    if(stateProv != null) {
      this.spinner.show();

      this.fremanService.getCitiesByStateId(stateProv.StateProvCode)
        .subscribe((result: any) => {
          this.spinner.hide();

          this.cities = result;

          this.applicationForm.patchValue({
            StateProvCode: stateProv.StateProvCode,
            CityCode: '',
            _CityCode: '',
            ZipCode: ''
          });
        }, (error: any) => {
          this.spinner.hide();
        });
    }
  }

  onCityChange(city: any) {
    if(city != null) {
      this.applicationForm.patchValue({
        CityCode: city.CityCode
      });

      const selectedCity: any = _.find(this.cities, { CityCode: city.CityCode });
      const zipCode = selectedCity.ZipCode;

      if(zipCode != null) {
        this.applicationForm.get('ZipCode').setValue(zipCode);
      }
    }
  }

  displayCountry(country: any) {
    const name = country && country.CountryName ? country.CountryName : '';

    return name.trim();
  }

  displayState(state: any) {
    const name = state && state.StateProvName ? state.StateProvName : '';

    return name.trim();
  }

  displayCity(city: any) {
    const name = city && city.CityName ? city.CityName : '';

    return name.trim();
  }

  filterOptions(value: string, list: any[], name: string) {
    const filterValue = value.toLowerCase();

    return list.filter((option) =>
      option[name].toLowerCase().includes(filterValue)
    );
  }

  addWorkExperience() {
    this.applicationForm.get('WorkExperiences')['controls'].push(
      new FormGroup({
        CompanyName: new FormControl('', Validators.required),
        CompanyAddress: new FormControl('', Validators.required),
        CompanyContactNumber: new FormControl('', Validators.required),
        JobPosition: new FormControl('', Validators.required),
        WorkResponsibilities: new FormControl('', Validators.required),
        StartingEmploymentMonth: new FormControl('', Validators.required),
        StartingEmploymentYear: new FormControl('', Validators.required),
        LastEmploymentMonth: new FormControl('', Validators.required),
        LastEmploymentYear: new FormControl('', Validators.required),
        SupervisorName: new FormControl('', Validators.required),
        SupervisorJobPosition: new FormControl('', Validators.required),
        SupervisorContactNumber: new FormControl('', Validators.required),
        ReasonForLeaving: new FormControl('', Validators.required)
      })
    );
  }

  removeWorkExperience(index: number) {
    this.applicationForm.get('WorkExperiences')['controls'].splice(index, 1);
  }

  addProfessionalReference() {
    this.applicationForm.get('ProfessionalReferences')['controls'].push(
      new FormGroup({
        Name: new FormControl('', Validators.required),
        ContactNumber: new FormControl('', Validators.required),
        YearsKnownAndCapacity: new FormControl('', Validators.required),
        Occupation: new FormControl(''),
        Address: new FormControl('')
      })
    );
  }

  removeProfessionalReference(index: number) {
    this.applicationForm.get('ProfessionalReferences')['controls'].splice(index, 1);
  }

  addEducationAndTraining(type: string) {
    this.applicationForm.get('EducationAndTraining')['controls'].push(
      new FormGroup({
        TypeOfSchool: new FormControl(type, Validators.required),
        SchoolName: new FormControl('', Validators.required),
        YearsCompleted: new FormControl('', Validators.required),
        SchoolLocation: new FormControl('', Validators.required),
        Degree: new FormControl(''),
        CourseOrMajor: new FormControl('')
      })
    );
  }

  removeEducationAndTraining(index: number) {
    this.applicationForm.get('EducationAndTraining')['controls'].splice(index, 1);
  }

  shouldDisplayError(formGroup: FormGroup, formControlName: string) {
    let formControl = formGroup.controls[formControlName];

    return formControl.invalid
      && (this.isSubmitted
        || formControl.dirty
        || formControl.touched
      )
      && !formControl.disabled;
  }

  displayError(formGroup: FormGroup, formControlName: string, customIdentifier: string = 'This field') {
    let errorPrompts = '';
    let formControl = formGroup.controls[formControlName];

    if(!this.shouldDisplayError(formGroup, formControlName)) {
      return;
    }

    if(formControl.errors != null && Object.keys(formControl.errors).length > 0) {
      Object.keys(formControl.errors).forEach((item: any) => {
        switch(item) {
          case 'required':
            errorPrompts += `<div>
                <span class="text-danger">${customIdentifier} is required.</span>
              </div>`;

            break;
          default:
            break;
        }
      });
    }

    return errorPrompts;
  }

  displayMergedError(formGroup: FormGroup, formControlName1: string, formControlName2: string, customIdentifier: string = 'This field') {
    let errorPrompts = '';
    let formControl1 = formGroup.controls[formControlName1];
    let formControl2 = formGroup.controls[formControlName2];
    let mergedErrors = null;

    if(!this.shouldDisplayError(formGroup, formControlName1) && !this.shouldDisplayError(formGroup, formControlName2)) {
      return;
    }

    if(formControl1.errors != null && Object.keys(formControl1.errors).length > 0) {
      Object.keys(formControl1.errors).forEach((item: any) => {
        if(item) {
          if(mergedErrors == null) {
            mergedErrors = {};
          }

          mergedErrors[item] = true;
        }
      });
    }

    if(formControl2.errors != null && Object.keys(formControl2.errors).length > 0) {
      Object.keys(formControl2.errors).forEach((item: any) => {
        if(item) {
          if(mergedErrors == null) {
            mergedErrors = {};
          }

          mergedErrors[item] = true;
        }
      });
    }

    if(mergedErrors != null && Object.keys(mergedErrors).length > 0) {
      Object.keys(mergedErrors).forEach((item: any) => {
        switch(item) {
          case 'required':
            errorPrompts += `<div>
                <span class="text-danger">${customIdentifier} is required.</span>
              </div>`;

            break;
          default:
            break;
        }
      });
    }

    return errorPrompts;
  }
}
