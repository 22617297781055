<div class="container py-4">
  <div class="display-3">Dev Testing Page</div>
  <hr>
  <section>
    <div class="d-flex gap-2">
      <a href="https://axp.app.link://" class="btn btn-axp-theme">Open Deeplink</a>
      <button type="button" class="btn btn-axp-theme" (click)="redirectToDeeplink()" [disabled]="redirectInterval != null">Redirect to Deeplink{{ redirectInterval != null ? ' in ' + redirectCountdown : '' }}</button>
    </div>
  </section>
</div>
