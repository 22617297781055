import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { FremanService } from 'src/app/services/freman.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  user: any = {};
  invoiceList: any[] = [];
  pendingList: any[] = [];
  paidList: any[] = [];
  deliveredList: any[] = [];
  cancelledList: any[] = [];

  selInvoice: any = {};
  isPayment: boolean = false;

  billingStateList: any[] = [];
  branchList: any[] = [];
  deliveryZoneList: any[] = [];

  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private fremanService: FremanService
  ) { }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    try {
      await this.loadSelections();

      this.user = this.auth.getUser();
      this.dataService.customerInvoices(this.user.customerId).subscribe(
        (res: any) => {
          this.invoiceList = res;
          this.pendingList = res.filter(z => z.LastSorefStatusCode === 'PENDING');
          this.cancelledList = res.filter(z => z.LastSorefStatusCode === 'CANCELLED');
          this.deliveredList = res.filter(z => z.IsDelivered);
          this.paidList = res.filter(z => z.IsPaid && !z.IsDelivered);
        },
        error => {
          console.log(error);
          this.spinner.hide();
          this.invoiceList = null;
        }
      );
    } catch (error) {
      console.log(error)
    }
    this.spinner.hide();
  }

  async loadSelections() {
    const result = await this.fremanService.getInvoiceDetails().toPromise();

    this.billingStateList = result?.StateList;
    this.deliveryZoneList = result?.DeliveryZoneList;
  }

  async onPayment(item) {
    this.spinner.show();
    try {
      this.branchList = await this.fremanService
        .getBranchesByCountryId(item.BillingCountry)
        .toPromise();

      this.selInvoice = item;
      this.isPayment = true;
    } catch (error) {
      console.log(error);
    }
    this.spinner.hide();
  }

  onPaymentBack() {
    this.selInvoice = {};
    this.isPayment = false;
  }
}
