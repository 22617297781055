import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BranchesComponent } from './pages/branches/branches.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './pages/services/services.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { BalikbayanBoxComponent } from './pages/axp/balikbayan-box/balikbayan-box.component';
import { RemittanceDetailsComponent } from './pages/axp/remittance-details/remittance-details.component';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';
import { SharedModule } from './shared/shared.module';
import { InvoiceComponent } from './sms/invoice/invoice.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { CustomerActivationComponent } from './account/customer-activation/customer-activation.component';
import { TermsAndConditionsShipmentComponent } from './pages/terms-and-conditions-shipment/terms-and-conditions-shipment.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { FremanPrivacyPolicyComponent } from './pages/freman-privacy-policy/freman-privacy-policy.component';
import { BlogListComponent } from './pages/blogs/blog-list/blog-list.component';
import { BlogPostComponent } from './pages/blogs/blog-post/blog-post.component';
import { DeeplinkComponent } from './deeplink/deeplink.component';
import { TransactionHistoryComponent } from './pages/transaction-history/transaction-history.component';
import { FremanOnlinePaymentComponent } from './pages/freman-online-payment/freman-online-payment.component';
import { DownloadComponent } from './pages/download/download.component';
import { JobApplicationComponent } from './pages/job-application/job-application.component';
import { DevTestingComponent } from './pages/dev-testing/dev-testing.component';
import { ManualsComponent } from './pages/manuals/manuals.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'receipt/:id', component: DownloadComponent },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AutoLoginGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      // for deeplink
      { path: 'app', component: DeeplinkComponent },
      { path: 'balikbayan-box/package-tracker', component: DeeplinkComponent },
      { path: 'balikbayan-box/booking/dropoff', component: DeeplinkComponent },
      { path: 'balikbayan-box/booking/pickup', component: DeeplinkComponent },

      { path: 'home', component: HomeComponent },
      { path: 'branches', component: BranchesComponent },
      { path: 'tracking', component: TrackingComponent },
      { path: 'services', component: ServicesComponent },
      { path: 'blogs', component: BlogListComponent },
      { path: 'blogs/:id', component: BlogPostComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'balikbayan-box', component: BalikbayanBoxComponent },
      { path: 'remittance-details', component: RemittanceDetailsComponent },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
      { path: 'terms-and-conditions-shipment', component: TermsAndConditionsShipmentComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'freman-privacy-policy', component: FremanPrivacyPolicyComponent },
      { path: 'transaction-history', component: TransactionHistoryComponent },
      { path: 'online-payment/:id', component: FremanOnlinePaymentComponent },
      { path: 'job-application', component: JobApplicationComponent },
      { path: 'manuals/:slug', component: ManualsComponent },
      { path: 'dev/testing', component: DevTestingComponent },
      {
        path: 'booking',
        loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)
      },
    ]
  },
  // {
  //   path: 'admin/booking-list',
  //   component: MainLayoutComponent,
  //   loadChildren: () => import('./admin/booking-list/booking-list.module').then(m => m.BookingListModule)
  // },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule)
  },
  {
    path: 'sms',
    children: [
      {
        path: 'invoice/:id', component: InvoiceComponent
      }
    ]
  },
  {
    path: 'account',
    children: [
      {
        path: 'activation', component: CustomerActivationComponent
      }
    ]
  },
  // { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), SharedModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
