import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewTransactionDetailsComponent } from '../view-transaction-details/view-transaction-details.component';

@Component({
  selector: 'app-transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: ['./transaction-table.component.scss']
})
export class TransactionTableComponent implements OnInit {
  @Input() historyList: any[] = [];
  @Input() hasAction: boolean = true;

  @Output() payNow = new EventEmitter();

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  viewTransactionDetails(item: any) {
    this.dialog.open(ViewTransactionDetailsComponent, {
      data: item,
    });
  }

  onPayment(item) {
    this.payNow.emit(item);
  }
}
